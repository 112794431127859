import { Component } from 'react';

class AsyncComponent extends Component {
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  forceUpdateAsync() {
    return new Promise((resolve) => {
      this.forceUpdate(resolve);
    });
  }
}

export default AsyncComponent;
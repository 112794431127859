import i18n from '../i18n';

import TreeMarker from '../images/report-markers/tree-marker.png';
import SnowRemovalMarker from '../images/report-markers/snow-marker.png';
import LightMarker from '../images/report-markers/light-marker.png';
import UrbanFurnitureMarker from '../images/report-markers/urban-furniture-marker.png';
import PotholeMarker from '../images/report-markers/pothole-marker.png';
import GraffitiMarker from '../images/report-markers/graffiti-marker.png';
import WaterPressureMarker from '../images/report-markers/waterPressure-marker.png';
import TrashMarker from '../images/report-markers/trash-marker.png';
import FloodMarker from '../images/report-markers/flood-marker.png';
import SignageMarker from '../images/report-markers/signage-marker.png';
import OtherMarker from '../images/report-markers/other-marker.png';

export const ProblemCategories = {
  'tree': { name: i18n.t('tree'), marker: TreeMarker },
  'snowRemoval': { name: i18n.t('snowRemoval'), marker: SnowRemovalMarker },
  'streetLight': { name: i18n.t('streetLight'), marker: LightMarker },
  'streetFurniture': { name: i18n.t('streetFurniture'), marker: UrbanFurnitureMarker },
  'pothole': { name: i18n.t('pothole'), marker: PotholeMarker },
  'graffiti': { name: i18n.t('graffiti'), marker: GraffitiMarker },
  'waterPressure': { name: i18n.t('waterPressure'), marker: WaterPressureMarker },
  'cleanliness': { name: i18n.t('cleanliness'), marker: TrashMarker },
  'flood': { name: i18n.t('flood'), marker: FloodMarker },
  'signage': { name: i18n.t('signage'), marker: SignageMarker },
  'other': { name: i18n.t('other'), marker: OtherMarker },
};

export const ProblemStatus = {
  'new': { name: i18n.t('new') },
  'received': { name: i18n.t('received') },
  'planned': { name: i18n.t('planned') },
  'done': { name: i18n.t('done') },
};

export const DelayPatterns = {
  'Days': { name: i18n.t('day') },
  'Weeks': { name: i18n.t('week') },
  'Months': { name: i18n.t('month') },
};

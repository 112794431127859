import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Alerts from '../scenes/Alerts';
import BrowseProblems from '../scenes/BrowseProblems';
import Invoices from '../scenes/Invoices';
import Surveys from '../scenes/Surveys';
import Informations from '../scenes/Informations';
import Stats from '../scenes/Stats';
import Redirections from '../scenes/Redirections';
import ReportsMessages from '../scenes/ReportsMessages';
import AuthorisedReports from '../scenes/AuthorisedReports';
import Password from '../scenes/Password';
import Users from '../scenes/Users';
import Cities from '../scenes/Cities';
import Citizens from '../scenes/Citizens';
import Subscribers from '../scenes/Subscribers';
import Groups from '../scenes/Groups';
import Parking from '../scenes/Parking';
import SingleProblem from '../scenes/SingleProblem';
import Login from '../scenes/Login';

import i18n from '../i18n';

import { PrivateRoute } from './PrivateRoute';


// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string '/' , marginTop:'76px'
const Main = () => (
  <main style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
    <Switch>
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/`} component={BrowseProblems} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Problem/:id`} component={SingleProblem} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Alerts`} component={Alerts} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Surveys`} component={Surveys} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Portail`} component={Informations} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Parking`} component={Parking} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Stats`} component={Stats} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Users`} component={Users} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Cities`} component={Cities} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Citizens`} component={Citizens} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Subscribers`} component={Subscribers} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Redirections`} component={Redirections} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Password`} component={Password} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/ReportsMessages`} component={ReportsMessages} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/AuthorisedReports`} component={AuthorisedReports} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Groups`} component={Groups} />
      <PrivateRoute path={`${process.env.PUBLIC_URL}/Invoices`} component={Invoices} />
      <Route path={`${process.env.PUBLIC_URL}/Login`} component={Login} />
      <Route component={NotFound} />
    </Switch>
  </main>
);

const NotFound = () =>
  <div>
    <h1>{i18n.t('404Title')}</h1>
    <p>{i18n.t('404Message')}</p>
  </div>;

export default Main;

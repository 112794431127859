import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@material-ui/core/Button';
import { Container, Col, Row } from 'reactstrap';
import deburr from 'lodash/deburr';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import LoadingPage from '../../components/LoadingPage';
import MenuItem from '@material-ui/core/MenuItem';
import { orderBy } from '../../utils/array_helper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast } from 'react-toastify';

import AccessForbidden from '../../components/AccessForbidden';
import CitizensService from '../../services/CitizensService';
import Cities from '../../data/Cities';
import User from '../../data/User';
import './styles.scss';
import Table from '../../components/Table';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class Citizens extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      city: '',
      cities: [],
      columnToSort: 'name',
      loading: false,
      openBlock: false,
      sortDirection: 'asc',
      userQuery: '',
      users: [],
      userToBlock: {}
    };

    this._blockUser = this._blockUser.bind(this);
    this._handleBlockUser = this._handleBlockUser.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._onUserQueryChange = this._onUserQueryChange.bind(this);
  }

  async componentDidMount() {
    await this._fetchUsersApp();
    await this._fetchCities();
    await this._getUserCities();
  }

  async _blockUser() {
    const userCanReport = !this.state.userToBlock.canReport;
    this.state.userToBlock.canReport = userCanReport;

    const blockedUser = {
      id: this.state.userToBlock.id,
      can_report: userCanReport,
    };

    const success = await CitizensService.updateCitizen(blockedUser);
    if (success) {
      await this.setStateAsync({ openBlock: false });
      userCanReport ? this._notifySuccess(i18n.t('successfullyUnblocked')) : this._notifySuccess(i18n.t('successfullyBlocked'));
    }
    else {
      this._notifyError(i18n.t('errorDuringBlocking'));
    }
  }

  async _fetchUsersApp() {
    const userCityIds = User.getInstance().getUser().city_ids;
    await this.setStateAsync({ loading: true });
    const users = await CitizensService.fetchUsersApp(this.state.city ? [this.state.city] : userCityIds);
    await this.setStateAsync({ users, loading: false });
  }

  async _fetchCities() {
    const cities = await Cities.getInstance().getCities();
    await this.setStateAsync({ cities });
  }

  async _fetchCityUsers(city) {
    await this.setStateAsync({ city });
    await this._fetchUsersApp();
  }

  async _getUserCities() {
    const cities = await User.getInstance().getUserCities();
    await this.setStateAsync({ cities, city: cities.some(city => city.cityId === 'null') ? 'null' : cities[0].cityId });
  }

  async _handleBlockUser(user) {
    await this.setStateAsync({ openBlock: true, userToBlock: user });
  }

  async _handleClose() {
    await this.setStateAsync({
      openBlock: false,
    });
  }

  async _handleSort(columnName) {
    await this.setStateAsync(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : 'asc'
    }));
  }

  _notifySuccess(message) {
    toast.success(message);
  }

  _notifyError(message) {
    toast.error(message);
  }

  async _onUserQueryChange(e) {
    await this.setStateAsync({ userQuery: e.target.value });
  }

  _renderUsersDatatables() {
    const lowerCaseQuery = deburr(this.state.userQuery.toLowerCase());
    return (
      <React.Fragment>
        <Row className='citiesSelectContainer'>
          <Col sm='6'>
            <FormControl fullWidth={true}>
              <InputLabel>{i18n.t('selectACityToConsult')}</InputLabel>
              <Select
                value={this.state.city}
                onChange={async (_event) => await this._fetchCityUsers(_event.target.value)}
              >
                {
                  this.state.cities.map(function(city) {
                    return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col sm='12'>
            <h2 className={'pageTitle'}>{i18n.t('numberOfCarecityCitizens')} : {this.state.users.length}</h2>
          </Col>
        </Row>
        <Row>
          <Col sm='6'>
            <TextField
              placeholder={i18n.t('enterTheUserName')}
              fullWidth={true}
              label={i18n.t('searchForAUser')}
              maxLength='255'
              onChange={this._onUserQueryChange}
              value={this.state.userQuery}
            />
          </Col>
        </Row>
        <Table
          sortDirection={this.state.sortDirection}
          columnToSort={this.state.columnToSort}
          handleSort={this._handleSort}
          handleBlock={this._handleBlockUser}
          deleteAction={false}
          blockAction={true}
          data={orderBy(
            lowerCaseQuery
              ? this.state.users.filter(x =>
                deburr(x['name'])
                  .toLowerCase()
                  .includes(lowerCaseQuery)
              )
              : this.state.users,
            this.state.columnToSort,
            this.state.sortDirection)}
          header={[
            {
              name: i18n.t('name'),
              prop: 'name'
            },
            {
              name: i18n.t('id'),
              prop: 'id'
            },
            {
              name: i18n.t('creationDate'),
              prop: 'createdAt'
            },
            {
              name: i18n.t('canReport'),
              prop: 'canReport'
            }
          ]}
        />
        <Dialog
          open={this.state.openBlock}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfBlock')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {this.state.userToBlock.canReport ? i18n.t('youAreAboutToBlockAUser') : i18n.t('youAreAboutToUnblockAUser')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {i18n.t('cancel')}
            </Button>
            <Button onClick={this._blockUser} color='primary'>
              {i18n.t('confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin') {
      return (
        <Container className='Citizens'>
          {this.state.loading && <LoadingPage />}
          {this._renderUsersDatatables()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Citizens;

import CitiesService from '../services/CitiesService';

class Cities {

  static getInstance() {
    if (!this.myInstance) {
      this.myInstance = new Cities();
    }

    return this.myInstance;
  }

  async fetchCities() {
    this.cities = await CitiesService.fetchCities();

    this.cities = this.cities.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1
        : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0));

    return this.cities;
  }

  async fetchGeographicCities() {
    this.geographicCities = await CitiesService.fetchGeographicCities();

    this.geographicCities = this.geographicCities.sort((a, b) => {
      let x = a.cityId, y = b.cityId;
      if (x === y) {
        x = a.name.toLowerCase();
        y = b.name.toLowerCase();
      }

      return x < y ? -1 : x > y ? 1 : 0;
    });

    return this.geographicCities;
  }

  async getCities() {
    if (!this.cities) {
      await this.fetchCities();
    }

    return this.cities;
  }

  async getGeographicCities() {
    if (!this.geographicCities) {
      await this.fetchGeographicCities();
    }

    return this.geographicCities;
  }

  async getCitiesName(cityIds) {
    if (cityIds[0] === null) {
      return 'Carecity';
    }

    let userCitiesString = '';
    const cities = await this.getCities();

    cityIds.forEach(cityId => {
      const city = cities.find(city => city.cityId === cityId);
      if (city) {
        userCitiesString += city.name + ', ';
      }
    });

    userCitiesString = userCitiesString.slice(0, -2);

    return userCitiesString;
  }
}

export default Cities;

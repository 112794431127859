import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import { Pie } from 'react-chartjs-2';
import { Container, Row } from 'reactstrap';

import './styles.scss';
import i18n from '../../i18n';

class SurveyDetails extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  _renderSurveyResults(choices) {
    const choicesCount = [];
    const choicesTitle = [];
    const colorArray = [];

    choices.forEach(choice => {
      choicesTitle.push(choice.title);
      choicesCount.push(choice.vote_count);
      colorArray.push(choice.color);
    });
    const dataSurveyResults = {
      labels: choicesTitle,
      datasets: [{
        data: choicesCount,
        backgroundColor: colorArray,
        hoverBackgroundColor: colorArray
      }]
    };

    return (
      <Pie data={dataSurveyResults} />
    );
  }

  render() {
    const survey = this.props.survey;
    const userCityIds = this.props.userCityIds;

    return (
      <Container className='SurveyDetails'>
        <Row>
          <p className='label'><span className='title'>{i18n.t('title')}</span>{survey.title}</p>
        </Row>
        <Row>
          <p className='label'><span className='title'>{i18n.t('dispatchDate')}</span>{survey.dateFormatted}</p>
        </Row>
        {userCityIds.length > 1 &&
          <Row>
            <p className='label'><span className='title'>{i18n.t('cities')}</span>{survey.citiesName}</p>
          </Row>
        }
        <Row>
          <p className='label'><span className='title'>{i18n.t('content')}</span>{survey.content}</p>
        </Row>
        <Row expandable={'true'}>
          <p className='title'><span className='title'>{i18n.t('surveyResults')} :</span></p>
          {this._renderSurveyResults(survey.choices)}
        </Row>
      </Container>
    );
  }
}

export default SurveyDetails;

import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import { Container, Col, Form, Row } from 'reactstrap';
import deburr from 'lodash/deburr';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Multiselect from 'react-select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from 'react-modal';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Quicksort from 'optimized-quicksort';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast } from 'react-toastify';

import AccessForbidden from '../../components/AccessForbidden';
import CitizensService from '../../services/CitizensService';
import GroupsService from '../../services/GroupsService';
import { isArrayContainedInOtherArray } from '../../utils/array_helper';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

Modal.setAppElement('#root');

class Groups extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEls: {},
      cityToFetch: 0,
      groups: [],
      groupToEdit: { users: [] },
      groupUsersIdsToEdit: [],
      groupEditingEnabled: false,
      inviteOpen: false,
      name: '',
      nameEdited: '',
      open: false,
      openError: false,
      users: [],
      userCities: [],
      userCitiesSelected: [],
      userQuery: '',
    };

    this._addGroup = this._addGroup.bind(this);
    this._deleteGroup = this._deleteGroup.bind(this);
    this._editGroup = this._editGroup.bind(this);
    this._editGroupUsers = this._editGroupUsers.bind(this);
    this._handleClosePopup = this._handleClosePopup.bind(this);
    this._handleCloseError = this._handleCloseError.bind(this);
    this._handleCloseModal = this._handleCloseModal.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleSelectMultiCitiesChange = this._handleSelectMultiCitiesChange.bind(this);
    this._onUserQueryChange = this._onUserQueryChange.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchUsersApp();
    await this._fetchGroups();
  }

  async _fetchUsersApp() {
    const users = await CitizensService.fetchUsersApp(this.state.userCityIds);
    users.forEach(user => {
      delete user.canReport;
      delete user.createdAt;
    });

    await this.setStateAsync({ users });
  }

  async _getUserCities() {
    const userCities = await User.getInstance().getUserCities();
    const userCityIds = userCities.map(city => city.cityId);
    await this.setStateAsync({ userCities, userCityIds });

    if (userCities.length === 1) {
      await this.setStateAsync({ userCitiesSelected: [{ value: userCities[0].cityId, label: userCities[0].name }], cityToFetch: userCities[0].cityId });
      await this._fetchGroups();
    }
  }

  async _fetchGroups() {
    const citiesToFetchUsersFrom = this.state.cityToFetch === 0 ? this.state.userCityIds : [this.state.cityToFetch];
    const groups = await GroupsService.fetchGroups(citiesToFetchUsersFrom);
    await this.setStateAsync({ groups });
  }

  async _addGroup() {
    if (this.state.name === '') {
      await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAddAName'));
    }
    else if (this.state.userCities.length > 1 && this.state.userCitiesSelected.length === 0) {
      await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustChooseAtLeastOneCity'));
    }
    else {
      const group = {
        name: this.state.name,
        cities: this.state.userCitiesSelected.map(city => city.value),
      };

      const success = await GroupsService.addGroup(group);

      if (success) {
        await this._fetchGroups();
        await this.setStateAsync({
          name: '',
          userCitiesSelected: []
        });

        if (this.state.userCities.length === 1) {
          await this.setStateAsync({ userCitiesSelected: [{ value: this.state.userCities[0].cityId, label: this.state.userCities[0].name }] });
        }

        this._notifySuccess(i18n.t('successfullyCreated'));
      }
      else {
        this._notifyError(i18n.t('errorDuringCreation'));
      }
    }
  }

  async _editGroup(usersInGroupEdition) {
    if (this.state.nameEdited === '') {
      await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustAddAName'));
    }
    else {
      const group = {
        id: usersInGroupEdition.id,
        name: this.state.nameEdited,
      };

      const success = await GroupsService.editGroup(group);
      success ? this._notifySuccess(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));

      if (success) {
        await this._fetchGroups();
        await this.setStateAsync({
          editing: false,
          nameEdited: '',
        });
      }
    }
  }

  async _editGroupUsers() {
    const group = {
      id: this.state.groupToEdit.id,
      name: this.state.groupToEdit.name,
      users: this.state.groupUsersIdsToEdit
    };

    const success = await GroupsService.editGroupUsers(group);
    success ? this._notifySuccess(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));

    if (success) {
      await this._fetchGroups();
      await this.setStateAsync({
        groupToEdit: { users: [] },
        groupUsersIdsToEdit: [],
        inviteOpen: false,
        userQuery: '',
      });
    }
  }

  async _deleteGroup() {
    const success = await GroupsService.deleteGroup(this.state.groupToDelete);
    if (success) {
      await this.setStateAsync({ open: false });
      this._notifySuccess(i18n.t('successfullyRemoved'));
      await this._fetchGroups();
      await this.setStateAsync({ anchorEls: {}, editing: false, groupToDelete: null });
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _filterGroups(cityToFetch) {
    await this.setStateAsync({ cityToFetch });
    await this._fetchGroups();
  }

  async _setAnchorEl(groupId, anchorEl) {
    const anchorEls = this.state.anchorEls;
    anchorEls[groupId] = anchorEl;
    await this.setStateAsync({ anchorEls });
  }

  async _cancelEditing(group) {
    await this._setAnchorEl(group.id, null);
    group.groupEditingEnabled = false;
    await this.setStateAsync({
      editing: false,
      nameEdited: '',
    });
  }

  async _toggleEditing(group) {
    if (!this.state.editing) {
      await this._setAnchorEl(group.id, null);
      group.groupEditingEnabled = true;
      await this.setStateAsync({
        editing: true,
        nameEdited: group.name,
      });
    }
    else {
      this._notifyError(i18n.t('youAreAlreadyEditingAnotherGroup'));
    }
  }

  async _toggleUserInGroup(value, user) {
    let usersInGroupEdition = this.state.groupToEdit.users;
    const groupUsersIdsToEdit = this.state.groupUsersIdsToEdit;

    if (value) {
      groupUsersIdsToEdit.push(user.id);
      usersInGroupEdition.push(user);
    }
    else {
      groupUsersIdsToEdit.splice(groupUsersIdsToEdit.indexOf(user.id), 1);
      usersInGroupEdition = usersInGroupEdition.filter(groupUser => groupUser.id !== user.id);
    }

    const groupToEdit = {
      id: this.state.groupToEdit.id,
      name: this.state.groupToEdit.name,
      users: usersInGroupEdition
    };

    await this.setStateAsync({ groupToEdit, groupUsersIdsToEdit });
  }

  async _handleSelectMultiCitiesChange(cities) {
    await this.setStateAsync({ userCitiesSelected: cities ? cities : [] });
  }

  async _handleClosePopup() {
    await this.setStateAsync({ open: false });
    await this.setStateAsync({
      anchorEls: {},
      inviteOpen: false
    });
  }

  async _handleCloseError() {
    await this.setStateAsync({
      anchorEls: {},
      openError: false,
    });
  }

  async _handleCloseModal() {
    await this.setStateAsync({
      anchorEls: {},
      groupToEdit: { users: [] },
      groupUsersIdsToEdit: [],
      inviteOpen: false,
      userQuery: '',
    });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleOpenDeleteModal(groupId) {
    await this.setStateAsync({ groupToDelete: groupId, open: true });
  }

  async _handleOpenInviteModal(group) {
    const groupUsersIdsToEdit = [];
    group.users.forEach(user => {
      groupUsersIdsToEdit.push(user.id);
    });

    await this._setAnchorEl(group.id, null);
    await this.setStateAsync({ inviteOpen: true, groupToEdit: group, groupUsersIdsToEdit });
  }

  async _handleOpenError(errorTitle, errorMessage) {
    await this.setStateAsync({ openError: true, errorTitle, errorMessage });
  }

  _notifySuccess(message) {
    toast.success(message);
  }

  _notifyError(message) {
    toast.error(message);
  }

  async _onUserQueryChange(e) {
    await this.setStateAsync({ userQuery: e.target.value });
  }

  _sortUsers(users) {
    return Quicksort.sort(users, (a, b) => a.name.localeCompare(b.name));
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <React.Fragment>
          <Row>
            <Col sm='12' className='citiesSelectFieldTitle'>
              <h5>{i18n.t('chooseTheCitiesConcernedByThisGroup')}</h5>
            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <Multiselect
                label={i18n.t('targetedCities')}
                isMulti={true}
                onChange={this._handleSelectMultiCitiesChange}
                options={this.state.userCities.map((city) => {
                  return { label: city.name, value: city.cityId };
                })}
                placeholder={i18n.t('targetedCities')}
                value={this.state.userCitiesSelected}
                className='multiSelect'
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  _renderAddGroup() {
    return (
      <React.Fragment>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('addAGroup')}</h2>
        </Row>
        <Form>
          <Row>
            <Col sm='12'>
              <TextField
                name='name'
                placeholder={i18n.t('addName')}
                fullWidth={true}
                label={i18n.t('name')}
                helperText={i18n.t('thisFieldIsRequired')}
                maxLength='255'
                onChange={this._handleInputChange}
                value={this.state.name}
              />
              <br />
            </Col>
          </Row>
          {this._renderCitiesSelect()}
          <Row>
            <Col sm='2'>
              <Button
                fullWidth={true}
                variant='contained'
                onClick={this._addGroup}
                className='addGroupButton'
              >
                {i18n.t('add')}
              </Button>
            </Col>
          </Row>
          <Dialog
            open={this.state.openError}
            onClose={this._handleCloseError}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{this.state.errorTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {this.state.errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this._handleCloseError} color='primary'>
                {'Ok'}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      </React.Fragment>
    );
  }

  _renderGroups() {
    return (
      <React.Fragment>
        <Row>
          <h2 className={'pageTitle'}>{i18n.t('groupManagement')}</h2>
        </Row>
        {
          this.state.userCities.length > 1 &&
          <Row className='citiesSelectorContainer'>
            <Col sm='6' className='citiesSelectField'>
              <FormControl fullWidth={true}>
                <InputLabel>{i18n.t('carecityCities')}</InputLabel>
                <Select
                  value={this.state.cityToFetch}
                  onChange={async (_event) => await this._filterGroups(_event.target.value)}
                >
                  <MenuItem key={0} value={0}>{i18n.t('allMyCities')} </MenuItem>;
                  {
                    this.state.userCities.map(function(city) {
                      return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
            </Col>
          </Row>
        }
        {this._renderGroupsCard()}
      </React.Fragment>
    );
  }

  _renderGroupsCard() {
    if (this.state.groups.length === 0) {
      return (
        <Row className='noData'>
          <h4>{i18n.t('noGroup')}</h4>
        </Row>
      );
    }
    else {
      return (
        this.state.groups && this.state.groups.map((group) =>
          <Card className='card' key={group.id}>
            {(User.getInstance().getUser().access_level === 'super_admin' || isArrayContainedInOtherArray(group.cityIds, this.state.userCityIds)) &&
              <IconButton
                className='actions'
                aria-label='More'
                aria-owns={this.state.open ? 'long-menu' : undefined}
                aria-haspopup='true'
                onClick={async (event) => await this._setAnchorEl(group.id, event.currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
            }
            <Menu
              id={group.id}
              anchorEl={this.state.anchorEls[group.id]}
              open={Boolean(this.state.anchorEls[group.id])}
              onClose={async () => await this._setAnchorEl(group.id, null)}
            >
              <MenuItem onClick={async () => await this._toggleEditing(group)}>{i18n.t('edit')}</MenuItem>
              <MenuItem onClick={async () => await this._handleOpenInviteModal(group)}>{i18n.t('invite')}</MenuItem>
              <MenuItem onClick={async () => await this._handleOpenDeleteModal(group.id)}>{i18n.t('remove')}</MenuItem>
              <Dialog
                open={this.state.open}
                onClose={this._handleClosePopup}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    {i18n.t('areYouSureYouWantToDeleteThis')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this._handleClosePopup} color='primary'>
                    {i18n.t('cancel')}
                  </Button>
                  <Button onClick={this._deleteGroup} color='primary'>
                    {i18n.t('confirm')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Menu>
            <Row>
              {!group.groupEditingEnabled &&
                <Col sm='8'>
                  <Row className='cardHeader'>
                    <p className='groupName'>{group.name}</p>
                  </Row>
                </Col>
              }
              {group.groupEditingEnabled &&
                <Col sm='8'>
                  <Row className='cardHeader'>
                    <TextField
                      name='nameEdited'
                      placeholder={i18n.t('addName')}
                      fullWidth={true}
                      label={i18n.t('name')}
                      helperText={i18n.t('thisFieldIsRequired')}
                      maxLength='255'
                      onChange={this._handleInputChange}
                      value={this.state.nameEdited}
                    />
                    <br />
                  </Row>
                </Col>
              }
            </Row>
            {this.state.userCityIds.length > 1 &&
              <Row className='cardHeader'>
                <p className='cardTitle'><span className='title'>{i18n.t('cities')}</span>{group.citiesName}</p>
              </Row>
            }
            <CardHeader
              actasexpander={'true'}
              showexpandablebutton={'true'}
            />
            <CardContent expandable={'true'}>
              <Row className='groupeUserListTitleContainer'>
                <p className='groupeUserListTitle'>{i18n.t('groupMembers')}</p>
              </Row>
              {this._renderGroupUsers(group)}
              {group.groupEditingEnabled &&
                <Row>
                  <Col sm='3'>
                    <Button
                      fullWidth={true}
                      variant='contained'
                      onClick={async () => await this._cancelEditing(group)}
                      className='editGroupCancelButton'
                    >
                      {i18n.t('cancel')}
                    </Button>
                  </Col>
                  <Col sm='3'>
                    <Button
                      fullWidth={true}
                      variant='contained'
                      onClick={async () => await this._editGroup(group)}
                      className='editGroupConfirmButton'
                    >
                      {i18n.t('edit')}
                    </Button>
                  </Col>
                </Row>
              }
            </CardContent>
          </Card>
        )
      );
    }
  }

  _renderGroupUsersEditing(group) {
    if (group.users.length === 0) {
      return (
        <Row className='noData'>
          <h5>{i18n.t('noUserInThisGroup')}</h5>
        </Row>
      );
    }
    else {
      return (
        group.users && this._sortUsers(group.users).map((user) =>
          <Row className='groupUserRow' key={user.id}>
            <Col sm='10'>
              <p className='groupUserName'>{user.name}</p>
            </Col>
            <Col sm='1'>
              <IconButton
                aria-label='Delete'
                onClick={async () => await this._toggleUserInGroup(false, user)}
              >
                <DeleteIcon />
              </IconButton>
            </Col>
          </Row>
        )
      );
    }
  }

  _renderGroupUsers(group) {
    if (group.users.length === 0) {
      return (
        <Row className='noData'>
          <h5>{i18n.t('noUserInThisGroup')}</h5>
        </Row>
      );
    }
    else {
      return (
        group.users && this._sortUsers(group.users).map((user) =>
          <Row key={user.id}>
            <Col sm='12'>
              <p>{user.name}</p>
            </Col>
          </Row>
        )
      );
    }
  }

  _renderGroupUsersInvitation() {
    const lowerCaseQuery = deburr(this.state.userQuery.toLowerCase());

    return (
      this.state.users && this._sortUsers(this.state.users).filter(x => deburr(x['name']).toLowerCase().includes(lowerCaseQuery)).map((user) =>
        <Row key={user.id} className='cityUserRow'>
          <Col sm='11' className='cityUserName'>
            <p>{user.name}</p>
          </Col>
          <Col sm='1' className='cityUserCheckbox'>
            <Checkbox
              checked={this.state.groupUsersIdsToEdit.includes(user.id)}
              onChange={async (_, value) => await this._toggleUserInGroup(value, user)}
              color='default'
            />
          </Col>
        </Row>
      )
    );
  }

  _renderModal() {
    return (
      <Modal
        isOpen={this.state.inviteOpen}
        onRequestClose={this._handleCloseModal}
        contentLabel='Example Modal'
      >
        <div className='Groups'>
          <div>
            <h4>{i18n.t('manageUsersOf')} {this.state.groupToEdit.name}</h4>
          </div>
          <Row>
            <Col sm='7' className='citizenTableContainer'>
              <div className='citizenTableHeader'>
                <h5>{i18n.t('citizens')}</h5>
              </div>
              <div>
                <Col sm='6'>
                  <TextField
                    className='searchTable'
                    placeholder={i18n.t('searchForAUser')}
                    fullWidth={true}
                    onChange={this._onUserQueryChange}
                    value={this.state.userQuery}
                  />
                </Col>
              </div>
              <div className='citizenTable'>
                {this._renderGroupUsersInvitation()}
              </div>
            </Col>
            <Col sm='5' className='groupTableContainer'>
              <div>
                <h5>{i18n.t('selected')}</h5>
              </div>
              <div className='groupTable'>
                {this._renderGroupUsersEditing(this.state.groupToEdit)}
              </div>
            </Col>
          </Row>
          <Row className='modalButtons'>
            <Button
              variant='contained'
              onClick={this._handleCloseModal}
              className='invitGroupCancelButton'
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              variant='contained'
              onClick={this._editGroupUsers}
              className='invitGroupConfirmButton'
            >
              {i18n.t('confirm')}
            </Button>
          </Row>
        </div>
      </Modal>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin' || User.getInstance().getUser().access_level === 'admin') {
      return (
        <Container className='Groups'>
          {this._renderAddGroup()}
          {this._renderGroups()}
          {this._renderModal()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Groups;

import React from 'react';
import { GoogleMap, Polygon, DrawingManager } from '@react-google-maps/api';
import { GoogleMapCustomStyle } from '../../utils/GoogleMapCustomStyle';

import './styles.scss';

function PolygonMap({ onZoneCompleted, currentZone, mapCenter, drawingModeEnabled }) {

  const onPolygonComplete = polygon => {
    polygon.setMap(null);
    const latlngs = polygon.getPath().getArray();
    if (latlngs.length < 3) {
      return;
    }
    const zone = latlngs.map(
      latLng => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });

    onZoneCompleted(zone);
    drawingModeEnabled = false;
  };

  return (
    <div className='PolygonMap'>
      <GoogleMap
        mapContainerClassName='map'
        center={mapCenter}
        zoom={12}
        version='weekly'
        options={{
          gestureHandling: 'greedy',
          streetViewControl: false,
          styles: GoogleMapCustomStyle.mapCustomStyles
        }}
      >
        <Polygon
          path={currentZone}
          options={{
            fillColor: '#6676f7',
            fillOpacity: 0.2,
            strokeColor: '#6676f7',
          }}
        />
        <DrawingManager
          options={{
            drawingControl: false,
            drawingMode: drawingModeEnabled ? 'polygon' : '',
            polygonOptions: {
              fillColor: '#6676f7',
              fillOpacity: 0.2,
              strokeColor: '#6676f7',
            }
          }}
          onPolygonComplete={onPolygonComplete}
        />
      </GoogleMap>
    </div>
  );
}

export default PolygonMap;
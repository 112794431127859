import HttpMessage, { ROOT_URL } from './HttpMessage';

import User from '../data/User';

class ParkingsService {

  static async fetchNightParking(cityId) {
    const message = {
      route: `${ROOT_URL}/night_parkings/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let nightParking = null;
    if (data.status === 'success') {
      nightParking = data.nightParking;
    }

    return nightParking;
  }

  static async addParkingZone(newNightParkingZone) {
    const message = {
      route: `${ROOT_URL}/night_parkings/${newNightParkingZone.night_parking_id}?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(newNightParkingZone),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async updateNightParking(nightParking) {
    const message = {
      route: `${ROOT_URL}/night_parkings/${nightParking.night_parking_id}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(nightParking),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async updateNightParkingZoneStatus(nightParking) {
    const message = {
      route: `${ROOT_URL}/night_parkings/zones?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(nightParking),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async deleteParkingZone(id) {
    const message = {
      route: `${ROOT_URL}/night_parkings/zones/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async fetchNightParkings() {
    const message = {
      route: `${ROOT_URL}/night_parkings?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const nightParkingsCitiesList = [];
    if (data.status === 'success') {
      data.nightParkings.forEach(val => {
        nightParkingsCitiesList.push(val.city_id);
      });
    }

    return nightParkingsCitiesList;
  }

  static async activateNightParking(newNightParking) {
    const message = {
      route: `${ROOT_URL}/night_parkings?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(newNightParking),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async deleteNightParking(id) {
    const message = {
      route: `${ROOT_URL}/night_parkings/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }
}

export default ParkingsService;

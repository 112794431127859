import React from 'react';
import AsyncComponent from '../AsyncComponent';
import MyMapWrapper from '../Map';
import Problems from '../../data/Problems';
import i18n from '../../i18n';

class ProblemMap extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      component: <span>{i18n.t('loading') + '...'}</span>,
    };

    this.problemsData = Problems.getInstance();
    this.clusterActive = this.problemsData.getClusterActive();
  }

  async componentDidMount() {
    const component = (<MyMapWrapper
      heatActive={!this.clusterActive}
      googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_KEY + '&v=3.exp&libraries=geometry,places,visualization,drawing'}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />);

    await this.setStateAsync({ component });
  }

  render() {
    this.clusterActive = this.problemsData.getClusterActive();
    return (
      this.state.component
    );
  }
}

export default ProblemMap;

/* eslint-disable no-template-curly-in-string */
const en = {
  'translation': {
    '403Message': 'We are sorry, but you are not authorized to see this page.',
    '403Title': '403 - Forbidden User',
    '404Message': 'We are sorry, but the page you are looking for doesn\'t exist.',
    '404Title': '404 - Page not found',
    'accessLevel': 'Access level',
    'account': 'Account',
    'active': 'Active',
    'activeCities': 'Active cities',
    'activeUsers': 'Active Users',
    'add': 'Add',
    'addACarecityCity': 'Add a Carecity city',
    'addAChoice': 'Add a choice',
    'addAGroup': 'Add a group',
    'addANightParkingZone': 'Add a night parking zone',
    'addAnImage': 'Add an image',
    'addComment': 'Add comment',
    'addContent': 'Add content',
    'addCountry': 'Add country',
    'addDaysSinceLastUpdate': 'Add days since last update',
    'addDescription': 'Add a description',
    'addEmail': 'Add email',
    'addHeaderMessage': 'Add header message',
    'addInactivityMessage': 'Add inactivity message',
    'additionalInformationAboutTheParkingArchive': 'Additional information about the parking archive',
    'additionalInformationEdit': 'Additional Information Edit',
    'addMessage': 'Add message',
    'addName': 'Add name',
    'addPriority': 'Add priority',
    'addQuestion': 'Add question',
    'addStateOrProvince': 'Add state or province',
    'addTitle': 'Add title',
    'addUrlLink': 'Add URL link',
    'administrator': 'Administrator',
    'alert': 'Alert',
    'alertDetailsHeader': 'Alert Details',
    'alertDuplicated': 'Alert duplicated',
    'alertEdit': 'Alert Edit',
    'alertHeader': 'Alerts',
    'alertProgramming': 'Alert Programming',
    'alertStopTime': 'Alert stop time',
    'alertTypes': 'Alert Types',
    'allMyCities': 'All my cities',
    'and': 'and',
    'anSmsOrAVoiceCallCannotBeChangedOnceSent': 'An SMS or a voice call cannot be changed once sent.',
    'approvalsByReport': 'Approvals by report',
    'areYouSureYouWantToDeleteThis': 'Are you sure you want to delete this?',
    'associate': 'Associate',
    'at': 'at',
    'authorized': 'Authorized',
    'authorizedReports': 'Authorized Reports',
    'automatedCalls': 'Automated calls',
    'averageNumberOfAppovalsBeforeAReportIsAccepted': 'Average number of approvals before a report is accepted',
    'averageNumberOfRepairsPerWeek': 'Average number of repairs per week',
    'averageTimeBetweenCreationAndClosingOfAReport': 'Average time between creation and closing of a report',
    'billing': 'Billing',
    'block': 'Block',
    'blockedUser': 'Following several complaints, Carecity has removed your rights to report. You can always view the reports around you and receive alerts from the communities you follow.',
    'blockedUserTitle': 'Rights Revoked',
    'breakdownOfReportingStatus': 'Breakdown of reporting status',
    'breakdownOftypesOfReports': 'Breakdown of types of reports',
    'byWhatMeansOfCommunicationDoYouWishToBeReached': 'By what means of communication do you wish to be reached?',
    'callMinutes': 'Call minutes',
    'callsAndSMS': 'Calls and SMS',
    'cancel': 'Cancel',
    'cannotEditThisAlert': 'Cannot edit this alert.',
    'cannotEditThisUser': 'Cannot edit this user.',
    'canReport': 'Can Report',
    'carecityAlertRegistrationForm': 'Carecity Alert Registration Form',
    'carecityCities': 'Carecity Cities',
    'carouselAlertFeed': 'Consult the alerts of the communities you follow',
    'carouselChooseProblem': 'Choose a type of report to send to your community',
    'carouselConfirmProblem': 'Confirm the location of the report',
    'carouselProfil': 'Manage the communities you follow and your preferences in your profile',
    'categories': 'Categories',
    'category': 'Category',
    'change': 'Change',
    'changeAuthor': 'Change author',
    'changeMyPassword': 'Change my password',
    'choice': 'Choice',
    'chooseACarecityCity': 'Choose a Carecity city',
    'chooseAnswer': 'You must choose an answer',
    'chooseCategory': 'Choose a category',
    'chooseFromLibrary': 'Choose from library',
    'chooseTheCitiesConcernedByThisGroup': 'Choose the cities concerned by this group',
    'cities': 'Cities',
    'citiesSuggestedByCitizens': 'Cities suggested by citizens',
    'citizenCallNumber': 'Citizen call number',
    'citizenCommunicationsEmail': 'Citizen communications email',
    'citizenEditor': 'Citizen editor',
    'citizenEmail': 'Citizen email',
    'citizens': 'Citizens',
    'citizenSmsNumber': 'Citizen SMS number',
    'city': 'City',
    'cityComment': 'City Comment',
    'cityInformations': 'City Informations',
    'cleanliness': 'Cleanliness',
    'closeTheSurvey': 'Close the survey',
    'communicationChannels': 'Communication channels',
    'communicationsEditor': 'Communications editor',
    'confirm': 'Confirm',
    'confirmationOfAlertDeliveryViaSms': 'Confirmation of alert delivery via SMS',
    'confirmationOfBlock': 'Confirmation of block',
    'confirmationOfDeletion': 'Confirmation of deletion',
    'confirmFacebookPageAssociaton': 'Confirm Facebook page association',
    'connect': 'Connect',
    'connectToAccessService': 'Connect to access service',
    'connectWithApple': 'Connect with Apple',
    'connectWithEmail': 'Connect with an email address',
    'connectWithFacebook': 'Connect with Facebook',
    'connectWithGoogle': 'Connect with Google',
    'consult': 'Consult',
    'contact': 'Contact',
    'contactCall': 'Contact call',
    'contactCityText': 'To report any request, we invite you to contact us.',
    'contactCityTitle': 'Contact',
    'contactInformationsTitle': 'If you wish to be contacted regarding your reports, fill in at least one of the fields below',
    'contactPlaceholder': 'How can we help you?',
    'contactSms': 'Contact SMS',
    'content': 'Content',
    'creationDate': 'Creation Date',
    'createZone': 'Create the zone',
    'day': 'Day',
    'days': 'Days',
    'deactivate': 'Deactivate my Account',
    'deactivateMessage': 'Are you sure you want to deactivate your Carecity account?',
    'defaultReportResponse': 'Thank you for your report!',
    'deleteImage': 'Delete image',
    'deleteZone': 'Delete zone',
    'delivered': 'Delivered',
    'deliveryStatus': 'Delivery status',
    'descriptionOfTheCitizen': 'Description of the citizen',
    'dispatchDate': 'Dispatch Date',
    'dispatchTime': 'Dispatch Time',
    'done': 'Done',
    'duplicate': 'Duplicate',
    'edit': 'Edit',
    'editTheImage': 'Edit the image',
    'email': 'Email',
    'endDateOfRecurrence': 'End date of recurrence',
    'english': 'English',
    'enterAChoice': 'Enter a choice',
    'enterTheAlertTitle': 'Enter the alert title',
    'enterTheCitizenCommunicationsEmail': 'Enter the citizen communication email',
    'enterTheCityName': 'Enter the city name',
    'enterTheCountry': 'Enter the country',
    'enterTheDate': 'Enter the date',
    'enterTheEmailAddressToSendNewReportsTo': 'Enter the email address to send new reports to',
    'enterTheEmailAddressToSendNotificationsOfReportStatusChange': 'Enter the email address to send notifications of report status change',
    'enterTheFullName': 'Enter the full name',
    'enterTheStateOrProvince': 'Enter the state or province',
    'enterTheSurveyTitle': 'Enter the survey title',
    'enterTheUserEmail': 'Enter the user email',
    'enterTheUserName': 'Enter the user name',
    'enterTheUserPhoneNumber': 'Enter the user phone number',
    'enterYourAddress': 'Enter your address',
    'enterYourCurrentPassword': 'Enter your current password',
    'enterYourEmail': 'Enter your email',
    'enterYourNewPassword': 'Enter your new password',
    'enterYourPassword': 'Enter your password',
    'errorDuringActivation': 'Error During Activation',
    'errorDuringAssociation': 'Error During Association',
    'errorDuringBlocking': 'Error During Blocking',
    'errorDuringCreation': 'Error During Creation',
    'errorDuringCreatorReport': 'Error During Creator Report',
    'errorDuringDeactivation': 'Error During Deactivation',
    'errorDuringDeletion': 'Error During Deletion',
    'errorDuringModification': 'Error During Modification',
    'errorDuringRegistration': 'Error During Registration',
    'errorDuringRegistrationConfirmation': 'Error during registration confirmation on the Carecity server.',
    'errorEmail': 'Please enter a valid email address.',
    'errorEmptyEmailPassword': 'Please complete all fields.',
    'errorNewPassword': 'Passwords do not match.',
    'errorPasswordStrength': 'Your password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one symbol.',
    'errorPhoneNumber': 'Please enter a valid phone number.',
    'errorSignIn': 'Your email or password is invalid.',
    'errorUserCreation': 'The user account could not be created. It may already exist.',
    'expectedDispatchOn': 'Expected on',
    'facebookAlertRedirectionPage': 'Facebook alert redirection page',
    'failed': 'Failed / Unsubscription',
    'feedback': 'Feedback',
    'feedbackText': 'Briefly explain what you love, or what we could improve.',
    'feedbackTitle': 'General Feedback',
    'filters': 'Filters',
    'flood': 'Flood',
    'followedCities': 'Followed Communities',
    'forgotPassword': 'Forgot your password?',
    'french': 'French',
    'from': 'from',
    'fullName': 'Full name',
    'goToSettings': 'Go to Settings',
    'graffiti': 'Graffiti',
    'groupManagement': 'Group management',
    'groupMembers': 'Group members',
    'groups': 'Groups',
    'gst': 'GST',
    'heatMap': 'Heat Map',
    'highRecallLevel': 'High Recall Level',
    'historyOfChanges': 'History of Changes',
    'id': 'ID',
    'imageLoadError': 'Unable to load the image',
    'imageUrlLink': 'Image URL link',
    'inactive': 'Inactive',
    'inactivityMessage': 'Inactivity message',
    'incorrectEmailOrPasswordWarning': 'The email or password is incorrect. If you don\'t remember your password, press \'Forgot password\'',
    'informationCurrentlyAvailableToCitizens': 'Information currently available to citizens',
    'informationDetailsHeader': 'Information Details',
    'informationOnTheReport': 'Information on the report',
    'invite': 'Invite',
    'isThisTheSameProblemYouAreReporting': 'Is this the same report you are reporting',
    'language': 'Language',
    'lastCommentFromTheMunicipality': 'Last comment from the municipality',
    'lastEditedXDaysAgo': 'Last Edited {{days}} days ago',
    'loading': 'Loading',
    'login': 'Login',
    'logins': 'Logins',
    'logout': 'Log out',
    'lowRecallLevel': 'Low Recall Level',
    'managementOfGeographicCities': 'Management of geographic cities',
    'managementOfReportingCategories': 'Management of Reporting Categories',
    'manageUsersOf': 'Manage users of',
    'markers': 'Markers',
    'messageOfAcknowledgmentOfReceiptOrNonProcessing': 'Message of acknowledgment of receipt or non-processing.',
    'mobileUsers': 'Mobile Users',
    'moderateRecallLevel': 'Moderate Recall Level',
    'month': 'Month',
    'moreDetails': 'More details',
    'municipalUsers': 'Municipal Users',
    'myAddresses': 'My Addresses',
    'myCities': 'My Communities',
    'myCity': 'My Community',
    'myFavoriteReports': 'My Favorite Reports',
    'myFavoriteReportsHeader': 'Favorite Reports',
    'name': 'Name',
    'nameOfTheCity': 'City name',
    'new': 'New',
    'newPassword': 'New password',
    'newPasswordAgain': 'New password, again',
    'nightParking': 'Night Parking',
    'nightParkingHistory': 'Night parking history',
    'no': 'No',
    'noAlerts': 'No alert to display',
    'noCity': 'No community to display',
    'noCityFollowed': 'You do not follow any communities',
    'noConnection': 'No internet connection',
    'noConnectionContent': 'Please, check internet connection on your smartphone to use the application',
    'noFacebookPage': 'No Facebook page',
    'noFavoriteReport': 'No report found in favorites',
    'noGroup': 'No group',
    'noHistory': 'No History',
    'noImage': 'You must add a picture to your report',
    'noInformation': 'No information',
    'noInformations': 'No information to display',
    'noMatches': 'No matches',
    'noNightParking': 'No parking notice',
    'noParkingZone': 'No Parking Zone',
    'noProblemCityComment': 'No comment provided',
    'noProblemDescription': 'No description provided',
    'noReport': 'No Report',
    'notAllowed': 'Not allowed',
    'notifications': 'Notifications',
    'noUserInThisGroup': 'No user in this group',
    'numberOfCarecityCitizens': 'Number of Carecity citizens',
    'numberOfDaysSinceLastUpdate': 'Number of days since last update',
    'numberOfDaysSinceTheLastModificationOfAReport': 'Number of days since the last modification of a report',
    'numberOfRequests': 'Number of requests',
    'numberOfSmsCharacters': 'Number of SMS characters : {{smsLength}}',
    'numberOfSmsSegments': 'Number of SMS segments : {{smsSegments}}',
    'numberOfSubscriptionsToAutomatedCalls': 'Number of subscriptions to automated calls',
    'numberOfSubscriptionsToSmsAlerts': 'Number of subscriptions to SMS alerts',
    'oldPassword': 'Current password',
    'openTheSurvey': 'Open the survey',
    'optional': 'The description is optional',
    'other': 'Other',
    'otherCity': 'Other city',
    'otherCityPlaceholder': 'Enter the city',
    'otherCityTitle': 'Enter the city where you would like to have Carecity implanted',
    'parking': 'Parking',
    'password': 'Password',
    'passwordReset': 'Password Reset',
    'periodicity': 'Periodicity',
    'permissionDenied': 'Permission denied',
    'permissionDeniedExplanation': 'To be able to take pictures with your camera and choose images from your library.',
    'phoneNumber': 'Phone number',
    'photo': 'Photo',
    'planned': 'Planned',
    'pleaseCompleteOrCancelTheChangesBeforeCreatingANewOne': 'Please complete or cancel the changes before creating a new one.',
    'pleaseFillInTheAddress': 'Please fill in the address.',
    'pleaseFillInTheCity': 'Please fill in the city.',
    'pleaseFillInTheFullName': 'Please fill in the full name.',
    'pleaseFillInTheMeansOfReceivingAlerts': 'Please fill in the means of receiving alerts.',
    'pleaseFillInThePhoneNumber': 'Please fill in the phone number with the following format: +1 (123) 456-7891.',
    'pleaseUpdateMessage': 'A new version of Carecity is now available. Please update your app to continue using Carecity.',
    'portal': 'Portal',
    'pothole': 'Pothole',
    'previousAlerts': 'Previous Alerts',
    'previousSurveys': 'Previous Surveys',
    'priority': 'Priority',
    'privacyPolicy': 'Privacy Policy',
    'profilHeader': 'Profile',
    'programmed': 'Programmed',
    'publicWorks': 'Public works',
    'publicWorksEditor': 'Public works editor',
    'pushNotification': 'Push Notification',
    'qst': 'QST',
    'quantity': 'Quantity',
    'rate': 'Rate',
    'receiveCityNotifications': 'Receive community\'s alerts',
    'received': 'Received',
    'receiveProblemsPushNotifications': 'Receive reports push notifications',
    'recurrence': 'Recurrence',
    'redirectionEmailForChangesInStatusOfReports': 'Redirection email for changes in status of reports',
    'redirectionEmailForNewReports': 'Redirection email for new reports',
    'redirectionEmailsForTheCity': 'Redirection emails to the city',
    'register': 'Register',
    'registration': 'Registration',
    'registrationSuccessfullyCompleted': 'Registration successfully completed!',
    'remove': 'Remove',
    'repairsPerWeek': 'Repairs per week',
    'repeatability': 'Repeatability',
    'report': 'Report',
    'reportButton': 'Report a problem',
    'reportCategories': 'Report Categories',
    'reportCoordinates': 'report coordinates',
    'reportCreatorToCarecity': 'Report creator to Carecity',
    'reportDate': 'Report Date',
    'reportedBy': 'Reported by',
    'reportedProblem': 'Reported reports',
    'reportRecallLevels': 'Report Recall Levels',
    'reports': 'Reports',
    'reportUrlLink': 'Report URL link',
    'retry': 'Retry',
    'save': 'Save',
    'scheduleTheAlert': 'Schedule the alert',
    'searchForACity': 'Search for a city',
    'searchForAnAlert': 'Search for an alert',
    'searchForAnArchive': 'Search for an archive',
    'searchForAPhoneNumber': 'Search for a phone number',
    'searchForASurvey': 'Search for a survey',
    'searchForAUser': 'Search for a user',
    'selectABillingMonth': 'Select a billing month',
    'selectACityToConsult': 'Select a city to consult',
    'selectAPhoto': 'Select a Photo',
    'selectCategories': 'Select categories',
    'selected': 'Selected',
    'selectStatuses': 'Select statuses',
    'selectTheMinimalApproval': 'Select the minimal approval',
    'send': 'Send',
    'sendAnAlert': 'Send an Alert',
    'sendASurvey': 'Send a Survey',
    'sendTheAlertOnAssociatedFacebookPage': 'Send the alert on associated Facebook page',
    'sendTheAlertViaCarecityPushNotification': 'Send the alert via Carecity push notification',
    'sendTheAlertViaSms': 'Send the alert via SMS',
    'sendTheAlertViaVoiceCall': 'Send the alert via voice call',
    'sendTimeAndStopTimeCannotBeEmpty': 'Send time and stop time cannot be empty.',
    'sent': 'Sent',
    'sentToGroups': 'Sent to Group(s):',
    'services': 'Services',
    'settingsAbout': 'About',
    'settingsContactCarecity': 'Contact Carecity',
    'settingsContactCity': 'Contact my Community',
    'settingsHeader': 'Settings',
    'settingsNotification': 'Notifications',
    'settingsPreferences': 'Preferences',
    'settingsPrivacy': 'Privacy Policies',
    'settingsSupport': 'Support',
    'settingsTerms': 'Terms and Conditions',
    'settingsUserInformations': 'My Informations',
    'settingsWebsite': 'Website',
    'signage': 'Signage',
    'signin': 'Sign In',
    'signinError': 'An error occured when trying to connect with your account. Please try again or use another Sign In method.',
    'signup': 'Sign Up',
    'signupWithEmail': 'New to Carecity? ',
    'skip': 'Skip',
    'smsAlerts': 'SMS Alerts',
    'smsSent': 'SMS sent',
    'smsDeliveryStatusNotAvailable': 'SMS delivery status not available for the moment.',
    'snowRemoval': 'Snow Removal',
    'startDate': 'Start date',
    'statistics': 'Statistics',
    'status': 'Status',
    'statuses': 'Statuses',
    'stopDate': 'Stop date',
    'streetFurniture': 'Street Furniture',
    'streetLight': 'Street Light',
    'submit': 'Submit',
    'subscriberAddress': 'Citizen coordinates',
    'subscriptions': 'Subscriptions',
    'subTotal': 'Sub-Total',
    'successfullyAssociated': 'Successfully associated',
    'successfullyBlocked': 'Successfully blocked',
    'successfullyCreated': 'Successfully created',
    'successfullyModified': 'Successfully modified',
    'successfullyRemoved': 'Successfully removed',
    'successfullyReported': 'Successfully reported',
    'successfullyUnblocked': 'Successfully unblocked',
    'successSignUp': 'Welcome to Carecity! You will receive a password to your email address within a few minutes.',
    'superAdministrator': 'Super Administrator',
    'survey': 'Survey',
    'surveyDetailHeader': 'Survey detail',
    'surveyDetails': 'Survey Details',
    'surveyEdit': 'Survey Edit',
    'surveyResults': 'Survey Results',
    'surveys': 'Surveys',
    'takeAPhoto': 'Take a photo',
    'targetAlertTitle': 'Target the alert',
    'targetedCities': 'Targeted Cities',
    'targetedGroups': 'Targeted Groups',
    'tellUsMoreAboutYourself': 'Tell Us More About Yourself',
    'theEndOfTheFirstAlertCannotBeBeforeSendingTheFristAlert': 'The end of the first alert cannot be before sending the first alert.',
    'thePriorityMustBeANumber': 'The priority must be a number.',
    'theSelectedFileIsNotInASupportedFormat': 'The selected file is not in a supported format.',
    'thisFieldIsOptional': 'This field is optional.',
    'thisFieldIsRequired': 'This field is required.',
    'title': 'Title',
    'to': 'to',
    'totalNumberOfCitizensSubscribedToTheCity': 'Total number of citizens subscribed to the city',
    'totalNumberOfReports': 'Total number of reports',
    'transfer': 'Transfer',
    'treatedProblem': 'Treated reports',
    'tree': 'Tree',
    'undelivered': 'Undelivered',
    'urlLink': 'URL Link',
    'user': 'User',
    'userCities': 'User Cities',
    'userEdit': 'User Edit',
    'userManagement': 'User Management',
    'validateRegistration': 'Validate Registration',
    'voiceAlerts': 'Voice Alerts',
    'voiceCall': 'Voice Call',
    'waterPressure': 'Water Main Breaks',
    'week': 'Week',
    'whatIsYourCity': 'What is your community?',
    'whatIsYourCityPlaceholder': 'Choose your community',
    'yes': 'Yes',
    'youAreAboutToBlockAUser': 'You are about to block a user.',
    'youAreAboutToUnblockAUser': 'You are about to unblock a user.',
    'youAreAlreadyEditingAnotherGroup': 'You are already modifying another group.',
    'youAreAlreadyEditingAnotherInformation': 'You are already modifying another information.',
    'youCannotDeleteYourUser': 'You cannot delete your user.',
    'youCannotHaveAnEmptyChoice': 'You cannot have an empty choice.',
    'youDoNotHaveAccessToAllCitiesInThisAlert': 'You do not have access to all cities linked to this alert.',
    'youMustAdd2Choices': 'You must add at least two choices.',
    'youMustAddAContent': 'You must add a content.',
    'youMustAddAName': 'You must add a name.',
    'youMustAddATitle': 'You must add a title.',
    'youMustChooseASingleFacebookPageToAssociateWithYourCarecityCity': 'You must choose a single Facebook page to associate with your Carecity city.',
    'youMustChooseAtLeastOneCity': 'You must choose at least one city.',
    'youMustChooseAtLeastOneCityOrGroup': 'You must choose at least one city or group.',
    'youMustChooseAtLeastOneSendingMethod': 'You must choose at least one sending method (Push, SMS, Voice call or Facebook)',
    'youMustEnterAnEndDataThatIsAfterTheStartDate': 'You must enter an end date that is after the start date.',
    'youMustEnterAValidEmailAddress': 'You must enter a valid email address.',
    'youMustEnterAValidEmailAddressOrLeaveTheFieldBlank': 'You must enter a valid email address or leave the field blank.',
    'youMustEnterAZoneName': 'You must enter a zone name.',
    'youMustEnterConsistentValuesForTheThresholds': 'You must enter consistent values for the thresholds.',
    'youMustEnterNumericValuesForTheThresholds': 'You must enter numeric values for the thresholds.',
    'youMustEnterTheNameStateAndCountryOfTheNewCarecityCity': 'You must enter the name, state and country of the new Carecity city.',
    'youMustEnterYourOldPasswordAndYourNewPasswordTwice': 'You must enter your old password and your new password twice.',
    'youMustHaveAContentWithLessThan1600CharactersForSmsAlerts': 'You must have a content with less than 1600 characters for SMS alerts. The current length of the SMS is {{nbCharacters}} characters.',
    'youMustHaveAContentWithLessThan4096CharactersForVoiceAlerts': 'You must have a content with less than 4096 characters for voice alerts. The current length of the message is {{nbCharacters}} characters.',
    'yourAlertWillCostXPerCitizenSubscribedToSmsAlerts': 'Your alert will cost ${{smsPrice}} per citizen subscribed to SMS alerts. Do you wish to send this alert via SMS?',
    'yourCarecityCityIsAssociatedWithTheFacebookPage': 'Your Carecity city is associated with the Facebook page',
    'yourCarecityCityIsNotAssociatedWithAFacebookPage': 'Your Carecity city is not associated with a Facebook page',
    'yourUrlLinkIsInvalid': 'Your URL link is invalid. You must have one of the following formats: https://www.carecity.io or http://www.carecity.io',
    'zoneHeaderMessage': 'Zone header message',
    'zonesStatus': 'Zone status',
    'zoomInfo': 'Please zoom in to confirm the location of your report.',
  }
};

export default en;

import HttpMessage, { ROOT_URL } from './HttpMessage';

import { getHttpCitiesFilter } from '../utils/Filters';
import Cities from '../data/Cities';
import User from '../data/User';

class InformationsService {

  static async fetchInformations(cityIds) {
    const citiesFilters = getHttpCitiesFilter(cityIds);
    const message = {
      route: `${ROOT_URL}/city_informations?${citiesFilters}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const informationsList = [];
    if (data.status === 'success') {
      await Promise.all(data.informations.map(async val => {
        const obj = {};
        obj.cityId = val.city_id;
        obj.cityName = await Cities.getInstance().getCitiesName([val.city_id]);
        obj.content = val.content;
        obj.id = val.id;
        obj.moreInformationUrl = val.more_information_url;
        obj.pictureUrl = val.picture_url;
        obj.title = val.title;
        obj.priority = val.priority;
        obj.modificationsEnabled = false;

        informationsList.push(obj);
      }));
    }

    return informationsList;
  }

  static async addInformation(information, imageFile) {
    const message = {
      route: `${ROOT_URL}/city_informations?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(information),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    if (data.status === 'success') {
      if (data.signedURL) {
        HttpMessage.postImage(imageFile, data.signedURL);
      }
    }

    return data.status === 'success';
  }

  static async editInformation(information, imageFile) {
    const message = {
      route: `${ROOT_URL}/city_informations/${information.id}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(information),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    if (data.status === 'success') {
      if (data.signedURL) {
        HttpMessage.postImage(imageFile, data.signedURL);
      }
    }

    return data.status === 'success';
  }

  static async deleteInformation(id) {
    const message = {
      route: `${ROOT_URL}/city_informations/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }
}

export default InformationsService;

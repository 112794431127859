import moment from 'moment-timezone';

import HttpMessage, { ROOT_URL } from './HttpMessage';

import { getHttpCitiesFilter } from '../utils/Filters';
import User from '../data/User';

class CitizensService {

  static async fetchUsersApp(cityIds) {
    const citiesFilters = getHttpCitiesFilter(cityIds);

    const message = {
      route: `${ROOT_URL}/users_app?token=${User.getInstance().getUser().token}&${citiesFilters}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const usersList = [];
    if (data.status === 'success') {
      data.users.forEach(val => {
        const obj = {};
        obj.id = val.id;
        obj.name = val.name;
        obj.createdAt = moment(val.created_at).format('YYYY-MM-DD');
        obj.canReport = val.can_report;

        usersList.push(obj);
      });
    }

    return usersList;
  }

  static async updateCitizen(citizen) {
    const message = {
      route: `${ROOT_URL}/users_app/blocked?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(citizen),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }
}

export default CitizensService;

import moment from 'moment-timezone';

import HttpMessage, { ROOT_URL } from './HttpMessage';

import User from '../data/User';

class SubscribersService {

  static async fetchSubscribers(cityId, subscriberType) {
    const message = {
      route: `${ROOT_URL}/subscribers?token=${User.getInstance().getUser().token}&city_id=${cityId}&subscriber_type=${subscriberType}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const subscribersList = [];
    if (data.status === 'success') {
      data.subscribers.forEach(val => {
        const obj = {};
        obj.name = val.name;
        obj.phoneNumber = val.phone_number;
        obj.createdAt = moment(val.created_at).format('YYYY-MM-DD');
        obj.latlng = val.latlng;
        obj.id = cityId + subscriberType + obj.phoneNumber;
        if (obj.latlng) {
          obj.id += obj.latlng.lat + obj.latlng.lat;
        }
        obj.subscriberType = val.subscriber_type;
        obj.cityId = val.city_id;

        subscribersList.push(obj);
      });
    }

    return subscribersList;
  }

  static async subscribe(subscription) {
    const message = {
      route: `${ROOT_URL}/subscribers/forceSubscription?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(subscription),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async unsubscribe(subscription) {
    const message = {
      route: `${ROOT_URL}/subscribers/forceUnsubscription?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(subscription),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }
}

export default SubscribersService;

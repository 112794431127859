import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BlockIcon from '@material-ui/icons/Block';
import InfoIcon from '@material-ui/icons/Info';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import i18n from '../../i18n';

class TableComponent extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEls: {},
      open: false,
    };
  }

  async _setAnchorEl(id, anchorEl) {
    const anchorEls = this.state.anchorEls;
    anchorEls[id] = anchorEl;
    await this.setStateAsync({ anchorEls });
  }

  _row(x, i, props, showActionsIcon) {
    return (
      <TableRow key={`tr-${i}`} selectable={'false'}>
        {
          props.header.map((y, k) => {
            const content = typeof (x[y.prop]) === 'boolean' ? (x[y.prop] ? i18n.t('yes') : i18n.t('no')) : x[y.prop];
            return (
              <TableCell key={`trc-${k}`} title={content}>
                {y.generateContent ? y.generateContent(x[y.prop]) : content}
              </TableCell>
            );
          })
        }
        {
          showActionsIcon &&
          <TableCell title={'Actions'}>
            <IconButton
              className='actions'
              aria-label='More'
              aria-owns={this.state.open ? 'long-menu' : undefined}
              aria-haspopup='true'
              onClick={async (event) => await this._setAnchorEl(x.id, event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={x.id}
              anchorEl={this.state.anchorEls[x.id]}
              open={Boolean(this.state.anchorEls[x.id])}
              onClose={async () => await this._setAnchorEl(x.id, null)}
            >
              {
                (props.handleConsult && (!props.isConsultSupported || props.isConsultSupported(x))) &&
                <MenuItem onClick={async () => {
                  props.handleConsult(x); await this._setAnchorEl(x.id, null);
                }}>{i18n.t('consult')}</MenuItem>
              }
              {
                (props.handleConsult && props.isConsultSupported && !props.isConsultSupported(x)) &&
                <MenuItem disabled={true}>{i18n.t('consult')}</MenuItem>
              }
              {
                (props.handleStatus && (!props.isStatusSupported || props.isStatusSupported(x))) &&
                  <MenuItem onClick={async () => {
                    props.handleStatus(x); await this._setAnchorEl(x.id, null);
                  }}>{i18n.t('deliveryStatus')}</MenuItem>
              }
              {
                (props.handleStatus && props.isStatusSupported && !props.isStatusSupported(x)) &&
                  <MenuItem disabled={true}>{i18n.t('deliveryStatus')}</MenuItem>
              }
              {
                (props.handleEdit && (!props.isEditSupported || props.isEditSupported(x))) &&
                <MenuItem onClick={async () => {
                  props.handleEdit(x); await this._setAnchorEl(x.id, null);
                }}>{i18n.t('edit')}</MenuItem>
              }
              {
                (props.handleEdit && props.isEditSupported && !props.isEditSupported(x)) &&
                <MenuItem disabled={true}>{i18n.t('edit')}</MenuItem>
              }
              {
                (props.handleDuplicate && (!props.isDuplicateSupported || props.isDuplicateSupported(x))) &&
                <MenuItem onClick={async () => {
                  props.handleDuplicate(x); await this._setAnchorEl(x.id, null);
                }}>{i18n.t('duplicate')}</MenuItem>
              }
              {
                (props.handleDuplicate && props.isDuplicateSupported && !props.isDuplicateSupported(x)) &&
                <MenuItem disabled={true}>{i18n.t('duplicate')}</MenuItem>
              }
              {
                (props.handleRemove && (!props.isRemoveSupported || props.isRemoveSupported(x))) &&
                <MenuItem onClick={async () => {
                  props.handleRemove(x); await this._setAnchorEl(x.id, null);
                }}>{i18n.t('remove')}</MenuItem>
              }
              {
                (props.handleRemove && props.isRemoveSupported && !props.isRemoveSupported(x)) &&
                <MenuItem disabled={true}>{i18n.t('remove')}</MenuItem>
              }
            </Menu>
          </TableCell>
        }
        {
          props.handleBlock &&
          <TableCell title={!props.isBlockSupported || props.isBlockSupported(x) ? i18n.t('block') : props.blockNotSupportedReason(x)}>
            {
              (!props.isBlockSupported || props.isBlockSupported(x)) &&
              <BlockIcon style={{ cursor: 'pointer' }} onClick={() => props.handleBlock(x)} />
            }
            {
              (props.isBlockSupported && !props.isBlockSupported(x)) &&
              <BlockIcon color='disabled' onClick={() => { }} />
            }
          </TableCell>
        }
        {
          props.handleMoreInformations &&
          <TableCell title={!props.isMoreInformationsSupported || props.isMoreInformationsSupported(x) ? i18n.t('moreDetails') : props.moreInformationsNotSupportedReason(x)}>
            {
              (!props.isMoreInformationsSupported || props.isMoreInformationsSupported(x)) &&
              <InfoIcon style={{ cursor: 'pointer' }} onClick={() => props.handleMoreInformations(x)} />
            }
            {
              (props.isMoreInformationsSupported && !props.isMoreInformationsSupported(x)) &&
              <InfoIcon color='disabled' onClick={() => { }} />
            }
          </TableCell>
        }
      </TableRow >
    );
  }

  render() {
    const showActionsIcon = this.props.handleConsult || this.props.handleEdit || this.props.handleDuplicate || this.props.handleRemove;

    return (
      <TableContainer style={{ maxHeight: 400, marginBottom: 50 }}>
        <Table stickyHeader >
          <TableHead displayselectall={'false'} adjustforcheckbox={'false'}>
            <TableRow>
              {this.props.header.map((x, i) =>
                <TableCell key={`thc-${i}`}>
                  <div style={{ display: 'flex', alignItems: 'center', minWidth: 120 }} onClick={() => this.props.handleSort(x.prop)}>
                    <span style={{ cursor: 'pointer' }}>{x.name}</span>
                    {
                      this.props.columnToSort === x.prop ? (
                        this.props.sortDirection === 'asc' ?
                          <ExpandLessIcon style={{ cursor: 'pointer' }} /> :
                          <ExpandMoreIcon style={{ cursor: 'pointer' }} />
                      ) : null
                    }
                  </div>
                </TableCell>
              )}
              {showActionsIcon &&
                <TableCell />
              }
              {this.props.handleBlock &&
                <TableCell />
              }
              {this.props.handleMoreInformations &&
                <TableCell />
              }
            </TableRow>
          </TableHead>
          <TableBody displayrowcheckbox={'false'}>
            {this.props.data.map((x, i) => this._row(x, i, this.props, showActionsIcon))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default TableComponent;

import UserService from '../services/UsersService';
import Cities from './Cities';
import { history } from '../routes/history';

class User {

  static getInstance() {
    if (!this.myInstance) {
      this.myInstance = new User();
      this.myInstance.user = JSON.parse(localStorage.getItem('user'));
    }

    return this.myInstance;
  }

  async fetchUser(username, password) {
    this.user = await UserService.login(username, password);
    localStorage.setItem('user', JSON.stringify(this.user));

    return this.user;
  }

  async updateToken() {
    const user = this.getUser();
    if (user) {
      const token = await UserService.updateToken();
      if (token) {
        user.token = token;
        localStorage.setItem('user', JSON.stringify(user));
      }
      else {
        this.logout();
        history.push('/login');
      }
    }
  }

  getUser() {
    return this.user;
  }

  async getUserCity() {
    if (this.getUser().access_level === 'super_admin') {
      return {};
    }

    const userCities = await this.getUserCities();
    return userCities.length ? userCities[0] : {};
  }

  async getUserCities() {
    const cities = (await Cities.getInstance().getCities()).slice();
    if (this.getUser().access_level === 'super_admin') {
      cities.push({ cityId: 'null', name: 'Carecity', state: '', country: '', parkingEnabled: false });
      return cities;
    }

    return cities.filter(city => this.getUser().city_ids.find(cityId => city.cityId === cityId));
  }

  logout() {
    this.user = null;
    localStorage.removeItem('user');
  }
}

export default User;

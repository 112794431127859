import HttpMessage, { ROOT_URL } from './HttpMessage';

import User from '../data/User';

class StatisticsService {

  static async fetchStats(cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}/stats?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let stats = null;
    if (data.status === 'success') {
      stats = data.stats;
    }

    return stats;
  }
}

export default StatisticsService;

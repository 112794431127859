import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Col, Container, Row } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Pie } from 'react-chartjs-2';
import Select from '@material-ui/core/Select';

import AccessForbidden from '../../components/AccessForbidden';
import { ProblemCategories, ProblemStatus } from '../../utils/Enums';
import StatisticsService from '../../services/StatisticsService';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

class Stats extends AsyncComponent {
  constructor(props) {
    super(props);

    this.state = {
      new_problems_count: 0,
      planned_problems_count: 0,
      received_problems_count: 0,
      done_problems_count: 0,
      streetLight_problems_count: 0,
      streetFurniture_problems_count: 0,
      pothole_problems_count: 0,
      graffiti_problems_count: 0,
      waterPressure_problems_count: 0,
      tree_problems_count: 0,
      cleanliness_problems_count: 0,
      snowRemoval_problems_count: 0,
      other_problems_count: 0,
      problems_count: 0,
      followers_count: 0,
      average_thumbs_up_count_before_accepting_problem: 0,
      average_reparations_per_week: 0,
      average_time_between_problem_creation_and_conclusion: 0,
      userCities: [],
    };
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchStats(this.state.city);
  }

  async _fetchStats() {
    const stats = await StatisticsService.fetchStats(this.state.city);
    await this.setStateAsync(stats);
  }

  async _fetchCityStats(city) {
    await this.setStateAsync({ city });
    await this._fetchStats();
  }

  async _getUserCities() {
    const userCities = (await User.getInstance().getUserCities()).filter(city => city.cityId !== 'null');
    await this.setStateAsync({ userCities, city: userCities[0].cityId });
  }

  _renderPieSatus() {
    const dataProbDivisionStatus = {
      labels: [
        ProblemStatus['new'].name,
        ProblemStatus['received'].name,
        ProblemStatus['planned'].name,
        ProblemStatus['done'].name,
      ],
      datasets: [{
        data: [
          this.state.new_problems_count,
          this.state.received_problems_count,
          this.state.planned_problems_count,
          this.state.done_problems_count
        ],
        backgroundColor: [
          '#725ac1',
          '#242038',
          '#6676f7',
          '#cac4ce'
        ],
        hoverBackgroundColor: [
          '#725ac1',
          '#242038',
          '#6676f7',
          '#cac4ce'
        ]
      }]
    };

    return (
      <Pie data={dataProbDivisionStatus} />
    );
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <Row className='citiesSelectContainer'>
          <Col sm='6'>
            <FormControl fullWidth={true}>
              <InputLabel>{i18n.t('selectACityToConsult')}</InputLabel>
              <Select
                value={this.state.city}
                onChange={async (_event) => await this._fetchCityStats(_event.target.value)}
              >
                {
                  this.state.userCities.map(function(city) {
                    return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
      );
    }
  }

  _renderPieTypes() {
    const dataProbDivisionStatus = {
      labels: [
        ProblemCategories['streetLight'].name,
        ProblemCategories['streetFurniture'].name,
        ProblemCategories['pothole'].name,
        ProblemCategories['graffiti'].name,
        ProblemCategories['waterPressure'].name,
        ProblemCategories['tree'].name,
        ProblemCategories['cleanliness'].name,
        ProblemCategories['snowRemoval'].name,
        ProblemCategories['other'].name,
      ],
      datasets: [{
        data: [
          this.state.streetLight_problems_count,
          this.state.streetFurniture_problems_count,
          this.state.pothole_problems_count,
          this.state.graffiti_problems_count,
          this.state.waterPressure_problems_count,
          this.state.tree_problems_count,
          this.state.cleanliness_problems_count,
          this.state.snowRemoval_problems_count,
          this.state.other_problems_count
        ],
        backgroundColor: [
          '#242038',
          '#736B92',
          '#6676f7',
          '#725ac1',
          '#91e5f6',
          '#86fbce',
          '#fd6063',
          '#cac4ce',
          '#AFAFDC'
        ],
        hoverBackgroundColor: [
          '#242038',
          '#736B92',
          '#6676f7',
          '#725ac1',
          '#91e5f6',
          '#86fbce',
          '#fd6063',
          '#cac4ce',
          '#AFAFDC'
        ]
      }]
    };

    return (
      <Pie data={dataProbDivisionStatus} />
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin' || User.getInstance().getUser().access_level === 'admin') {
      return (
        <Container className='Stats'>
          {this._renderCitiesSelect()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('statistics')}</h2>
          </Row>
          <Card className='card'>
            <CardHeader
              title={i18n.t('breakdownOfReportingStatus')}
              className='title'
              showexpandablebutton={'false'}
            />
            <CardContent expandable={'false'}>
              {this._renderPieSatus()}
            </CardContent>
          </Card>
          <Card className='card'>
            <CardHeader
              title={i18n.t('breakdownOftypesOfReports')}
              className='title'
              showexpandablebutton={'false'}
            />
            <CardContent expandable={'false'}>
              {this._renderPieTypes()}
            </CardContent>
          </Card>
          <Card className='card'>
            <CardHeader
              title={i18n.t('totalNumberOfReports')}
              className='title'
              showexpandablebutton={'false'}
            />
            <CardContent className='numberBigCard' expandable={'false'}>
              <h1>{this.state.problems_count}<span> {i18n.t('reports')}</span></h1>
            </CardContent>
          </Card>
          <Card className='card'>
            <CardHeader
              title={i18n.t('totalNumberOfCitizensSubscribedToTheCity')}
              className='title'
              showexpandablebutton={'false'}
            />
            <CardContent className='numberBigCard' expandable={'false'}>
              <h1>{this.state.followers_count}<span> {i18n.t('citizens')}</span></h1>
            </CardContent>
          </Card>
          <Card className='card'>
            <CardHeader
              title={i18n.t('averageNumberOfAppovalsBeforeAReportIsAccepted')}
              className='title'
              showexpandablebutton={'false'}
            />
            <CardContent className='numberBigCard' expandable={'false'}>
              <h1>{Math.round(this.state.average_thumbs_up_count_before_accepting_problem * 100) / 100}<span> {i18n.t('approvalsByReport')}</span></h1>
            </CardContent>
          </Card>
          <Card className='card'>
            <CardHeader
              title={i18n.t('averageNumberOfRepairsPerWeek')}
              className='title'
              showexpandablebutton={'false'}
            />
            <CardContent className='numberBigCard' expandable={'false'}>
              <h1>{Math.round(this.state.average_reparations_per_week * 100) / 100}<span> {i18n.t('repairsPerWeek')}</span></h1>
            </CardContent>
          </Card>
          <Card className='card'>
            <CardHeader
              title={i18n.t('averageTimeBetweenCreationAndClosingOfAReport')}
              className='title'
              showexpandablebutton={'false'}
            />
            <CardContent className='numberBigCard' expandable={'false'}>
              <h1>{Math.round(this.state.average_time_between_problem_creation_and_conclusion / 1000 / 60 / 60 / 24 * 100) / 100}<span> {i18n.t('days')}</span></h1>
            </CardContent>
          </Card>
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Stats;

import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import i18n from '../../i18n';

class AccessForbidden extends AsyncComponent {
  render() {
    return (
      <div>
        <h1>{i18n.t('403Title')}</h1>
        <p>{i18n.t('403Message')}</p>
      </div>
    );
  }
}

export default AccessForbidden;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import './styles.scss';

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('user')
      ?
      <React.Fragment>
        <Header />
        <Component {...props}/>
        <div className='Filler'></div>
        <Footer />
      </React.Fragment>
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )} />
);

import HttpMessage, { ROOT_URL } from './HttpMessage';

class FacebookService {

  static async fetchFacebookPages(userId, shortLivedUserAccessToken) {
    const message = {
      route: `${ROOT_URL}/facebook/pages?user_id=${userId}&short_lived_user_access_token=${shortLivedUserAccessToken}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data && data.status !== 'error' && data.pages;
  }
}

export default FacebookService;
export const ROOT_URL = 'https://api.carecity.io';

class HttpMessage {

  static async sendMessage(message) {
    let returnValue = null;
    try {
      const response = await fetch(message.route, {
        method: message.method,
        body: message.body,
        header: message.header
      });

      returnValue = await response.json();
      if (!returnValue || returnValue.status === 'error') {
        console.log(returnValue);
      }
    }
    catch (error) {
      console.warn(error);
      returnValue = { status: 'error', message: 'Error encountered.' };
    }

    return returnValue;
  }

  static postImage(imageFile, presignedUrl) {
    const imageType = 'image/jpeg';
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', presignedUrl, false);
    xhr.setRequestHeader('X-Amz-ACL', 'public-read');
    xhr.setRequestHeader('Content-Type', imageType);
    try {
      xhr.send(imageFile);
    }
    catch (e) {
      // Do nothing
    }
  }
}

export default HttpMessage;

import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Slider from '@material-ui/core/Slider';
import { Container, Row, Col, ButtonGroup, Button, Collapse, Card, CardBody } from 'reactstrap';
import Multiselect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faCalendar from '@fortawesome/fontawesome-free-solid/faCalendar';
import faStopwatch from '@fortawesome/fontawesome-free-solid/faStopwatch';
import faThumbsUp from '@fortawesome/fontawesome-free-solid/faThumbsUp';
import faThumbsDown from '@fortawesome/fontawesome-free-solid/faThumbsDown';
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';
import faCaretUp from '@fortawesome/fontawesome-free-solid/faCaretUp';
import faSortAmountUp from '@fortawesome/fontawesome-free-solid/faSortAmountUp';
import faSortAmountDown from '@fortawesome/fontawesome-free-solid/faSortAmountDown';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import AccessForbidden from '../../components/AccessForbidden';
import { ProblemCategories, ProblemStatus } from '../../utils/Enums';
import CitiesService from '../../services/CitiesService';
import Problems from '../../data/Problems';
import ProblemList from '../../components/ProblemList';
import ProblemMap from '../../components/ProblemMap';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

class BrowseProblems extends AsyncComponent {

  constructor(props) {
    super(props);
    this.state = {
      cityId: 0,
      categories: [],
      cluster: true,
      collapseCategories: false,
      collapseThumbsUp: false,
      collapseStatus: false,
      maxThumbsUp: 1,
      reminderLevels: null,
      sortDaysSinceLastUpdate: 0,
      sortId: 1,
      sortThumbsUp: 0,
      sortThumbsDown: 0,
      status: [],
      userCities: [],
    };

    this.problemsData = Problems.getInstance();

    this._toggleCategories = this._toggleCategories.bind(this);
    this._toggleThumbsUp = this._toggleThumbsUp.bind(this);
    this._toggleStatus = this._toggleStatus.bind(this);
    this._toggleSortDaysSinceLastUpdate = this._toggleSortDaysSinceLastUpdate.bind(this);
    this._toggleSortId = this._toggleSortId.bind(this);
    this._toggleSortThumbsUp = this._toggleSortThumbsUp.bind(this);
    this._toggleSortThumbsDown = this._toggleSortThumbsDown.bind(this);
    this._handleSelectCategorieChange = this._handleSelectCategorieChange.bind(this);
    this._handleSelectStatusChange = this._handleSelectStatusChange.bind(this);
    this._handleSlider = this._handleSlider.bind(this);
    this._toggleHeat = this._toggleHeat.bind(this);
    this._toggleCluster = this._toggleCluster.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchCity(this.state.cityId);
    this.problemsData.setSortType(1);
  }

  componentWillUnmount() {
    this.problemsData.clearListeners();
  }

  async _getUserCities() {
    const userCityIds = User.getInstance().getUser().city_ids;
    let userCities = await User.getInstance().getUserCities();
    userCities = userCities.filter(city => city.cityId !== 'null');
    await this.setStateAsync({ userCities, userCityIds });
    if (userCities.length === 1) {
      await this.setStateAsync({ cityId: userCities[0].cityId });
    }
  }

  async _fetchCity(cityId) {
    await this.setStateAsync({ cityId });
    await this._fetchProblems();
    await this._fetchReminderLevels();
  }

  async _fetchProblems() {
    const citiesToFetchProblemsFrom = this.state.cityId === 0 ? this.state.userCityIds : [this.state.cityId];
    await this.problemsData.fetchProblems(citiesToFetchProblemsFrom);
    const maxThumbsUp = this.problemsData.getMaxThumbsUp();
    if (maxThumbsUp > 0) {
      await this.setStateAsync({ maxThumbsUp: maxThumbsUp });
    }
  }

  async _fetchReminderLevels() {
    if (this.state.cityId) {
      const city = await CitiesService.fetchCity(this.state.cityId);
      await this.setStateAsync({ reminderLevels: city.report_reminder_level });
    }
    else {
      await this.setStateAsync({ reminderLevels: null });
    }
  }

  async _handleSelectCategorieChange(categories) {
    categories = categories ? categories : [];
    await this.setStateAsync({ categories });
    this.problemsData.setCategories(categories.map(category => category.value));
  }

  async _handleSelectStatusChange(status) {
    status = status ? status : [];
    await this.setStateAsync({ status });
    this.problemsData.setStatus(status.map(singleStatus => singleStatus.value));
  }

  async _handleSlider(_event, value) {
    await this.setStateAsync({ slider: value });
    this.problemsData.setMinThumbsUp(value);
  }

  _isSortActive(sortType) {
    if (sortType === 1 || sortType === 2) {
      return true;
    }
    else {
      return false;
    }
  }

  async _toggleCategories() {
    await this.setStateAsync({
      collapseThumbsUp: false,
      collapseStatus: false,
      collapseCategories: !this.state.collapseCategories
    });
  }

  async _toggleCluster() {
    await this.setStateAsync({
      cluster: true
    });
    this.problemsData.setClusterActive(true);
  }

  async _toggleHeat() {
    await this.setStateAsync({
      cluster: false
    });
    this.problemsData.setClusterActive(false);
  }

  async _toggleSortDaysSinceLastUpdate() {
    let sortValue = 1;
    if (this.state.sortDaysSinceLastUpdate === 1) {
      sortValue = 2;
    }

    await this.setStateAsync({
      sortDaysSinceLastUpdate: sortValue,
      sortId: 0,
      sortThumbsUp: 0,
      sortThumbsDown: 0,
    });

    const sortType = 6 + sortValue;
    this.problemsData.setSortType(sortType);
  }

  async _toggleSortId() {
    let sortValue = 1;
    if (this.state.sortId === 1) {
      sortValue = 2;
    }

    await this.setStateAsync({
      sortDaysSinceLastUpdate: 0,
      sortId: sortValue,
      sortThumbsUp: 0,
      sortThumbsDown: 0,
    });

    const sortType = 0 + sortValue;
    this.problemsData.setSortType(sortType);
  }

  async _toggleSortThumbsDown() {
    let sortValue = 1;
    if (this.state.sortThumbsDown === 1) {
      sortValue = 2;
    }

    await this.setStateAsync({
      sortDaysSinceLastUpdate: 0,
      sortId: 0,
      sortThumbsUp: 0,
      sortThumbsDown: sortValue,
    });

    const sortType = 4 + sortValue;
    this.problemsData.setSortType(sortType);
  }

  async _toggleSortThumbsUp() {
    let sortValue = 1;
    if (this.state.sortThumbsUp === 1) {
      sortValue = 2;
    }

    await this.setStateAsync({
      sortDaysSinceLastUpdate: 0,
      sortId: 0,
      sortThumbsUp: sortValue,
      sortThumbsDown: 0,
    });

    const sortType = 2 + sortValue;
    this.problemsData.setSortType(sortType);
  }

  async _toggleStatus() {
    await this.setStateAsync({
      collapseThumbsUp: false,
      collapseCategories: false,
      collapseStatus: !this.state.collapseStatus
    });
  }

  async _toggleThumbsUp() {
    await this.setStateAsync({
      collapseCategories: false,
      collapseStatus: false,
      collapseThumbsUp: !this.state.collapseThumbsUp
    });
  }

  _returnStyleMainPage() {
    if (this.state.collapseCategories || this.state.collapseStatus || this.state.collapseThumbsUp) {
      return ({ height: '100%', paddingTop: '184px', marginTop: '-184px' });
    }
    else {
      return ({ height: '100%', paddingTop: '64px', marginTop: '-64px' });
    }
  }

  _renderDropDownIcon(collapse) {
    if (collapse) {
      return faCaretUp;
    }
    else {
      return faCaretDown;
    }
  }

  _renderSortIcon(sortType) {
    if (sortType === 0 || sortType === 1) {
      return < FontAwesomeIcon icon={faSortAmountUp} />;
    }
    else if (sortType === 2) {
      return < FontAwesomeIcon icon={faSortAmountDown} />;
    }
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <React.Fragment>
          <FormControl>
            <InputLabel>{i18n.t('carecityCities')}</InputLabel>
            <Select
              value={this.state.cityId}
              onChange={async (_event) => await this._fetchCity(_event.target.value)}
              style={{ width: '300px' }}
            >
              <MenuItem key={0} value={0}>{i18n.t('allMyCities')}</MenuItem>;
              {
                this.state.userCities.map(function(city) {
                  return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                })
              }
            </Select>
          </FormControl>
        </React.Fragment>
      );
    }
  }

  render() {
    const accessLevel = User.getInstance().getUser().access_level;
    if (accessLevel === 'publisher_public_works' || accessLevel === 'admin' || accessLevel === 'super_admin') {
      const { categories, status, sortDaysSinceLastUpdate, sortId, sortThumbsUp, sortThumbsDown } = this.state;
      return (
        <Container className='BrowseProblems' fluid={true} style={{ height: '100%', paddingTop: '76px', marginTop: '-76px' }}>
          <Row style={{ height: '64px' }}>
            <Col md='10' sm='10' xs='10'>
              <Container style={{ marginLeft: '0' }}>
                <Row style={{ paddingTop: '0', paddingBottom: '0', height: '64px' }}>
                  <Col md='2' sm='2' xs='2' className='Filters' style={{ paddingLeft: '0' }}>
                    <Button
                      active={this.state.collapseStatus}
                      onClick={this._toggleStatus}
                      style={{ width: '120px' }}
                    >
                      {i18n.t('statuses')} <FontAwesomeIcon icon={this._renderDropDownIcon(this.state.collapseStatus)} />
                    </Button>
                  </Col>
                  <Col md='3' sm='3' xs='3' className='Filters'>
                    <Button
                      active={this.state.collapseCategories}
                      onClick={this._toggleCategories}
                      style={{ width: '120px' }}
                    >
                      {i18n.t('categories')} <FontAwesomeIcon icon={this._renderDropDownIcon(this.state.collapseCategories)} />
                    </Button>
                  </Col>
                  <Col md='3' sm='3' xs='3' className='Filters'>
                    <Button
                      active={this.state.collapseThumbsUp}
                      onClick={this._toggleThumbsUp}
                    >
                      <FontAwesomeIcon icon={faThumbsUp} /> <FontAwesomeIcon icon={this._renderDropDownIcon(this.state.collapseThumbsUp)} />
                    </Button>
                  </Col>
                  <Col md='1' sm='1' xs='1' className='Filters' style={{ paddingLeft: '10px' }}>
                    <div className='vl'></div>
                    <Button
                      active={this._isSortActive(sortId)}
                      onClick={this._toggleSortId}
                      style={{ width: '70px' }}
                      title={i18n.t('creationDate')}
                    >
                      {this._renderSortIcon(sortId)} <FontAwesomeIcon icon={faCalendar} />
                    </Button>
                  </Col>
                  <Col md='1' sm='1' xs='1' className='Filters'>
                    <Button
                      active={this._isSortActive(sortDaysSinceLastUpdate)}
                      onClick={this._toggleSortDaysSinceLastUpdate}
                      style={{ width: '70px' }}
                      title={i18n.t('numberOfDaysSinceLastUpdate')}
                    >
                      {this._renderSortIcon(sortDaysSinceLastUpdate)} <FontAwesomeIcon icon={faStopwatch} />
                    </Button>
                  </Col>
                  <Col md='1' sm='1' xs='1' className='Filters'>
                    <Button
                      active={this._isSortActive(sortThumbsDown)}
                      onClick={this._toggleSortThumbsDown}
                      style={{ width: '70px' }}
                    >
                      {this._renderSortIcon(sortThumbsDown)} <FontAwesomeIcon icon={faThumbsDown} />
                    </Button>
                  </Col>
                  <Col md='1' sm='1' xs='1' className='Filters'>
                    <Button
                      active={this._isSortActive(sortThumbsUp)}
                      onClick={this._toggleSortThumbsUp}
                      style={{ width: '70px' }}
                    >
                      {this._renderSortIcon(sortThumbsUp)} <FontAwesomeIcon icon={faThumbsUp} />
                    </Button>
                  </Col>
                  <Col md='1' sm='1' xs='1' className='citiesSelectorContainer'>
                    {this._renderCitiesSelect()}
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md='2' sm='2' xs='2' className='buttonGroup'>
              <ButtonGroup size='sm'>
                <Button
                  className='heatMapButton'
                  active={this.state.cluster}
                  onClick={this._toggleCluster}
                >
                  {i18n.t('markers')}
                </Button>
                <Button
                  className='heatMapButton'
                  active={!this.state.cluster}
                  onClick={this._toggleHeat}
                >
                  {i18n.t('heatMap')}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Collapse isOpen={this.state.collapseStatus}>
            <Card>
              <CardBody>
                <Container>
                  <Row className='cardRow' style={{ justifyContent: 'center' }}>
                    <Col md='12' style={{ flexBasis: '700px' }}>
                      <Multiselect
                        label={i18n.t('selectStatuses')}
                        isMulti={true}
                        onChange={this._handleSelectStatusChange}
                        options={Object.keys(ProblemStatus).map((status) => {
                          return { label: ProblemStatus[status].name, value: status };
                        })}
                        placeholder={i18n.t('selectStatuses')}
                        value={status}
                        className='multiSelect'
                      />
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.collapseCategories}>
            <Card>
              <CardBody>
                <Container>
                  <Row className='cardRow' style={{ justifyContent: 'center' }}>
                    <Col md='12' style={{ flexBasis: '700px' }}>
                      <Multiselect
                        label={i18n.t('selectCategories')}
                        isMulti={true}
                        onChange={this._handleSelectCategorieChange}
                        options={Object.keys(ProblemCategories).map((category) => {
                          return { label: ProblemCategories[category].name, value: category };
                        })}
                        placeholder={i18n.t('selectCategories')}
                        value={categories}
                        className='multiSelect'
                      />
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.collapseThumbsUp}>
            <Card>
              <CardBody>
                <Container className='cardRow'>
                  <Row style={{ height: '20px', paddingTop: '10px', justifyContent: 'center' }}>
                    <Col md='12' style={{ flexBasis: '500px' }}>
                      <p style={{ textAlign: 'center' }}>{i18n.t('selectTheMinimalApproval')}</p>
                    </Col>
                  </Row>
                  <Row style={{ height: '60px', justifyContent: 'center' }}>
                    <Col md='6' sm='10' >
                      <Slider
                        step={1}
                        valueLabelDisplay={'auto'}
                        onChange={this._handleSlider}
                        min={0}
                        max={this.state.maxThumbsUp}
                        value={0}
                      />
                    </Col>
                    <Col md='6' sm='2' style={{ paddingLeft: '0', flexBasis: '80px', color: '#999', paddingTop: '12px' }}>
                      <Container>
                        <Row style={{ height: '100%', paddingTop: '0', marginTop: '-6px' }}>
                          <Col md='6' xs='2' style={{ padding: '0', paddingRight: '1px', flexBasis: '20px' }}>
                            <span>{this.state.slider}</span>
                          </Col>
                          <Col md='6' style={{ marginTop: '-2px', padding: '0' }}>
                            <FontAwesomeIcon icon={faThumbsUp} />
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </Collapse>
          <Row style={this._returnStyleMainPage()}>
            <Col md='5' xl='4' className='List'>
              <ProblemList reminderLevels={this.state.reminderLevels} />
            </Col>
            <Col md='7' xl='8' className='Map'>
              <ProblemMap />
            </Col>
          </Row>
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default BrowseProblems;

import HttpMessage, { ROOT_URL } from './HttpMessage';

import Cities from '../data/Cities';
import { getHttpCitiesFilter } from '../utils/Filters';
import User from '../data/User';

class GroupsService {

  static async fetchGroups(cityIds) {
    const citiesFilters = getHttpCitiesFilter(cityIds);

    const message = {
      route: `${ROOT_URL}/users_app_groups?token=${User.getInstance().getUser().token}&${citiesFilters}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const groupsList = [];
    if (data.status === 'success') {
      await Promise.all(data.groups.map(async group => {
        const obj = {};
        obj.id = group.id;
        obj.name = group.name;
        obj.users = group.users;
        obj.cityIds = group.city_ids;
        obj.citiesName = await Cities.getInstance().getCitiesName(group.city_ids);
        groupsList.push(obj);
      }));
    }

    return groupsList;
  }

  static async addGroup(group) {
    const message = {
      route: `${ROOT_URL}/users_app_groups?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(group),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }

  static async editGroup(group) {
    const message = {
      route: `${ROOT_URL}/users_app_groups/${group.id}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(group),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }

  static async editGroupUsers(group) {
    const message = {
      route: `${ROOT_URL}/users_app_groups/${group.id}/users?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(group),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }

  static async deleteGroup(id) {
    const message = {
      route: `${ROOT_URL}/users_app_groups/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }
}

export default GroupsService;

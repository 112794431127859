import React from 'react';
import AsyncComponent from '../AsyncComponent';
import { Container } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'antd';

import Problem from '../Problem';
import Problems from '../../data/Problems';
import './styles.scss';
import i18n from '../../i18n';

class ProblemsContainer extends AsyncComponent {
  constructor(props) {
    super(props);

    this.problemsData = Problems.getInstance();
    this.problemsData.addListener(this);
  }

  noDataWarning(length) {
    if (length === 0) {
      return <h4 className='errorMessage'>{i18n.t('noReport')}</h4>;
    }
  }

  render() {
    this.problems = this.problemsData.getProblemsOnMap();
    return (
      <div className='Problems' style={{ height: '100%' }}>
        <Container style={{ height: '100%' }} className='listContainer'>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            useWindow={false}
            bordered={1}
            loadMore={() => { }}
          >
            <List
              dataSource={this.problems}
              renderItem={item => (
                <Problem
                  className='itemList'
                  {...item}
                  reminderLevels={this.props.reminderLevels}
                />
              )}
            >
              {this.noDataWarning(this.problems.length)}
            </List>
          </InfiniteScroll>
        </Container>
      </div>
    );
  }
}

export default ProblemsContainer;

import React from 'react';
import AsyncComponent from '../components/AsyncComponent';
import './styles.scss';

import User from '../data/User';
import i18n from '../i18n';

class Footer extends AsyncComponent {
  constructor(props) {
    super(props);
    this.user = User.getInstance().getUser();
    this.state = {};
  }

  render() {
    return (
      <footer className='Footer'>
        <p>&copy; {new Date().getFullYear()} Technologies Carecity Inc.</p>
        <p>
          <a href="https://carecity.io/politique-de-confidentialite/">{i18n.t('privacyPolicy')}</a>
        </p>
      </footer>
    );
  }
}

export default Footer;
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en';
import fr from './locales/fr';

i18n
  .use(LanguageDetector)
  .init({
    resources: { en, fr },
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false
    }
  });

i18n.getLanguageEnabled = () => {
  return !i18n.languages || i18n.languages.length === 0 || i18n.languages[0].includes('fr') ? 'fr' : 'en';
};

export default i18n;
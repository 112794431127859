import Quicksort from 'optimized-quicksort';

export const isArrayContainedInOtherArray = (array, otherArray) => {
  if (!array) {
    return true;
  }

  if (!otherArray) {
    return false;
  }

  for (let i = 0; i < array.length; i++) {
    if (!otherArray.find(otherElement => otherElement === array[i] || (otherElement !== null && array[i] !== null && otherElement.toString() === array[i].toString()))) {
      return false;
    }
  }

  return true;
};

export const orderBy = (array, columnToSort, sortOrder) => {
  return Quicksort.sort(array, sortOrder === 'asc' ?
    (a, b) => a[columnToSort].toString().localeCompare(b[columnToSort].toString()) :
    (a, b) => b[columnToSort].toString().localeCompare(a[columnToSort].toString()));
};
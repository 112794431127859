import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { Container, Form, Row, Col } from 'reactstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Multiselect from 'react-select';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';

import AccessForbidden from '../../components/AccessForbidden';
import LoadingPage from '../../components/LoadingPage';
import InformationsService from '../../services/InformationsService';
import User from '../../data/User';
import { SupportedImageFileTypes, SupportedImageFileTypesString, NoImageCitySrc } from '../../utils/ImageHelper';
import './styles.scss';
import i18n from '../../i18n';

class Informations extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEls: {},
      cityToFetch: 0,
      content: '',
      contentEdited: '',
      editing: false,
      errorMessage: '',
      errorTitle: '',
      imagePreviewUrl: '',
      imagePreviewUrlEdited: '',
      imageFile: null,
      imageFileEdited: null,
      informations: [],
      informationToDelete: null,
      informationEditingEnabled: false,
      loading: false,
      moreInformationUrl: '',
      moreInformationUrlEdited: '',
      open: false,
      openError: false,
      priority: '',
      priorityEdited: '',
      title: '',
      titleEdited: '',
      userCities: [],
      userCitiesSelected: [],
    };

    this._addInformation = this._addInformation.bind(this);
    this._deleteInformation = this._deleteInformation.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleSelectMultiCitiesChange = this._handleSelectMultiCitiesChange.bind(this);
    this._modifyInformation = this._modifyInformation.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchInformations();
  }

  async _fetchInformations() {
    const citiesToFetchUsersFrom = this.state.cityToFetch === 0 ? this.state.userCityIds : [this.state.cityToFetch];
    const informations = await InformationsService.fetchInformations(citiesToFetchUsersFrom);
    informations.sort((a, b) => {
      let x = a.priority, y = b.priority;
      if (x === y) {
        x = b.id;
        y = a.id;
      }

      return x > y ? 1
        : (x < y ? -1 : 0);
    });
    await this.setStateAsync({ informations });
  }

  async _filterInformations(cityToFetch) {
    await this.setStateAsync({ cityToFetch });
    await this._fetchInformations();
  }

  async _getUserCities() {
    const userCities = (await User.getInstance().getUserCities()).filter(city => city.cityId !== 'null');
    const userCityIds = userCities.map(city => city.cityId);
    await this.setStateAsync({ userCities, userCityIds });

    if (userCities.length === 1) {
      await this.setStateAsync({ userCitiesSelected: [{ value: userCities[0].cityId, label: userCities[0].name }], cityToFetch: userCities[0].cityId });
    }
  }

  async _handleOpenError(errorTitle, errorMessage) {
    await this.setStateAsync({ openError: true, errorTitle, errorMessage });
  }

  async _handleOpenDeleteModal(informationId) {
    await this.setStateAsync({ informationToDelete: informationId, open: true });
  }

  async _handleClose() {
    await this.setStateAsync({ open: false });
    await this.setStateAsync({ anchorEls: {}, openError: false });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleSelectMultiCitiesChange(cities) {
    await this.setStateAsync({ userCitiesSelected: cities ? cities : [] });
  }

  async _setAnchorEl(informationId, anchorEl) {
    const anchorEls = this.state.anchorEls;
    anchorEls[informationId] = anchorEl;
    await this.setStateAsync({ anchorEls });
  }

  _notifySuccess(message) {
    toast.success(message);
  }

  _notifyError(message) {
    toast.error(message);
  }

  async _cancelEditing(information) {
    await this._setAnchorEl(information.id, null);
    information.informationEditingEnabled = false;
    await this.setStateAsync({
      contentEdited: '',
      editing: false,
      alerts: this.state.informations,
      moreInformationUrlEdited: '',
      priorityEdited: '',
      titleEdited: '',
    });
  }

  async _toggleEditing(information) {
    if (!this.state.editing) {
      await this._setAnchorEl(information.id, null);
      information.informationEditingEnabled = true;
      await this.setStateAsync({
        contentEdited: information.content,
        editing: true,
        alerts: this.state.informations,
        imagePreviewUrlEdited: information.pictureUrl !== null ? information.pictureUrl : '',
        moreInformationUrlEdited: information.moreInformationUrl ? information.moreInformationUrl : '',
        priorityEdited: information.priority.toString(),
        titleEdited: information.title,
        cityId: information.cityId,
      });
    }
    else {
      this._notifyError(i18n.t('youAreAlreadyEditingAnotherInformation'));
    }
  }

  async _addInformation() {
    if (!this.state.loading) {
      if (this.state.title === '') {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAddATitle'));
      }
      else if (this.state.content === '') {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAddAContent'));
      }
      else if (this.state.moreInformationUrl !== '' && !validator.isURL(this.state.moreInformationUrl, { protocols: ['http', 'https'], require_protocol: true })) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('yourUrlLinkIsInvalid'));
      }
      else if (this.state.priority !== '' && isNaN(this.state.priority)) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('thePriorityMustBeANumber'));
      }
      else if (this.state.userCitiesSelected.length === 0) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustChooseAtLeastOneCity'));
      }
      else if (this.state.editing) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('pleaseCompleteOrCancelTheChangesBeforeCreatingANewOne'));
      }
      else {
        await this.setStateAsync({ loading: true });

        let success = false;
        await Promise.all(this.state.userCitiesSelected.map(async city => {
          const information = {
            title: this.state.title,
            content: this.state.content,
            city_id: city.value,
            more_information_url: this.state.moreInformationUrl,
            picture: this.state.imageFile !== null && this.state.imageFile !== 'null' && this.state.imageFile !== '' ? true : false,
            priority: this.state.priority,
          };

          success |= await InformationsService.addInformation(information, this.state.imageFile);
        }));

        if (success) {
          await this._fetchInformations();
          await this.setStateAsync({
            content: '',
            imagePreviewUrl: '',
            imageFile: null,
            moreInformationUrl: '',
            priority: '',
            title: '',
            userCitiesSelected: [],
          });

          if (this.state.userCities.length === 1) {
            await this.setStateAsync({ userCitiesSelected: [{ value: this.state.userCities[0].cityId, label: this.state.userCities[0].name }] });
          }

          this._notifySuccess(i18n.t('successfullyCreated'));
        }
        else {
          this._notifyError(i18n.t('errorDuringCreation'));
        }

        await this.setStateAsync({ loading: false });
      }
    }
  }

  async _modifyInformation(informationEdited) {
    if (!this.state.loading) {
      if (this.state.titleEdited === '') {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustAddATitle'));
      }
      else if (this.state.contentEdited === '') {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustAddAContent'));
      }
      else if (this.state.moreInformationUrlEdited !== '' && !validator.isURL(this.state.moreInformationUrlEdited, { protocols: ['http', 'https'], require_protocol: true })) {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('yourUrlLinkIsInvalid'));
      }
      else if (this.state.priorityEdited === '' || isNaN(this.state.priorityEdited)) {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('thePriorityMustBeANumber'));
      }
      else {
        await this.setStateAsync({ loading: true });

        const information = {
          id: informationEdited.id,
          title: this.state.titleEdited,
          content: this.state.contentEdited,
          city_id: this.state.cityId,
          more_information_url: this.state.moreInformationUrlEdited ? this.state.moreInformationUrlEdited : null,
          picture_url: informationEdited.pictureUrl !== null && informationEdited.pictureUrl !== 'null' && informationEdited.pictureUrl !== '' ? informationEdited.pictureUrl : null,
          priority: this.state.priorityEdited,
        };


        if (this.state.imageFileEdited !== null) {
          information.picture = this.state.imageFileEdited !== '' ? true : false;
        }

        const success = await InformationsService.editInformation(information, this.state.imageFileEdited);

        if (success) {
          this._notifySuccess(i18n.t('successfullyModified'));
          await this._fetchInformations();

          await this.setStateAsync({
            contentEdited: '',
            editing: false,
            imageFileEdited: null,
            moreInformationUrlEdited: '',
            priorityEdited: '',
            titleEdited: '',
          });
        }
        else {
          this._notifyError(i18n.t('errorDuringModification'));
        }

        await this.setStateAsync({ loading: false });
      }
    }
  }

  async _deleteInformation() {
    const success = await InformationsService.deleteInformation(this.state.informationToDelete);
    if (success) {
      await this.setStateAsync({ open: false });
      this._notifySuccess(i18n.t('successfullyRemoved'));
      await this.setStateAsync({ anchorEls: {}, editing: false, informationToDelete: null });
      await this._fetchInformations();
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _removePicture() {
    this.informationEditedImageInput.value = '';
    await this.setStateAsync({ imagePreviewUrlEdited: '', imageFileEdited: '' });
  }

  _renderCardImage(image, editing) {
    if (editing) {
      return (
        <div>
          <img
            src={this.state.imagePreviewUrlEdited === '' ? NoImageCitySrc : this.state.imagePreviewUrlEdited}
            className='informationImage'
            alt='Information'
          />
          <Row className='editInformationImageButtonsContainer'>
            <label htmlFor='contained-button-file2'>
              <Button variant='outlined' component='span'
                className='editInformationModifyImageButton'
              >
                {this.state.imageFileEdited ? i18n.t('editTheImage') : i18n.t('addAnImage')}
              </Button>
            </label>
            {(this.state.imagePreviewUrlEdited !== '') &&
              <div className='deletePictureButtonContainer'>
                <IconButton
                  aria-label='Delete'
                  onClick={async () => await this._removePicture()}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            }
          </Row>
          <div className={(this.state.imagePreviewUrlEdited !== '') ? 'editPictureButtonContainerWithDelete' : 'editPictureButtonContainer'}>
            <input
              accept={SupportedImageFileTypesString}
              id='contained-button-file2'
              type='file'
              className='imageInput'
              ref={informationEditedImageInput => this.informationEditedImageInput = informationEditedImageInput}
              onChange={
                async (e) => {
                  e.preventDefault();
                  const files = e.target.files;
                  if (files.length !== 0) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                      if (SupportedImageFileTypes.includes(files[0].type.replace('image/', ''))) {
                        await this.setStateAsync({ imagePreviewUrlEdited: reader.result, imageFileEdited: files[0] });
                      }
                      else {
                        this._notifyError(i18n.t('theSelectedFileIsNotInASupportedFormat'));
                      }
                    };
                    reader.readAsDataURL(files[0]);
                  }
                }
              }
            />
          </div>
        </div>
      );
    }
    else {
      return (
        <img
          src={image !== null ? image : NoImageCitySrc}
          className='informationImage'
          alt='Information'
        />
      );
    }
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <React.Fragment>
          <Row className='citiesSelectorContainer'>
            <Col sm='12'>
              <Multiselect
                label={i18n.t('targetedCities')}
                isMulti={true}
                onChange={this._handleSelectMultiCitiesChange}
                options={this.state.userCities.map((city) => {
                  return { label: city.name, value: city.cityId };
                })}
                placeholder={i18n.t('targetedCities')}
                value={this.state.userCitiesSelected}
                className='multiSelect'
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  _renderImagePreview() {
    if (this.state.imagePreviewUrl !== '') {
      return (
        <img
          src={this.state.imagePreviewUrl}
          className='imagePreview'
          alt='Information'
        />
      );
    }
  }

  _renderInformationsCard() {
    if (this.state.informations.length === 0) {
      return (
        <Row className='noData'>
          <h4>{i18n.t('noInformation')}</h4>
        </Row>
      );
    }
    else {
      return (
        this.state.informations && this.state.informations.map((information) =>
          <Card className='card' key={information.id}>
            <Row>
              <Col sm='12'>
                <IconButton
                  className='actions'
                  aria-label='More'
                  aria-owns={this.state.open ? 'long-menu' : undefined}
                  aria-haspopup='true'
                  onClick={async (event) => await this._setAnchorEl(information.id, event.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={information.id}
                  anchorEl={this.state.anchorEls[information.id]}
                  open={Boolean(this.state.anchorEls[information.id])}
                  onClose={async () => await this._setAnchorEl(information.id, null)}
                >
                  <MenuItem onClick={async () => await this._toggleEditing(information)}>{i18n.t('edit')}</MenuItem>
                  <MenuItem onClick={async () => await this._handleOpenDeleteModal(information.id)}>{i18n.t('remove')}</MenuItem>

                </Menu>
              </Col>
            </Row>
            <Row className='informationsContainer'>
              <Col xs={12} sm={12} md={12} lg={4}>
                {this._renderCardImage(information.pictureUrl, information.informationEditingEnabled)}
              </Col>
              <Col xs={12} sm={12} md={12} lg={8} className='informationFormContainer'>
                {!information.informationEditingEnabled &&
                  <React.Fragment>
                    <Row className='fieldContainer'>
                      <Col sm='12'>
                        <p className='cardTitle'><span className='title'>{i18n.t('title')}</span>{information.title}</p>
                      </Col>
                    </Row>
                    {this.state.userCityIds.length > 1 &&
                      <Row className='fieldContainer'>
                        <Col sm='12'>
                          <p className='cardTitle'><span className='title'>{i18n.t('cities')}</span>{information.cityName}</p>
                        </Col>
                      </Row>
                    }
                    <Row className='fieldContainer'>
                      <Col sm='12'>
                        <p className='cardTitle'><span className='title'>{i18n.t('content')}</span>{information.content}</p>
                      </Col>
                    </Row>
                    <Row className='fieldContainer'>
                      <Col sm='12'>
                        <p className='cardTitle'><span className='title'>{i18n.t('priority')}</span>{information.priority}</p>
                      </Col>
                    </Row>
                    {information.moreInformationUrl &&
                      <Row className='fieldContainer'>
                        <Col sm='12'>
                          <p className='cardTitle'><span className='title'>{i18n.t('urlLink')}</span></p>
                          <a href={information.moreInformationUrl} >{information.moreInformationUrl.length > 75 ? information.moreInformationUrl.slice(0, 75) + '...' : information.moreInformationUrl}</a>
                        </Col>
                      </Row>
                    }
                  </React.Fragment>
                }
                {information.informationEditingEnabled &&
                  <React.Fragment>
                    <Row className='fieldContainer'>
                      <Col sm='12'>
                        <TextField
                          name='titleEdited'
                          placeholder={i18n.t('addTitle')}
                          fullWidth={true}
                          label={i18n.t('title')}
                          helperText={i18n.t('thisFieldIsRequired')}
                          maxLength='255'
                          multiline={true}
                          onChange={this._handleInputChange}
                          value={this.state.titleEdited}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                        <br />
                      </Col>
                    </Row>
                    <Row className='fieldContainer'>
                      <Col sm='12'>
                        <TextField
                          name='contentEdited'
                          placeholder={i18n.t('addContent')}
                          fullWidth={true}
                          label={i18n.t('content')}
                          helperText={i18n.t('thisFieldIsRequired')}
                          multiline={true}
                          onChange={this._handleInputChange}
                          value={this.state.contentEdited}
                        />
                        <br />
                      </Col>
                    </Row>
                    <Row className='fieldContainer'>
                      <Col sm='12'>
                        <TextField
                          name='moreInformationUrlEdited'
                          placeholder={i18n.t('addUrlLink')}
                          fullWidth={true}
                          label={i18n.t('urlLink')}
                          helperText={i18n.t('thisFieldIsOptional')}
                          maxLength='255'
                          onChange={this._handleInputChange}
                          value={this.state.moreInformationUrlEdited}
                        />
                        <br />
                      </Col>
                    </Row>
                    <Row className='fieldContainer'>
                      <Col sm='12'>
                        <TextField
                          name='priorityEdited'
                          placeholder={i18n.t('addPriority')}
                          fullWidth={true}
                          label={i18n.t('priority')}
                          helperText={i18n.t('thisFieldIsRequired')}
                          onChange={this._handleInputChange}
                          value={this.state.priorityEdited}
                        />
                        <br />
                      </Col>
                    </Row>
                  </React.Fragment>
                }
              </Col>
            </Row>
            {information.informationEditingEnabled &&
              <Row className='editInformationButtonsContainer'>
                <Button
                  variant='outlined'
                  onClick={async () => await this._cancelEditing(information)}
                  className='editInformationCancelButton'
                >
                  {i18n.t('cancel')}
                </Button>
                <Button
                  variant='outlined'
                  onClick={async () => await this._modifyInformation(information)}
                  className='editInformationConfirmButton'
                >
                  {i18n.t('edit')}
                </Button>
              </Row>
            }
            <Dialog
              open={this.state.open}
              onClose={this._handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  {i18n.t('areYouSureYouWantToDeleteThis')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this._handleClose} color='primary'>
                  {i18n.t('cancel')}
                </Button>
                <Button onClick={this._deleteInformation} color='primary'>
                  {i18n.t('confirm')}
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        )
      );
    }
  }

  _renderInformationForm() {
    return (
      <Form>
        <TextField
          name='title'
          placeholder={i18n.t('addTitle')}
          fullWidth={true}
          label={i18n.t('title')}
          helperText={i18n.t('thisFieldIsRequired')}
          maxLength='255'
          multiline={true}
          onChange={this._handleInputChange}
          value={this.state.title}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        <br />
        <TextField
          name='content'
          id='f_message'
          placeholder={i18n.t('addContent')}
          fullWidth={true}
          label={i18n.t('content')}
          helperText={i18n.t('thisFieldIsRequired')}
          multiline={true}
          onChange={this._handleInputChange}
          value={this.state.content}
        />
        <br />
        <TextField
          name='moreInformationUrl'
          placeholder={i18n.t('addUrlLink')}
          fullWidth={true}
          label={i18n.t('urlLink')}
          helperText={i18n.t('thisFieldIsOptional')}
          maxLength='255'
          onChange={this._handleInputChange}
          value={this.state.moreInformationUrl}
        />
        <br />
        <TextField
          name='priority'
          placeholder={i18n.t('addPriority')}
          fullWidth={true}
          label={i18n.t('priority')}
          helperText={i18n.t('thisFieldIsOptional')}
          onChange={this._handleInputChange}
          value={this.state.priority}
        />
        <br />
        {this._renderCitiesSelect()}
        {this._renderImagePreview()}
        {
          this.state.imageFile && <IconButton
            aria-label='Delete'
            onClick={async () => {
              this.informationImageInput.value = '';
              await this.setStateAsync({ imagePreviewUrl: '', imageFile: null });
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
        <input
          accept={SupportedImageFileTypesString}
          className='imageInput'
          id='addImage'
          type='file'
          ref={informationImageInput => this.informationImageInput = informationImageInput}
          onChange={
            async (e) => {
              e.preventDefault();
              const files = e.target.files;
              if (files.length !== 0) {
                const reader = new FileReader();
                reader.onloadend = async () => {
                  if (SupportedImageFileTypes.includes(files[0].type.replace('image/', ''))) {
                    await this.setStateAsync({ imagePreviewUrl: reader.result, imageFile: files[0] });
                  }
                  else {
                    this._notifyError(i18n.t('theSelectedFileIsNotInASupportedFormat'));
                  }
                };
                reader.readAsDataURL(files[0]);
              }
            }
          }
        />
        <Row>
          <Col xs={12} sm={12} md={12} lg={3}>
            <label className='addImageLabel' htmlFor='addImage'>
              <Button
                variant='outlined'
                component='span'
                fullWidth={true}
                className='addImageButton'
              >
                {this.state.imageFile ? i18n.t('editTheImage') : i18n.t('addAnImage')}
              </Button>
            </label>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Button
              variant='contained'
              onClick={this._addInformation}
              fullWidth={true}
              className='sendInformationButton'
            >
              {i18n.t('send')}
            </Button>
          </Col>
        </Row>
        <Dialog
          open={this.state.openError}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{this.state.errorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {this.state.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    );
  }

  render() {
    const accessLevel = User.getInstance().getUser().access_level;
    if (accessLevel === 'publisher_communications' || accessLevel === 'admin' || accessLevel === 'super_admin') {
      return (
        <Container className='Informations'>
          {this.state.loading && <LoadingPage />}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('cityInformations')}</h2>
          </Row>
          {this._renderInformationForm()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('informationCurrentlyAvailableToCitizens')}</h2>
          </Row>
          {
            this.state.userCities.length > 1 &&
            <Row className='citiesSelectorContainer'>
              <Col sm='6'>
                <FormControl fullWidth={true}>
                  <InputLabel>{i18n.t('carecityCities')}</InputLabel>
                  <Select
                    value={this.state.cityToFetch}
                    onChange={async (_event) => await this._filterInformations(_event.target.value)}
                  >
                    <MenuItem key={0} value={0}>{i18n.t('allMyCities')} </MenuItem>;
                    {
                      this.state.userCities.map(function(city) {
                        return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                      })
                    }
                  </Select>
                </FormControl>
              </Col>
            </Row>
          }
          {this._renderInformationsCard()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Informations;

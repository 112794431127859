/*global google*/
import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@material-ui/core/Button';
import { Container, Row, Col } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import faThumbsUp from '@fortawesome/fontawesome-free-solid/faThumbsUp';
import faThumbsDown from '@fortawesome/fontawesome-free-solid/faThumbsDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import { GoogleMap, Marker } from '@react-google-maps/api';
import ImageLoader from 'react-imageloader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment-timezone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Select from '@material-ui/core/Select';
import './styles.scss';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast } from 'react-toastify';

import AccessForbidden from '../../components/AccessForbidden';
import { history } from '../../routes/history';
import { ProblemCategories, ProblemStatus } from '../../utils/Enums';
import { GoogleMapCustomStyle } from '../../utils/GoogleMapCustomStyle';
import Problems from '../../data/Problems';
import User from '../../data/User';
import { NoImageCardSrc } from '../../utils/ImageHelper';

import ProblemsService from '../../services/ProblemsService';
import LogsService from '../../services/LogsService';
import i18n from '../../i18n';

const spinner = require('../../images/loading_spinner.gif');

const MyMapWrapper = ({ problem }) => {
  const center = { lat: problem.latitude, lng: problem.longitude };
  return (
    <GoogleMap
      mapContainerClassName='map'
      center={center}
      zoom={15}
      version='weekly'
      options={{
        gestureHandling: 'greedy',
        streetViewControl: false,
        styles: GoogleMapCustomStyle.mapCustomStyles
      }}
    >
      <Marker
        position={center}
        key={-1}
        icon={{
          url: ProblemCategories[problem.category].marker,
          scaledSize: new google.maps.Size(37, 37)
        }}
        zIndex={1}
      />
    </GoogleMap>);
};

class SingleProblem extends AsyncComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEls: {},
      dialogOpened: false
    };

    this._closeDialog = this._closeDialog.bind(this);
    this._deleteProblem = this._deleteProblem.bind(this);
    this._handleCategoryChange = this._handleCategoryChange.bind(this);
    this._handleStatusChange = this._handleStatusChange.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._openDialog = this._openDialog.bind(this);
    this._renderPreloader = this._renderPreloader.bind(this);
    this._sendCityComment = this._sendCityComment.bind(this);
    this._signalCreator = this._signalCreator.bind(this);
    this._sendProblemInformations = this._sendProblemInformations.bind(this);
  }

  async componentDidMount() {
    await this._fetchProblem();
  }

  async _fetchProblem() {
    const response = await ProblemsService.fetchProblem(this.props.match.params.id);
    if (response && response.status === 'success') {
      const problemLogs = await this._fetchProblemLogs(response.problem.id);

      await this.setStateAsync({
        cityComment: response.problem.city_comment,
        problem: response.problem,
        problemLogs: problemLogs
      });
    }
    else {
      await this.setStateAsync({ showError: true });
    }
  }

  async _fetchProblemLogs(problemId) {
    let problemLogs = await LogsService.fetchProblemLogs(problemId);

    problemLogs = problemLogs.sort((a, b) =>
      a.createdAt > b.createdAt ? -1
        : (a.createdAt < b.createdAt ? 1 : 0));

    await this.setStateAsync({
      problemLogs: problemLogs
    });

    return problemLogs;
  }

  async _openDialog() {
    await this.setStateAsync({ dialogOpened: true });
  }

  async _closeDialog() {
    await this.setStateAsync({ dialogOpened: false });
  }

  async _deleteProblem() {
    const success = await ProblemsService.deleteProblem(this.state.problem.id);
    if (success) {
      const userCityIds = User.getInstance().getUser().city_ids;
      this._notify(i18n.t('successfullyRemoved'));
      await Problems.getInstance().fetchProblems(userCityIds);
      history.push('/');
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _handleCategoryChange(_event) {
    const success = await ProblemsService.modifyProblemCategory(this.state.problem.id, _event.target.value);

    if (success) {
      this.state.problem.category = _event.target.value;
      this.forceUpdateAsync();
      this._notify(i18n.t('successfullyModified'));
      await this._fetchProblemLogs(this.state.problem.id);
    }
    else {
      this._notifyError(i18n.t('errorDuringModification'));
    }
  }

  async _handleStatusChange(_event) {
    const success = await ProblemsService.modifyProblemStatus(this.state.problem.id, _event.target.value);

    if (success) {
      this.state.problem.status = _event.target.value;
      this.forceUpdateAsync();
      this._notify(i18n.t('successfullyModified'));
      await this.setStateAsync({ problem: this.state.problem });
      await this._fetchProblemLogs(this.state.problem.id);
    }
    else {
      this._notifyError(i18n.t('errorDuringModification'));
    }
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(message) {
    toast.error(message);
  }

  async _sendCityComment() {
    const problem = {
      id: this.state.problem.id,
      comment: this.state.cityComment
    };

    const success = await ProblemsService.modifyProblemCityComment(problem);
    success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
    if (success) {
      await this._fetchProblemLogs(this.state.problem.id);
    }
  }

  async _signalCreator() {
    const success = await ProblemsService.signalCreator(this.state.problem.id);
    success ? this._notify(i18n.t('successfullyReported')) : this._notifyError(i18n.t('errorDuringCreatorReport'));
  }

  async _sendProblemInformations() {
    const dateTitle = i18n.t('reportDate') + ':  ';
    const date = moment(this.state.problem.created_at).format('YYYY-MM-DD:HH-mm') + '\r\n\r\n';

    const categoryTitle = i18n.t('category') + ':  ';
    const category = ProblemCategories[this.state.problem.category].name + '\r\n\r\n';

    const descriptionTitle = i18n.t('descriptionOfTheCitizen') + ':  ';
    const description = this.state.problem.description + '\r\n\r\n';

    const longLatTitle = i18n.t('reportCoordinates') + ':  ';
    const longLat = this.state.problem.latitude + ', ' + this.state.problem.longitude + '\r\n\r\n';

    const imageTitle = i18n.t('imageUrlLink') + ':  ';
    const image = this.state.problem.picture_url + '\r\n\r\n';

    let cityCommentTitle = '';
    let cityComment = '';

    if (this.state.cityComment !== '') {
      cityCommentTitle = i18n.t('lastCommentFromTheMunicipality') + ':  ';
      cityComment = this.state.cityComment + '\r\n\r\n';
    }

    const carecityLinkTitle = i18n.t('reportUrlLink') + ':  ';
    const carecityLink = 'https://www.app.carecity.io/problem/' + this.state.problem.id + '\r\n\r\n';

    let mailToBody =
      dateTitle + date +
      categoryTitle + category +
      descriptionTitle + description +
      longLatTitle + longLat +
      imageTitle + image +
      cityCommentTitle + cityComment +
      carecityLinkTitle + carecityLink;

    const subject = i18n.t('informationOnTheReport') + ' #' + this.state.problem.id;

    mailToBody = encodeURIComponent(mailToBody);
    window.location.href = 'mailto:?subject=' + subject + '&body=' + mailToBody;
  }

  _renderMap() {
    return (
      <MyMapWrapper
        googleMapURL={'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_KEY + '&v=3.exp&libraries=geometry,places,visualization,drawing'}
        loadingElement={<div className='fullHeight' />}
        containerElement={<div className='fullHeight' />}
        mapElement={<div className='fullHeight' />}
        problem={this.state.problem}
      />);
  }

  _renderCityProblemComment() {
    if (User.getInstance().getUser().access_level === 'admin' || User.getInstance().getUser().access_level === 'super_admin') {
      return (
        <Row className='commentRow'>
          <Col md='12'>
            <TextField
              name='cityComment'
              placeholder={i18n.t('addComment')}
              fullWidth={true}
              label={i18n.t('cityComment')}
              helperText={i18n.t('thisFieldIsOptional')}
              multiline={true}
              onChange={this._handleInputChange}
              value={this.state.cityComment}
            />
          </Col>
          <Col md='2'>
            <Button
              variant='contained'
              onClick={this._sendCityComment}
              className='cityCommentButton'
            >
              {i18n.t('edit')}
            </Button>
          </Col>
        </Row>
      );
    }
  }

  _renderProblemHistoryHeader() {
    if (User.getInstance().getUser().access_level === 'admin' || User.getInstance().getUser().access_level === 'super_admin') {
      return (
        <React.Fragment>
          <Row>
            <h2>{i18n.t('historyOfChanges')}</h2>
          </Row>
          <Row className='problemHistoryHeader'>
            <Col sm='3'>
              <h5>{'Date'}</h5>
            </Col>
            <Col sm='4'>
              <h5>{i18n.t('change')}</h5>
            </Col>
            <Col sm='3'>
              <h5>{i18n.t('changeAuthor')}</h5>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }

  _renderProblemHistory() {
    if (User.getInstance().getUser().access_level === 'admin' || User.getInstance().getUser().access_level === 'super_admin') {
      if (this.state.problemLogs.length === 0) {
        return (
          <Row className='noData'>
            <h4>{i18n.t('noHistory')}</h4>
          </Row>
        );
      }
      else {
        return (
          this.state.problemLogs.map(log =>
            <Row key={log.id}>
              <Col md='3'>
                <p>{log.createdAt}</p>
              </Col>
              <Col md='4'>
                <p>{log.content}</p>
              </Col>
              <Col md='3'>
                <p>{log.employee}</p>
              </Col>
            </Row>
          )
        );
      }
    }
  }

  async _setAnchorEl(problemId, anchorEl) {
    this.state.anchorEls[problemId] = anchorEl;
    await this.forceUpdateAsync();
  }

  _renderPreloader() {
    return <img
      src={spinner}
      alt={i18n.t('loading')}
    />;
  }

  render() {
    const accessLevel = User.getInstance().getUser().access_level;
    if (accessLevel === 'publisher_public_works' || accessLevel === 'admin' || accessLevel === 'super_admin') {
      return (
        <div>
          {
            this.state.showError &&
            <div>
              <h1>{i18n.t('404Title')}</h1>
              <p>{i18n.t('404Message')}</p>
            </div>
          }
          {this.state.problem &&
            <Container className='SingleProblem'>
              <Row>
                <Col sm='12'>
                  <IconButton
                    className='menuIcon'
                    aria-label='More'
                    aria-owns={this.state.open ? 'long-menu' : undefined}
                    aria-haspopup='true'
                    onClick={async (event) => await this._setAnchorEl(1, event.currentTarget)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id='simple-menu'
                    anchorEl={this.state.anchorEls[1]}
                    open={Boolean(this.state.anchorEls[1])}
                    onClose={async () => await this._setAnchorEl(1, null)}
                  >
                    <MenuItem onClick={async () => await this._sendProblemInformations()}>{i18n.t('transfer')}</MenuItem>
                    <MenuItem onClick={async () => await this._openDialog()}>{i18n.t('remove')}</MenuItem>
                  </Menu>
                </Col>
              </Row>
              <Row className='title'>
                <Col sm='10'>
                  <h3>{i18n.t('report')}{' #' + this.state.problem.id}</h3>
                </Col>
              </Row>
              <Row className='marginBottom'>
                <Col sm='6'>
                  <FormControl fullWidth={true}>
                    <InputLabel>{i18n.t('category')}</InputLabel>
                    <Select
                      value={this.state.problem.category}
                      onChange={this._handleCategoryChange}
                    >
                      {
                        Object.keys(ProblemCategories).map((category) => {
                          return <MenuItem key={category} value={category}>{ProblemCategories[category].name}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                </Col>
                <Col sm='6'>
                  <FormControl fullWidth={true}>
                    <InputLabel>{i18n.t('status')}</InputLabel>
                    <Select
                      value={this.state.problem.status}
                      onChange={this._handleStatusChange}
                    >
                      {
                        Object.keys(ProblemStatus).map((status) => {
                          return <MenuItem key={status} value={status}>{ProblemStatus[status].name}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row className='singleProblemContainer row-eq-height'>
                <Col className='problemDescription' sm='8'>
                  <Row>
                    <Col sm='12'>
                      <p className='descriptionTitle'>{i18n.t('reportDate')}: <span className='descriptionContent'>{moment(this.state.problem.created_at).format('YYYY-MM-DD')}</span></p>
                    </Col>
                  </Row>
                  {this.state.problem.description &&
                    <Row>
                      <Col sm='12'>
                        <p className='descriptionTitle'>{i18n.t('descriptionOfTheCitizen')}: <span className='descriptionContent'>{this.state.problem.description}</span></p>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col sm='12'>
                      <p className='descriptionTitle'>{i18n.t('reportCoordinates')}: <span className='descriptionContent'>{this.state.problem.latitude}, {this.state.problem.longitude}</span></p>
                    </Col>
                  </Row>
                  {this.state.problem.created_by &&
                    <Row>
                      <Col sm='12'>
                        <p className='descriptionTitle'>{i18n.t('reportedBy')}: <span className='descriptionContent'>{this.state.problem.created_by}</span></p>
                      </Col>
                    </Row>
                  }
                  {this.state.problem.user_contact_info && this.state.problem.user_contact_info.contact_email &&
                    <Row>
                      <Col sm='12'>
                        <p className='descriptionTitle'>{i18n.t('citizenEmail')}: <span className='descriptionContent'>{this.state.problem.user_contact_info.contact_email}</span></p>
                      </Col>
                    </Row>
                  }
                  {this.state.problem.user_contact_info && this.state.problem.user_contact_info.contact_sms &&
                    <Row>
                      <Col sm='12'>
                        <p className='descriptionTitle'>{i18n.t('citizenSmsNumber')}: <span className='descriptionContent'>{this.state.problem.user_contact_info.contact_sms}</span></p>
                      </Col>
                    </Row>
                  }
                  {this.state.problem.user_contact_info && this.state.problem.user_contact_info.contact_call &&
                    <Row>
                      <Col sm='12'>
                        <p className='descriptionTitle'>{i18n.t('citizenCallNumber')}: <span className='descriptionContent'>{this.state.problem.user_contact_info.contact_call}</span></p>
                      </Col>
                    </Row>
                  }
                </Col>
                <Col sm='4'>
                  <Row>
                    <Col sm='12'>
                      <ImageLoader
                        src={this.state.problem.picture_url === '' ? NoImageCardSrc : this.state.problem.picture_url}
                        preloader={this._renderPreloader}
                        className='fullHeight'>
                        <div src={NoImageCardSrc}></div>
                      </ImageLoader>
                    </Col>
                  </Row>
                  <Row className='thumbsUpDownContainer'>
                    <Col md='3' className='thumbsStats'>
                      <span>{this.state.problem.thumbs_up_count ? this.state.problem.thumbs_up_count : 0}</span>
                    </Col>
                    <Col md='3' className='thumbsIcon'>
                      <FontAwesomeIcon icon={faThumbsUp} />
                    </Col>
                    <Col md='3' className='thumbsStats'>
                      <span>{this.state.problem.thumbs_down_count ? this.state.problem.thumbs_down_count : 0}</span>
                    </Col>
                    <Col md='3' className='thumbsIcon'>
                      <FontAwesomeIcon icon={faThumbsDown} />
                    </Col>
                  </Row>
                </Col>
                <Col sm='12'>
                  <Row>
                    <Col sm='12'>
                      {this._renderCityProblemComment()}
                    </Col>
                  </Row>
                </Col>
                <Col sm='12'>
                  <Row className='mapContainer'>
                    <Col sm='12'>
                      {this._renderMap()}
                    </Col>
                  </Row>
                </Col>
                {
                  (User.getInstance().getUser().access_level === 'admin' || User.getInstance().getUser().access_level === 'super_admin') &&
                  <Col sm='12' className='reportCreatorButtonContainer'>
                    <Button
                      variant='outlined'
                      onClick={this._signalCreator}
                      className='reportCreatorButton'
                    >
                      {i18n.t('reportCreatorToCarecity')}
                    </Button>
                  </Col>
                }
              </Row>
              {this._renderProblemHistoryHeader()}
              {this._renderProblemHistory()}
              <Dialog
                open={this.state.dialogOpened}
                onClose={this._closeDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    {i18n.t('areYouSureYouWantToDeleteThis')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this._closeDialog} color='primary'>
                    {i18n.t('cancel')}
                  </Button>
                  <Button onClick={this._deleteProblem} color='primary'>
                    {i18n.t('confirm')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Container>
          }
          <ToastContainer />
        </div>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default SingleProblem;

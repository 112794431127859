import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import { Col, Container, Row } from 'reactstrap';
import { NoImageCitySrc } from '../../utils/ImageHelper';

import './styles.scss';
import i18n from '../../i18n';

class AlertDetails extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  _renderImage(image) {
    return (
      <div className='imageContainer'>
        <img
          src={image !== null ? image : NoImageCitySrc}
          className='alertImage'
          alt='Alerte'
        />
      </div>
    );
  }

  render() {
    const alert = this.props.alert;
    const alertTypes = this.props.alertTypes;
    const userCityIds = this.props.userCityIds;
    const languageEnabled = i18n.getLanguageEnabled();

    return (
      <Container className='AlertDetails'>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            {this._renderImage(alert.pictureUrl)}
            <Row className='alertStatusContainer'>
              <p className={alert.sent ? 'alertSent' : 'alertUnsent'}>{alert.sendStatus}</p>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <Row>
              <p className='label'><span className='title'>{i18n.t('title')}</span>{alert.title}</p>
            </Row>
            <Row>
              <p className='label'><span className='title'>{i18n.t('dispatchDate')}</span>{alert.dateToSendFormatted}</p>
            </Row>
            <Row>
              <p className='label'><span className='title'>Type</span>{alertTypes[alert.type][languageEnabled]}</p>
            </Row>
            {(userCityIds.length > 1 || alert.group_alert) &&
              <Row>
                <p className='label'><span className='title'>{i18n.t('cities')}</span>{alert.citiesName}</p>
              </Row>
            }
            <Row>
              <p className='label'><span className='title'>{i18n.t('communicationChannels')}</span>{alert.communicationChannels}</p>
            </Row>
            <Row>
              <p className='label'><span className='title'>{i18n.t('content')}</span>{alert.content}</p>
            </Row>
            {
              (alert.moreInformationUrl !== null && alert.moreInformationUrl !== '') &&
              <Row>
                <p className='label'><span className='title'>{i18n.t('urlLink')}</span><a href={alert.moreInformationUrl} className='label'>{alert.moreInformationUrl.length > 150 ? alert.moreInformationUrl.slice(0, 150) + '...' : alert.moreInformationUrl}</a></p>
              </Row>
            }
            {
              alert.group_alert &&
              <React.Fragment>
                <Row>
                  <p className='label'><span className='title'>{i18n.t('sentToGroups')}</span></p>
                </Row>
                {
                  alert.groups.map(group =>
                    <p key={group.id}>
                      {group.name}
                    </p>
                  )
                }
              </React.Fragment>
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AlertDetails;

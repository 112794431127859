/*global google*/
import React from 'react';
import AsyncComponent from '../AsyncComponent';
import { GoogleMap, HeatmapLayer, Marker, MarkerClusterer } from '@react-google-maps/api';

import { ProblemCategories } from '../../utils/Enums';
import { GoogleMapCustomStyle } from '../../utils/GoogleMapCustomStyle';
import User from '../../data/User';
import Problems from '../../data/Problems';
import MarkerOverlay from '../MarkerOverlay';

class MapGoogleComponent extends AsyncComponent {
  constructor(props) {
    super(props);

    this._onBoundsChanged = this._onBoundsChanged.bind(this);
    this._onMapLoad = this._onMapLoad.bind(this);
    this._setMapCenter = this._setMapCenter.bind(this);

    this.problemsData = Problems.getInstance();
    this.problemsData.addListener(this);

    this.state = {
      center: { lat: 45.497668, lng: -73.577900 }
    };
  }

  _getCityLocation(city) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': city }, this._setMapCenter);
  }

  _getPoints(list) {
    const points = [];
    list.forEach(val => {
      const obj = new google.maps.LatLng(val.latitude, val.longitude);
      points.push(obj);
    });

    return points;
  }

  _onBoundsChanged() {
    if (this.map && this.map.getBounds()) {
      const bounds = this.map.getBounds();
      this.problemsData.setBounds(bounds);
    }
  }

  async _onMapLoad(map) {
    this.map = map;
    const problems = this.problemsData.getProblems();
    if (problems && problems.length !== 0) {
      const bounds = new google.maps.LatLngBounds();
      problems.forEach(problem => {
        const latlng = new google.maps.LatLng(problem.latitude, problem.longitude);
        bounds.extend(latlng);
      });

      map && map.fitBounds(bounds);
    }
    else if (User.getInstance().getUser().city_id !== null) {
      const city = await User.getInstance().getUserCity();
      this._getCityLocation(city.name + ' ' + city.state + ' ' + city.country);
    }
  }

  async _setMapCenter(results, status) {
    if (status === 'OK') {
      const center = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
      await this.setStateAsync({ center });
    }
  }

  _updateCurrentProblem(currentProblem) {
    this.problemsData.setCurrentProblem(currentProblem);
  }

  _renderClusters() {
    return this.clusterActive &&
      <MarkerClusterer>
        {clusterer =>
          this.problems.map(problem =>
            <Marker
              position={{ lat: problem.latitude, lng: problem.longitude }}
              key={problem.id}
              onClick={() => this._updateCurrentProblem(problem)}
              icon={{
                url: ProblemCategories[problem.category].marker,
                scaledSize: new google.maps.Size(50, 50)
              }}
              zIndex={1}
              clusterer={clusterer}
            />
          )}
      </MarkerClusterer>;
  }

  _renderHeat() {
    if (!this.clusterActive) {
      return (
        <HeatmapLayer
          data={this._getPoints(this.problems)}
        />
      );
    }
  }

  _renderMarkerOverlay() {
    if (this.currentProblem) {
      const center = { lat: this.currentProblem.latitude, lng: this.currentProblem.longitude };
      if (this.currentProblem.id &&
        this.map &&
        this.map.getBounds() !== undefined &&
        this.map.getBounds().contains(center) &&
        this.clusterActive) {

        return (
          <MarkerOverlay
            problem={this.currentProblem}
          />
        );
      }
    }
  }

  render() {
    this.problems = this.problemsData.getProblemsOnMap();
    this.currentProblem = this.problemsData.getCurrentProblem();
    this.clusterActive = this.problemsData.getClusterActive();

    return (
      <GoogleMap
        mapContainerClassName='map'
        center={this.state.center}
        zoom={10}
        version='weekly'
        onClick={() => this._updateCurrentProblem(null)}
        onLoad={this._onMapLoad}
        onBoundsChanged={this._onBoundsChanged}
        options={{
          gestureHandling: 'greedy',
          streetViewControl: false,
          styles: GoogleMapCustomStyle.mapCustomStyles
        }}
      >
        {this._renderClusters()}
        {this._renderMarkerOverlay()}
        {this._renderHeat()}
      </GoogleMap>
    );
  }
}

export default MapGoogleComponent;

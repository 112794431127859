import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@material-ui/core/Button';
import { Container, Col, Form, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment-timezone';
import orderBy from 'lodash/orderBy';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import TimePicker from 'react-time-picker';
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';
import 'react-datepicker/dist/react-datepicker.css';

import AccessForbidden from '../../components/AccessForbidden';
import LogsService from '../../services/LogsService';
import ParkingService from '../../services/ParkingService';
import Table from '../../components/Table';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class Parking extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      alertTitle: '',
      noRestrictionMessage: '',
      columnToSort: 'createdAt',
      errorMessage: '',
      errorTitle: '',
      logInformations: {},
      moreInformationUrl: '',
      open: false,
      openError: false,
      openMoreInformations: false,
      nightParking: {},
      nightParkingLogs: [],
      newParkingZoneName: '',
      selectedCityId: '',
      sortDirection: 'desc',
      startDate: new Date(),
      status: true,
      stopDate: new Date(),
      time: '17:00',
      timeToStop: '8:00',
      user: User.getInstance().getUser(),
      userCities: [],
      userQuery: '',
      zoneToDelete: {},
      zones: []
    };

    this._addParkingZone = this._addParkingZone.bind(this);
    this._deleteParkingZone = this._deleteParkingZone.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleStartDateChange = this._handleStartDateChange.bind(this);
    this._handleStopDateChange = this._handleStopDateChange.bind(this);
    this._handleMoreInformations = this._handleMoreInformations.bind(this);
    this._handleTimeChange = this._handleTimeChange.bind(this);
    this._handleStopTimeChange = this._handleStopTimeChange.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._onUserQueryChange = this._onUserQueryChange.bind(this);
    this._updateAlertDetails = this._updateAlertDetails.bind(this);
    this._updateNightParkingZones = this._updateNightParkingZones.bind(this);
    this._updateNightParkingZoneStatus = this._updateNightParkingZoneStatus.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._setSelectedCityId(this.state.userCities[0].cityId);
  }

  async _addParkingZone() {
    if (this.state.newParkingZoneName !== '') {
      const newNightParkingZone = {
        night_parking_id: this.state.selectedCityId,
        name: this.state.newParkingZoneName
      };

      const success = await ParkingService.addParkingZone(newNightParkingZone);
      if (success) {
        this._notify(i18n.t('successfullyCreated'));
        await this._fetchNightParking();
      }
      else {
        this._notifyError(i18n.t('errorDuringCreation'));
      }
    }
    else {
      await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustEnterAZoneName'));
    }
  }

  async _deleteParkingZone() {
    const success = await ParkingService.deleteParkingZone(this.state.zoneToDelete.id);
    if (success) {
      this._notify(i18n.t('successfullyRemoved'));
      this.state.zones = this.state.zones.filter(zone => zone.id !== this.state.zoneToDelete.id);
      await this._handleClose();
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _fetchCityLogs() {
    if (this.state.user.access_level === 'admin' || this.state.user.access_level === 'super_admin') {
      const nightParkingLogs = await LogsService.fetchCityLogs(this.state.selectedCityId, 'night_parking');
      await this.setStateAsync({ nightParkingLogs });
    }
  }

  async _fetchNightParking() {
    const nightParking = await ParkingService.fetchNightParking(this.state.selectedCityId);
    if (nightParking) {
      nightParking.zones.sort((a, b) =>
        a.id > b.id ? 1
          : (a.id < b.id ? -1 : 0));

      const startDateTime = moment(nightParking.start_datetime);
      const stopDateTime = moment(nightParking.stop_datetime);

      await this.setStateAsync({
        alertMessage: nightParking.alert_message,
        alertTitle: nightParking.alert_title,
        noRestrictionMessage: nightParking.no_restriction_message,
        startDate: startDateTime.toDate(),
        stopDate: stopDateTime.toDate(),
        moreInformationUrl: nightParking.more_information_url ? nightParking.more_information_url : '',
        nightParking: nightParking,
        time: startDateTime.format('HH:mm'),
        timeToStop: stopDateTime.format('HH:mm'),
        zones: nightParking.zones,
        newParkingZoneName: '',
      });
    }
  }

  async _getUserCities() {
    let userCities = await User.getInstance().getUserCities();
    userCities = userCities.filter(city => city.parkingEnabled);
    await this.setStateAsync({ userCities });
  }

  async _handleClose() {
    await this.setStateAsync({
      open: false,
      openError: false,
      openMoreInformations: false
    });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleMoreInformations(logInformations) {
    await this.setStateAsync({
      openMoreInformations: true,
      logInformations: logInformations
    });
  }

  async _handleOpen(zone) {
    await this.setStateAsync({ zoneToDelete: zone, open: true });
  }

  async _handleOpenError(errorTitle, errorMessage) {
    await this.setStateAsync({ openError: true, errorTitle, errorMessage });
  }

  async _handleOpenLogInformations() {
    await this.setStateAsync({ openMoreInformations: true });
  }

  async _handleSort(columnName) {
    await this.setStateAsync(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : 'asc'
    }));
  }

  async _handleStartDateChange(date) {
    let stopDate = this.state.stopDate;
    if (date > stopDate) {
      stopDate = date;
    }

    await this.setStateAsync({
      startDate: date,
      stopDate: stopDate,
    });
  }

  async _handleStopDateChange(date) {
    await this.setStateAsync({
      stopDate: date
    });
  }

  async _handleTimeChange(time) {
    await this.setStateAsync({ time });
  }

  async _handleStopTimeChange(timeToStop) {
    await this.setStateAsync({ timeToStop });
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(errorMessage) {
    toast.error(errorMessage);
  }

  async _onUserQueryChange(e) {
    await this.setStateAsync({ userQuery: e.target.value });
  }

  async _setSelectedCityId(selectedCityId) {
    await this.setStateAsync({ selectedCityId });
    await this._fetchNightParking();
    await this._fetchCityLogs();
  }

  async _updateAlertDetails() {
    const startDateTime = moment(moment(this.state.startDate).format('YYYY-MM-DD') + ' ' + this.state.time, 'YYYY-MM-DD HH:mm');
    const stopDateTime = moment(moment(this.state.stopDate).format('YYYY-MM-DD') + ' ' + this.state.timeToStop, 'YYYY-MM-DD HH:mm');
    if (this.state.moreInformationUrl !== '' && !validator.isURL(this.state.moreInformationUrl, { protocols: ['http', 'https'], require_protocol: true })) {
      await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('yourUrlLinkIsInvalid'));
    }
    else if (this.state.stopDate < this.state.startDate) {
      await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustEnterAnEndDataThatIsAfterTheStartDate'));
    }
    else if (!this.state.time || !this.state.timeToStop) {
      await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('sendTimeAndStopTimeCannotBeEmpty'));
    }
    else if (stopDateTime <= startDateTime) {
      await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('theEndOfTheFirstAlertCannotBeBeforeSendingTheFristAlert'));
    }
    else {
      const nightParking = {
        alert_message: this.state.alertMessage,
        alert_title: this.state.alertTitle,
        no_restriction_message: this.state.noRestrictionMessage,
        start_datetime: startDateTime.utc().format(),
        stop_datetime: stopDateTime.utc().format(),
        night_parking_id: this.state.selectedCityId,
        more_information_url: this.state.moreInformationUrl
      };

      const success = await ParkingService.updateNightParking(nightParking);
      success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
      await this._fetchCityLogs();
    }
  }

  async _updateNightParkingZones() {
    const nightParking = this.state.nightParking;
    nightParking.zones = this.state.zones;
    const success = await ParkingService.updateNightParkingZoneStatus(nightParking);
    success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
    await this._fetchCityLogs();
  }

  async _updateNightParkingZoneStatus(zone, newStatus) {
    const nightParkingZones = this.state.nightParking.zones;
    zone.can_park = newStatus;
    nightParkingZones[nightParkingZones.findIndex(x => x.id === zone.id)] = zone;
    await this.setStateAsync({ zones: nightParkingZones });
  }

  _renderCitiesSelect() {
    if (this.state.user.access_level === 'super_admin' || this.state.userCities.length > 1) {
      return (
        <Row className='citiesSelectContainer'>
          <Col sm='6'>
            <FormControl fullWidth={true}>
              <InputLabel>{i18n.t('selectACityToConsult')}</InputLabel>
              <Select
                value={this.state.selectedCityId}
                onChange={async (_event) => await this._setSelectedCityId(_event.target.value)}
              >
                {
                  this.state.userCities.map(function(city) {
                    return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
      );
    }
  }

  _renderParkingZonesHeader() {
    if (this.state.zones.length !== 0) {
      return (
        <Row>
          <Col sm='4'>
            <h5>{'Zone'}</h5>
          </Col>
          <Col sm='4'>
            <h5>{i18n.t('status')}</h5>
          </Col>
        </Row>
      );
    }
  }

  _renderCurrentParkingZones() {
    if (this.state.zones.length === 0) {
      return (
        <h4>{i18n.t('noParkingZone')}</h4>
      );
    }

    return (
      this.state.zones.map(zone =>
        <Row key={zone.id}>
          <Col sm='4'>
            <h6>{zone.name}</h6>
          </Col>
          <Col sm='6'>
            <FormControl fullWidth={true}>
              <InputLabel>{i18n.t('status')}</InputLabel>
              <Select
                value={zone.can_park}
                onChange={async (_event) => await this._updateNightParkingZoneStatus(zone, _event.target.value)}
              >
                {
                  STATUS.map(function(access) {
                    return <MenuItem key={access.value} value={access.value}>{access.label}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
          {(this.state.user.access_level === 'admin' || this.state.user.access_level === 'super_admin') &&
            <Col sm='2' className='marginTop'>
              <Button
                fullWidth={true}
                variant='contained'
                onClick={async () => await this._handleOpen(zone)}
                className='deleteZoneButton'
              >
                {i18n.t('remove')}
              </Button>
              <Dialog
                open={this.state.open}
                onClose={this._handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    {i18n.t('areYouSureYouWantToDeleteThis')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this._handleClose} color='primary'>
                    {i18n.t('cancel')}
                  </Button>
                  <Button onClick={this._deleteParkingZone} color='primary'>
                    {i18n.t('confirm')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Col>
          }
        </Row>
      )
    );
  }

  _renderAddParkingZone() {
    if (this.state.user.access_level === 'admin' || this.state.user.access_level === 'super_admin') {
      return (
        <Container>
          <Row>
            <h4 className={'pageSecondTitle'}>{i18n.t('addANightParkingZone')}</h4>
          </Row>
          <Form>
            <Row>
              <Col sm='10'>
                <TextField
                  name='newParkingZoneName'
                  placeholder={i18n.t('addName')}
                  fullWidth={true}
                  label={i18n.t('name')}
                  helperText={i18n.t('thisFieldIsRequired')}
                  maxLength='255'
                  onChange={this._handleInputChange}
                  value={this.state.newParkingZoneName}
                />
                <br />
              </Col>
              <Col sm='2'>
                <Button
                  fullWidth={true}
                  variant='contained'
                  onClick={this._addParkingZone}
                  className='addZoneButton'
                >
                  {i18n.t('add')}
                </Button>
              </Col>
            </Row>
            <Dialog
              open={this.state.openError}
              onClose={this._handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>{this.state.errorTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  {this.state.errorMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this._handleClose} color='primary'>
                  {'Ok'}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        </Container>
      );
    }
  }

  _renderParkingZonesAlertDetails() {
    if (this.state.zones.length !== 0 && (this.state.user.access_level === 'admin' || this.state.user.access_level === 'super_admin')) {
      return (
        <Container>
          <Row>
            <h4 className={'pageSecondTitle'}>{i18n.t('additionalInformationEdit')}</h4>
          </Row>
          <Form>
            <Row className={'additionalInfo'}>
              <Col sm='12'>
                <TextField
                  name='moreInformationUrl'
                  placeholder={i18n.t('addUrlLink')}
                  fullWidth={true}
                  label={i18n.t('urlLink')}
                  helperText={i18n.t('thisFieldIsOptional')}
                  maxLength='255'
                  onChange={this._handleInputChange}
                  value={this.state.moreInformationUrl}
                />
                <br />
              </Col>
            </Row>
            <Row className={'additionalInfo'}>
              <Col sm='12'>
                <TextField
                  name='alertTitle'
                  placeholder={i18n.t('addTitle')}
                  fullWidth={true}
                  label={i18n.t('title')}
                  helperText={i18n.t('thisFieldIsOptional')}
                  maxLength='255'
                  onChange={this._handleInputChange}
                  value={this.state.alertTitle}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <br />
              </Col>
            </Row>
            <Row className={'additionalInfo'}>
              <Col sm='9'>
                <TextField
                  name='alertMessage'
                  placeholder={i18n.t('addHeaderMessage')}
                  fullWidth={true}
                  label={i18n.t('zoneHeaderMessage')}
                  helperText={i18n.t('thisFieldIsOptional')}
                  maxLength='255'
                  multiline={true}
                  onChange={this._handleInputChange}
                  value={this.state.alertMessage}
                />
                <br />
              </Col>
              <Col sm='3'>
                <h6>{i18n.t('dispatchTime')}</h6>
                <TimePicker
                  onChange={this._handleTimeChange}
                  value={this.state.time}
                  locale={'en-US'}
                  disableClock={true}
                />
              </Col>
            </Row>
            <Row className={'additionalInfo'}>
              <Col sm='9'>
                <TextField
                  name='noRestrictionMessage'
                  placeholder={i18n.t('addInactivityMessage')}
                  fullWidth={true}
                  label={i18n.t('inactivityMessage')}
                  helperText={i18n.t('thisFieldIsOptional')}
                  maxLength='255'
                  multiline={true}
                  onChange={this._handleInputChange}
                  value={this.state.noRestrictionMessage}
                />
                <br />
              </Col>
              <Col sm='3'>
                <h6>{i18n.t('alertStopTime')}</h6>
                <TimePicker
                  onChange={this._handleStopTimeChange}
                  value={this.state.timeToStop}
                  locale={'en-US'}
                  disableClock={true}
                />
              </Col>
            </Row>
            <Row className='marginTop'>
              <Col sm='2'>
                <p>{i18n.t('startDate')}</p>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this._handleStartDateChange}
                  popperPlacement={'top'}
                  popperModifiers={{
                    flip: {
                      behavior: ['top']
                    },
                    preventOverflow: {
                      enabled: false
                    },
                    hide: {
                      enabled: false
                    }
                  }}
                />
              </Col>
              <Col sm='2'>
                <p>{i18n.t('stopDate')}</p>
                <DatePicker
                  selected={this.state.stopDate}
                  onChange={this._handleStopDateChange}
                  minDate={this.state.startDate ? this.state.startDate : new Date()}
                  popperPlacement={'top'}
                  popperModifiers={{
                    flip: {
                      behavior: ['top']
                    },
                    preventOverflow: {
                      enabled: false
                    },
                    hide: {
                      enabled: false
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm='4'>
                <Button
                  variant='contained'
                  onClick={this._updateAlertDetails}
                  className='editParkingInformationsButton'
                >
                  {i18n.t('edit')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      );
    }
  }

  _renderParkingZonesArchive() {
    const lowerCaseQuery = this.state.userQuery.toLowerCase();
    if (this.state.user.access_level === 'admin' || this.state.user.access_level === 'super_admin') {
      return (
        <Container className='nightParkingZoneArchive'>
          <Row>
            <h4 className={'pageSecondTitle'}>{i18n.t('nightParkingHistory')}</h4>
          </Row>
          <React.Fragment>
            <Row>
              <Col sm='6'>
                <TextField
                  placeholder={i18n.t('enterTheDate')}
                  fullWidth={true}
                  label={i18n.t('searchForAnArchive')}
                  maxLength='255'
                  onChange={this._onUserQueryChange}
                  value={this.state.userQuery}
                />
              </Col>
            </Row>
            <Table
              sortDirection={this.state.sortDirection}
              columnToSort={this.state.columnToSort}
              handleSort={this._handleSort}
              handleMoreInformations={this._handleMoreInformations}
              deleteAction={false}
              blockAction={false}
              moreInformations={true}
              data={orderBy(
                this.state.userQuery
                  ? this.state.nightParkingLogs.filter(x =>
                    x['createdAt']
                      .toLowerCase()
                      .includes(lowerCaseQuery)
                  )
                  : this.state.nightParkingLogs,
                this.state.columnToSort,
                this.state.sortDirection)}
              header={[
                {
                  name: 'Date',
                  prop: 'createdAt'
                },
                {
                  name: i18n.t('user'),
                  prop: 'employee'
                }
              ]}
            />
          </React.Fragment>
        </Container>
      );
    }
  }

  _renderLogInformations() {
    return (
      <Dialog
        open={this.state.openMoreInformations}
        onClose={this._handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{i18n.t('additionalInformationAboutTheParkingArchive')}</DialogTitle>
        <DialogContent>
          <h5>{i18n.t('content')}:</h5>
          <p className='log'>{this.state.logInformations.content}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._handleClose} color='primary'>
            {'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderSaveButton() {
    if (this.state.zones.length !== 0) {
      return (
        <Row>
          <Col sm='6'>
            <Button
              fullWidth={true}
              variant='contained'
              onClick={this._updateNightParkingZones}
              className='saveZoneStatusChangesButton'
            >
              {i18n.t('save')}
            </Button>
          </Col>
        </Row>
      );
    }
  }

  render() {
    const accessLevel = User.getInstance().getUser().access_level;
    if (accessLevel === 'publisher_public_works' || accessLevel === 'admin' || accessLevel === 'super_admin') {
      return (
        <Container className='Parking'>
          {this._renderCitiesSelect()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('nightParking')}</h2>
          </Row>
          {this._renderParkingZonesHeader()}
          {this._renderCurrentParkingZones()}
          {this._renderSaveButton()}
          {this._renderAddParkingZone()}
          {this._renderParkingZonesAlertDetails()}
          {this._renderParkingZonesArchive()}
          {this._renderLogInformations()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

const STATUS = [
  { label: i18n.t('authorized'), value: true },
  { label: i18n.t('notAllowed'), value: false },
];

export default Parking;

import HttpMessage, { ROOT_URL } from './HttpMessage';
import moment from 'moment-timezone';

import { getHttpCitiesFilter } from '../utils/Filters';
import Cities from '../data/Cities';
import User from '../data/User';
import i18n from '../i18n';

class CommunicationsService {

  static async fetchAlerts(cityIds) {
    const citiesFilters = getHttpCitiesFilter(cityIds);

    // Already sent alerts
    let message = {
      route: `${ROOT_URL}/city_alerts?${citiesFilters}&communication_channel=push&communication_channel=sms&communication_channel=voice&communication_channel=facebook&token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    let data = await HttpMessage.sendMessage(message);

    const alertsList = [];
    if (data.status === 'success') {
      await Promise.all(data.alerts.map(async alert => {
        const obj = {};
        obj.id = alert.id;
        obj.title = alert.title;
        obj.content = alert.content;
        obj.pictureUrl = alert.picture_url;
        obj.moreInformationUrl = alert.more_information_url;
        obj.cityId = alert.city_id;
        obj.type = alert.type;
        obj.createdAt = moment(alert.created_at);
        obj.sent = alert.sent;
        obj.dateToSend = alert.time_to_send !== null ? moment(alert.time_to_send) : moment(alert.created_at);
        obj.timeToSend = alert.time_to_send !== null ? moment(alert.time_to_send).format('HH:mm') : moment(alert.created_at).format('HH:mm');
        obj.modificationsEnabled = false;
        obj.group_alert = alert.group_alert;
        obj.groups = alert.groups;
        obj.cityIds = alert.city_ids;
        obj.citiesName = await Cities.getInstance().getCitiesName(alert.city_ids);
        obj.push = alert.push;
        obj.facebook = alert.facebook;
        obj.sms = alert.sms;
        obj.voice = alert.voice;
        obj.sendStatus = i18n.t('sent');
        obj.dateToSendFormatted = alert.time_to_send !== null ? moment(alert.time_to_send).format('YYYY-MM-DD HH:mm') : moment(alert.created_at).format('YYYY-MM-DD HH:mm');
        obj.communicationChannels = await this.getCommunicationChannels(alert);
        if (alert.pattern) {
          obj.pattern = alert.pattern;
          obj.patternCount = alert.pattern_count;
          obj.endDate = alert.end_datetime;
        }

        alertsList.push(obj);
      }));
    }

    // Unsent alerts
    message = {
      route: `${ROOT_URL}/city_alerts/unsent?${citiesFilters}&communication_channel=push&communication_channel=sms&communication_channel=voice&token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    data = await HttpMessage.sendMessage(message);

    if (data.status === 'success') {
      await Promise.all(data.alerts.map(async alert => {
        const obj = {};
        obj.id = alert.id;
        obj.title = alert.title;
        obj.content = alert.content;
        obj.pictureUrl = alert.picture_url;
        obj.moreInformationUrl = alert.more_information_url;
        obj.cityId = alert.city_id;
        obj.type = alert.type;
        obj.createdAt = moment(alert.created_at);
        obj.sent = alert.sent;
        obj.dateToSend = moment(alert.time_to_send);
        obj.timeToSend = moment(alert.time_to_send).format('HH:mm');
        obj.modificationsEnabled = false;
        obj.group_alert = alert.group_alert;
        obj.groups = alert.groups;
        obj.cityIds = alert.city_ids;
        obj.citiesName = await Cities.getInstance().getCitiesName(alert.city_ids);
        obj.push = alert.push;
        obj.facebook = alert.facebook;
        obj.sms = alert.sms;
        obj.voice = alert.voice;
        obj.sendStatus = i18n.t('expectedDispatchOn') + ' ' + moment(alert.time_to_send).format('YYYY-MM-DD') + ' ' + i18n.t('at') + ' ' + moment(alert.time_to_send).format('HH:mm');
        obj.dateToSendFormatted = alert.time_to_send !== null ? moment(alert.time_to_send).format('YYYY-MM-DD HH:mm') : moment(alert.created_at).format('YYYY-MM-DD HH:mm');
        obj.communicationChannels = await this.getCommunicationChannels(alert);

        if (alert.pattern) {
          obj.pattern = alert.pattern;
          obj.patternCount = alert.pattern_count;
          obj.endDate = alert.end_datetime;
        }

        alertsList.push(obj);
      }));
    }

    return alertsList;
  }

  static async fetchAlertDeliveryStatus(alert) {
    const message = {
      route: `${ROOT_URL}/city_alerts/stats/${alert.id}?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let smsDelivery = [];
    if (data.status === 'success') {
      smsDelivery = data.smsStats;
    }

    const smsDelivered = smsDelivery.filter((obj) => obj.status === 'delivered').length;
    const smsSent = smsDelivery.filter((obj) => obj.status === 'sent').length;
    const smsUndelivered = smsDelivery.filter((obj) => obj.status === 'undelivered').length;
    const smsFailed = smsDelivery.filter((obj) => obj.status === 'failed').length;

    smsDelivery = smsDelivery.map(alertStatus =>
      ({to: alertStatus.to, status: i18n.t(alertStatus.status)})
    );

    const alertSmsDeliveryStatus = {
      smsDelivered: smsDelivered,
      smsSent: smsSent,
      smsUndelivered: smsUndelivered,
      smsFailed: smsFailed,
      smsDelivery: smsDelivery
    };

    return alertSmsDeliveryStatus;
  }

  static async fetchAlertTypes() {
    const message = {
      route: `${ROOT_URL}/city_alerts/types`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let alertTypes = {};
    if (data.status === 'success') {
      alertTypes = data.types;
      delete alertTypes.survey;
    }

    return alertTypes;
  }

  static async sendAlert(alert) {
    const message = {
      route: `${ROOT_URL}/city_alerts?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(alert),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }

  static async editAlert(alert) {
    const message = {
      route: `${ROOT_URL}/city_alerts/${alert.id}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(alert),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }

  static async deleteAlert(id) {
    const message = {
      route: `${ROOT_URL}/city_alerts/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async fetchSurveys(cityIds) {
    const citiesFilters = getHttpCitiesFilter(cityIds);
    const message = {
      route: `${ROOT_URL}/city_surveys?${citiesFilters}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const surveysList = [];

    if (data.status === 'success') {
      await Promise.all(data.surveys.map(async survey => {
        const obj = {};
        obj.id = survey.id;
        obj.title = survey.title;
        obj.content = survey.content;
        obj.active = survey.active;
        obj.cityId = survey.city_id;
        obj.createdAt = moment(survey.created_at);
        obj.choices = survey.choices;
        obj.cityIds = survey.city_ids;
        obj.citiesName = await Cities.getInstance().getCitiesName(survey.city_ids);
        obj.dateFormatted = moment(survey.created_at).format('YYYY-MM-DD HH:mm');

        surveysList.push(obj);
      }));
    }

    return surveysList;
  }

  static async sendSurvey(survey) {
    const message = {
      route: `${ROOT_URL}/city_surveys?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(survey),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    return data.status === 'success';
  }

  static async editSurvey(survey) {
    const message = {
      route: `${ROOT_URL}/city_surveys/${survey.id}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(survey),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifySurveyStatus(currentSurvey) {
    const message = {
      route: `${ROOT_URL}/city_surveys/${currentSurvey.id}/status?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(currentSurvey),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async deleteSurvey(id) {
    const message = {
      route: `${ROOT_URL}/city_surveys/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async getCommunicationChannels(alert) {
    let communicationChannels = '';

    if (alert.push) {
      communicationChannels += i18n.t('pushNotification') + ', ';
    }

    if (alert.facebook) {
      communicationChannels += 'Facebook, ';
    }

    if (alert.sms) {
      communicationChannels += 'SMS, ';
    }

    if (alert.voice) {
      communicationChannels += i18n.t('voiceCall') + ', ';
    }

    if (communicationChannels.length !== 0) {
      communicationChannels = communicationChannels.slice(0, -2);
    }

    return communicationChannels;
  }
}

export default CommunicationsService;

import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@material-ui/core/Button';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import deburr from 'lodash/deburr';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Multiselect from 'react-select';
import { orderBy } from '../../utils/array_helper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast } from 'react-toastify';

import CommunicationsService from '../../services/CommunicationsService';

import AccessForbidden from '../../components/AccessForbidden';
import SurveyDetails from '../../components/SurveyDetails';
import Table from '../../components/Table';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

const invertDirection = {
  asc: 'desc',
  desc: 'asc'
};

class Survey extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      choices: ['', ''],
      cityToFetch: 0,
      columnToSort: 'dateFormatted',
      content: '',
      editing: false,
      errorMessage: '',
      errorTitle: '',
      inputs: ['input-0', 'input-1'],
      loading: false,
      open: false,
      openConsult: false,
      openError: false,
      sortDirection: 'desc',
      surveys: [],
      surveyDetails: null,
      surveyEdited: null,
      surveyToDelete: {},
      title: '',
      userCities: [],
      userCitiesSelected: [],
      userQuery: '',
    };

    this._appendInput = this._appendInput.bind(this);
    this._deleteSurvey = this._deleteSurvey.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
    this._handleClose = this._handleClose.bind(this);
    this._toggleConsult = this._toggleConsult.bind(this);
    this._handleCloseSurveyConsultation = this._handleCloseSurveyConsultation.bind(this);
    this._handleDynamicInputChange = this._handleDynamicInputChange.bind(this);
    this._handleDynamicEditingInputChange = this._handleDynamicEditingInputChange.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._handleSelectMultiCitiesChange = this._handleSelectMultiCitiesChange.bind(this);
    this._handleSort = this._handleSort.bind(this);
    this._onUserQueryChange = this._onUserQueryChange.bind(this);
    this._sendSurvey = this._sendSurvey.bind(this);
    this._toggleEditing = this._toggleEditing.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchSurveys();
  }

  async _appendInput() {
    const newInput = `input-${this.state.inputs.length}`;
    this.state.choices.push('');
    await this.setStateAsync({ inputs: this.state.inputs.concat([newInput]) });
  }

  async _cancelEditing(survey) {
    survey.surveyEditingEnabled = false;
    await this.setStateAsync({
      editing: false,
      surveys: this.state.surveys,
      surveyEdited: null
    });
  }

  async _deleteSurvey() {
    const success = await CommunicationsService.deleteSurvey(this.state.surveyToDelete.id);
    if (success) {
      await this.setStateAsync({ open: false });
      this._notifySuccess(i18n.t('successfullyRemoved'));
      await this.setStateAsync({ editing: false, surveyToDelete: null });
      await this._fetchSurveys();
    }
    else {
      this._notifyError(i18n.t('errorDuringDeletion'));
    }
  }

  async _fetchSurveys() {
    const citiesToFetchUsersFrom = this.state.cityToFetch === 0 ? this.state.userCityIds : [this.state.cityToFetch];
    let surveys = await CommunicationsService.fetchSurveys(citiesToFetchUsersFrom);
    surveys = surveys.filter(survey => this.state.cityToFetch === 'null' || this.state.cityToFetch === 0 || survey.cityId !== null);

    surveys.forEach(survey => {
      survey.choices.forEach(choice => {
        choice.color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      });
    });

    surveys = surveys.sort((a, b) =>
      a.id < b.id ? 1
        : (a.id > b.id ? -1 : 0));
    await this.setStateAsync({ surveys });
  }

  async _filterSurveys(cityToFetch) {
    await this.setStateAsync({ cityToFetch });
    this._fetchSurveys();
  }

  async _getUserCities() {
    const userCities = await User.getInstance().getUserCities();
    const userCityIds = userCities.map(city => city.cityId).filter(cityId => cityId !== 'null');
    await this.setStateAsync({ userCities, userCityIds });

    if (userCities.length === 1) {
      await this.setStateAsync({ userCitiesSelected: [{ value: userCities[0].cityId, label: userCities[0].name }], cityToFetch: userCities[0].cityId });
    }
  }

  async _handleOpenError(errorTitle, errorMessage) {
    await this.setStateAsync({ openError: true, errorTitle, errorMessage });
  }

  async _handleRemove(survey) {
    await this.setStateAsync({ surveyToDelete: survey, open: true });
  }

  async _handleClose() {
    await this.setStateAsync({ open: false, openError: false });
  }

  async _handleCloseSurveyConsultation() {
    await this.setStateAsync({ openConsult: false });
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleDynamicInputChange(event) {
    const choices = this.state.choices;
    const { name, value } = event.target;
    choices[name] = value;
    await this.setStateAsync({
      choices
    });
  }

  async _handleDynamicEditingInputChange(event) {
    const surveyEdited = this.state.surveyEdited;
    const { name, value } = event.target;
    surveyEdited.choices[name].title = value;
    await this.setStateAsync({
      surveyEdited
    });
  }

  async _handleSort(columnName) {
    await this.setStateAsync(state => ({
      columnToSort: columnName,
      sortDirection:
        state.columnToSort === columnName
          ? invertDirection[state.sortDirection]
          : 'asc'
    }));
  }

  async _handleSelectMultiCitiesChange(cities) {
    await this.setStateAsync({ userCitiesSelected: cities ? cities : [] });
  }

  async _toggleConsult(surveyDetails) {
    await this.setStateAsync({
      surveyDetails,
      openConsult: true,
    });
  }

  async _toggleEditing(survey) {
    survey.surveyEditingEnabled = true;

    const surveyEdited = JSON.parse(JSON.stringify(survey));

    await this.setStateAsync({
      editing: true,
      openModification: true,
      surveyEdited,
    });
  }

  async _modifySurvey(surveyEdited) {
    if (!this.state.loading) {
      if (surveyEdited.title === '') {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustAddATitle'));
      }
      else if (surveyEdited.content === '') {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youMustAddAContent'));
      }
      else if (surveyEdited.choices.some(choice => choice.title === '')) {
        await this._handleOpenError(i18n.t('errorDuringModification'), i18n.t('youCannotHaveAnEmptyChoice'));
      }
      else {
        await this.setStateAsync({ loading: true });

        const success = await CommunicationsService.editSurvey(surveyEdited);
        if (success) {
          await this._fetchSurveys();
          await this.setStateAsync({
            editing: false,
            surveyEdited: null
          });
          this._notifySuccess(i18n.t('successfullyModified'));
        }
        else {
          this._notifyError(i18n.t('errorDuringModification'));
        }

        await this.setStateAsync({ loading: false });
      }
    }
  }

  async _modifySurveyStatus(survey) {
    const modifiedSurvey = {
      cityId: survey.cityId,
      content: survey.content,
      id: survey.id,
      active: !survey.active,
      title: survey.title,
    };

    const success = await CommunicationsService.modifySurveyStatus(modifiedSurvey);
    if (success) {
      survey.active = !survey.active;
      await this.setStateAsync({ surveys: this.state.surveys });
      this._notifySuccess(i18n.t('successfullyModified'));
    }
    else {
      this._notifyError(i18n.t('errorDuringModification'));
    }
  }

  _notifyError(message) {
    toast.error(message);
  }

  _notifySuccess(message) {
    toast.success(message);
  }

  async _onUserQueryChange(e) {
    await this.setStateAsync({ userQuery: e.target.value });
  }

  async _sendSurvey() {
    if (!this.state.loading) {
      if (this.state.title === '') {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAddATitle'));
      }
      if (this.state.content === '') {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAddAContent'));
      }
      else if (this.state.choices[0] === '' || this.state.choices[1] === '') {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustAdd2Choices'));
      }
      else if (this.state.userCities.length > 1 && this.state.userCitiesSelected.length === 0) {
        await this._handleOpenError(i18n.t('errorDuringCreation'), i18n.t('youMustChooseAtLeastOneCity'));
      }
      else {
        await this.setStateAsync({ loading: true });

        const survey = {
          title: this.state.title,
          content: this.state.content,
          city_ids: this.state.userCitiesSelected.map(city => city.value === 'null' ? null : city.value),
          choices: JSON.parse(JSON.stringify(this.state.choices.filter(choice => choice))),
        };

        const success = await CommunicationsService.sendSurvey(survey);

        if (success) {
          await this._fetchSurveys();
          await this.setStateAsync({
            choices: ['', ''],
            content: '',
            inputs: ['input-0', 'input-1'],
            title: '',
            userCitiesSelected: []
          });

          if (this.state.userCities.length === 1) {
            await this.setStateAsync({ userCitiesSelected: [{ value: this.state.userCities[0].cityId, label: this.state.userCities[0].name }] });
          }

          this._notifySuccess(i18n.t('successfullyCreated'));
        }
        else {
          this._notifyError(i18n.t('errorDuringCreation'));
        }

        await this.setStateAsync({ loading: false });
      }
    }
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <Row>
          <Col sm='12'>
            <Multiselect
              label={i18n.t('targetedCities')}
              isMulti={true}
              onChange={this._handleSelectMultiCitiesChange}
              options={this.state.userCities.map((city) => {
                return { label: city.name, value: city.cityId };
              })}
              placeholder={i18n.t('targetedCities')}
              value={this.state.userCitiesSelected}
              className='multiSelectCity'
            />
          </Col>
        </Row>
      );
    }
  }

  _renderDialogs() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this._handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{i18n.t('confirmationOfDeletion')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {i18n.t('areYouSureYouWantToDeleteThis')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._handleClose} color='primary'>
            {i18n.t('cancel')}
          </Button>
          <Button onClick={this._deleteSurvey} color='primary'>
            {i18n.t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderSurveyForm() {
    return (
      <Form>
        <FormGroup>
          <TextField
            name='title'
            placeholder={i18n.t('addQuestion')}
            fullWidth={true}
            label={'Question'}
            helperText={i18n.t('thisFieldIsRequired')}
            maxLength='255'
            multiline={true}
            onChange={this._handleInputChange}
            value={this.state.title}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
          <br />
          <TextField
            name='content'
            id='f_message'
            placeholder={i18n.t('addDescription')}
            fullWidth={true}
            label={'Description'}
            helperText={i18n.t('thisFieldIsOptional')}
            multiline={true}
            onChange={this._handleInputChange}
            value={this.state.content}
          />
          <br />
        </FormGroup>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            {
              this.state.inputs.map(
                (input, index) =>
                  <div id='dynamicInput' key={input}>
                    <TextField
                      key={input}
                      fullWidth={true}
                      name={index.toString()}
                      id='f_message'
                      placeholder={i18n.t('enterAChoice')}
                      label={i18n.t('choice')}
                      helperText={i18n.t('thisFieldIsRequired')}
                      maxLength='255'
                      multiline={true}
                      onChange={this._handleDynamicInputChange}
                      value={this.state.choices[index]}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
              )
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={3}>
            <Button
              variant='outlined'
              onClick={this._appendInput}
              fullWidth={true}
              className='addChoiceButton'
            >
              {i18n.t('addAChoice')}
            </Button>
          </Col>
        </Row>
        {this._renderCitiesSelect()}
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Button
              variant='contained'
              onClick={this._sendSurvey}
              fullWidth={true}
              className='sendSurveyButton'
            >
              {i18n.t('send')}
            </Button>
          </Col>
        </Row>
        <Dialog
          open={this.state.openError}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{this.state.errorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {this.state.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    );
  }

  _renderSurveyDetails() {
    if (this.state.surveyDetails !== null) {
      return (
        <Dialog
          open={this.state.openConsult}
          onClose={this._handleCloseSurveyConsultation}
          maxWidth='md'
          fullWidth={true}
        >
          <h3 className={'consultSurveyTitle'}>{i18n.t('surveyDetails')}</h3>
          <DialogContent>
            <form>
              <SurveyDetails survey={this.state.surveyDetails} userCityIds={this.state.userCityIds} />
            </form>
          </DialogContent>
          <DialogActions className='editSurveyButtonsContainer'>
            <Button
              variant='outlined'
              onClick={async () => await this._handleCloseSurveyConsultation()}
              className='consultSurveyCloseButton'
            >
              {'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  _renderSurveyModicationForm() {
    if (this.state.surveyEdited !== null) {
      return (
        <Dialog
          open={this.state.openModification}
          onClose={this._handleCloseAlertModification}
          disableBackdropClick={true}
          maxWidth='md'
          fullWidth={true}
        >
          <h3 className={'editSurveyTitle'}>{i18n.t('surveyEdit')}</h3>
          <DialogContent>
            <form>
              <Row>
                <Col sm='12'>
                  <TextField
                    name='titleEdited'
                    placeholder={i18n.t('addQuestion')}
                    fullWidth={true}
                    label={'Question'}
                    helperText={i18n.t('thisFieldIsRequired')}
                    multiline={true}
                    onChange={async (event) => {
                      this.state.surveyEdited.title = event.target.value;
                      this.forceUpdate();
                    }}
                    value={this.state.surveyEdited.title}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <TextField
                    name='contentEdited'
                    placeholder={i18n.t('addDescription')}
                    fullWidth={true}
                    label='Description'
                    helperText={i18n.t('thisFieldIsRequired')}
                    multiline={true}
                    onChange={async (event) => {
                      this.state.surveyEdited.content = event.target.value;
                      this.forceUpdate();
                    }}
                    value={this.state.surveyEdited.content}
                  />
                  <br />
                </Col>
              </Row>
              {
                this.state.surveyEdited.choices.map(
                  (input, index) =>
                    <Row className='marginTop' key={index}>
                      <Col sm='6'>
                        <TextField
                          key={input}
                          fullWidth={true}
                          name={index.toString()}
                          placeholder={i18n.t('enterAChoice')}
                          label={i18n.t('choice')}
                          helperText={i18n.t('thisFieldIsRequired')}
                          maxLength='255'
                          multiline={true}
                          onChange={this._handleDynamicEditingInputChange}
                          value={this.state.surveyEdited.choices[index].title}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Col>
                    </Row>
                )
              }
              <Row className='editSurveyStatusContainer'>
                <Button
                  variant='contained'
                  onClick={async () => await this._modifySurveyStatus(this.state.surveyEdited)}
                  className='editSurveyStatusButton'
                >
                  {this.state.surveyEdited.active ? i18n.t('closeTheSurvey') : i18n.t('openTheSurvey')}
                </Button>
              </Row>
            </form>
          </DialogContent>
          <DialogActions className='editSurveyButtonsContainer'>
            <Button
              variant='outlined'
              onClick={async () => await this._cancelEditing(alert)}
              className='editSurveyCancelButton'
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              variant='outlined'
              onClick={async () => await this._modifySurvey(this.state.surveyEdited)}
              className='editSurveyConfirmButton'
            >
              {i18n.t('edit')}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }

  _renderSurveysDatatables() {
    const lowerCaseQuery = deburr(this.state.userQuery.toLowerCase());

    return (
      <React.Fragment>
        <Row className='marginBottom'>
          <Col sm='6'>
            <TextField
              placeholder={i18n.t('enterTheSurveyTitle')}
              fullWidth={true}
              label={i18n.t('searchForASurvey')}
              maxLength='255'
              onChange={this._onUserQueryChange}
              value={this.state.userQuery}
            />
          </Col>
          {
            this.state.userCities.length > 1 &&
            <Col sm='6'>
              <FormControl fullWidth={true}>
                <InputLabel>{i18n.t('carecityCities')}</InputLabel>
                <Select
                  value={this.state.cityToFetch}
                  onChange={async (_event) => await this._filterSurveys(_event.target.value)}
                >
                  <MenuItem key={0} value={0}>{i18n.t('allMyCities')}</MenuItem>;
                  {
                    this.state.userCities.map(function(city) {
                      return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
            </Col>
          }
        </Row>
        <Table
          sortDirection={this.state.sortDirection}
          columnToSort={this.state.columnToSort}
          handleConsult={this._toggleConsult}
          handleEdit={this._toggleEditing}
          handleRemove={this._handleRemove}
          handleSort={this._handleSort}
          data={orderBy(
            lowerCaseQuery
              ? this.state.surveys.filter(x =>
                deburr(x['title'])
                  .toLowerCase()
                  .includes(lowerCaseQuery)
              )
              : this.state.surveys,
            this.state.columnToSort,
            this.state.sortDirection)}
          header={[
            {
              name: i18n.t('creationDate'),
              prop: 'dateFormatted'
            },
            {
              name: i18n.t('title'),
              prop: 'title'
            }
          ]}
        />
      </React.Fragment>
    );
  }

  render() {
    const accessLevel = User.getInstance().getUser().access_level;
    if (accessLevel === 'publisher_communications' || accessLevel === 'admin' || accessLevel === 'super_admin') {
      return (
        <Container className='Survey'>
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('sendASurvey')}</h2>
          </Row>
          {this._renderSurveyForm()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('previousSurveys')}</h2>
          </Row>
          {this._renderSurveysDatatables()}
          {this._renderSurveyDetails()}
          {this._renderSurveyModicationForm()}
          {this._renderDialogs()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Survey;

import HttpMessage, { ROOT_URL } from './HttpMessage';

import User from '../data/User';
import { ProblemCategories } from '../utils/Enums';
import i18n from '../i18n';

class CitiesService {

  static async fetchCities() {
    const message = {
      route: `${ROOT_URL}/cities?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const citiesList = [];
    if (data.status === 'success') {
      data.cities.forEach(val => {
        const obj = {};
        obj.cityId = val.id;
        obj.name = val.name;
        obj.state = val.state;
        obj.country = val.country;
        obj.parkingEnabled = val.parking_enabled;
        obj.smsEnabled = val.sms_enabled;
        obj.voiceEnabled = val.voice_enabled;
        obj.facebookEnabled = val.facebook_page_id !== '' ? true : false;
        obj.pictureUrl = val.picture_url;

        citiesList.push(obj);
      });
    }

    return citiesList;
  }

  static async fetchOtherCities() {
    const message = {
      route: `${ROOT_URL}/other_cities?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const citiesList = [];
    if (data.status === 'success') {
      data.otherCities.forEach(val => {
        const obj = {};
        obj.id = val.city;
        obj.city = val.city;
        obj.count = val.count;

        citiesList.push(obj);
      });
    }

    return citiesList;
  }

  static async fetchCity(cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    const city = data.city;
    city.report_categories = JSON.parse(city.report_categories);
    city.report_reminder_level = JSON.parse(city.report_reminder_level);

    Object.keys(ProblemCategories).forEach((category) => {
      if (!city.report_categories[category]) {
        city.report_categories[category] = { enabled: true, message: i18n.t('defaultReportResponse') };
      }
    });

    return city;
  }

  static async addCity(city) {
    const message = {
      route: `${ROOT_URL}/cities?token=${User.getInstance().getUser().token}`,
      method: 'POST',
      body: JSON.stringify(city),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async deleteCity(id) {
    const message = {
      route: `${ROOT_URL}/cities/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async deleteOtherCity(city) {
    const message = {
      route: `${ROOT_URL}/other_cities/${city}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async fetchGeographicCities() {
    const message = {
      route: `${ROOT_URL}/geographic_cities?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const geographicCitiesList = [];
    if (data.status === 'success') {
      data.cities.forEach(val => {
        const obj = {};
        obj.id = val.id;
        obj.cityId = val.city_id;
        obj.name = val.name;
        obj.state = val.state;
        obj.country = val.country;

        geographicCitiesList.push(obj);
      });
    }

    return geographicCitiesList;
  }

  static async deleteGeographicCity(id) {
    const message = {
      route: `${ROOT_URL}/geographic_cities/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyGeographicCity(currentCity) {
    const message = {
      route: `${ROOT_URL}/geographic_cities/${currentCity.id}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(currentCity),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async updateReportCategories(reportCategories, cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(reportCategories),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async updateReminderLevels(reminderLevels, cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(reminderLevels),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyInfoEmail(infoEmail, cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ info_email: infoEmail }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyCommunicationsEmail(communicationsEmail, cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ communications_email: communicationsEmail }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyProblemStatusEmail(problemStatusEmail, cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ problem_status_email: problemStatusEmail }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyCityFacebookPage(facebookPageId, facebookPageAccessToken, cityId) {
    const message = {
      route: `${ROOT_URL}/cities/${cityId}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ facebook_page_id: facebookPageId, facebook_page_access_token: facebookPageAccessToken }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyCity(city, imageFile) {
    const message = {
      route: `${ROOT_URL}/cities/${city.cityId}?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ sms_enabled: city.smsEnabledEdited, voice_enabled: city.voiceEnabledEdited, picture: city.picture }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    if (data.status === 'success') {
      if (data.signedURL) {
        HttpMessage.postImage(imageFile, data.signedURL);
      }

      city.pictureUrl = data.city.picture_url;
    }

    return data.status === 'success';
  }
}

export default CitiesService;

import React from 'react';
import AsyncComponent from '../../components/AsyncComponent';
import Button from '@material-ui/core/Button';
import { Container, Col, Row } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FacebookLogin from 'react-facebook-login';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

import AccessForbidden from '../../components/AccessForbidden';
import CitiesService from '../../services/CitiesService';
import FacebookService from '../../services/FacebookService';
import User from '../../data/User';
import './styles.scss';
import i18n from '../../i18n';

class Redirections extends AsyncComponent {
  constructor(props) {
    super(props);
    this.state = {
      communicationsEmail: '',
      facebookPageId: '',
      facebookPageIdSelected: '',
      facebookPages: null,
      infoEmail: '',
      open: false,
      openInfoEmailModificationError: false,
      problemStatusEmail: '',
      userCities: [],
    };

    this._handleClose = this._handleClose.bind(this);
    this._handleCancelFacebookPage = this._handleCancelFacebookPage.bind(this);
    this._handleConfirmFacebookPage = this._handleConfirmFacebookPage.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
    this._modifyCommunicationsEmail = this._modifyCommunicationsEmail.bind(this);
    this._modifyProblemStatusEmail = this._modifyProblemStatusEmail.bind(this);
    this._modifyInfoEmail = this._modifyInfoEmail.bind(this);
    this._getFacebookPages = this._getFacebookPages.bind(this);
  }

  async componentDidMount() {
    await this._getUserCities();
    await this._fetchCity(this.state.cityId);
  }

  async _fetchCity(cityId) {
    await this.setStateAsync({ cityId });
    const city = await CitiesService.fetchCity(cityId);
    const cityCategories = city.report_categories;
    const infoEmail = city.info_email;
    const communicationsEmail = city.communications_email;
    const problemStatusEmail = city.problem_status_email;
    const facebookPageId = city.facebook_page_id;

    await this.setStateAsync({ cityCategories, infoEmail, communicationsEmail, facebookPageId, problemStatusEmail });
  }

  async _getUserCities() {
    const userCities = (await User.getInstance().getUserCities()).filter(city => city.cityId !== 'null');
    await this.setStateAsync({ userCities, cityId: userCities[0].cityId });
  }

  async _handleChangeFacebookPage(event) {
    await this.setStateAsync({ facebookPageIdSelected: event.target.value });
  }

  async _handleClose() {
    await this.setStateAsync({
      open: false,
      openInfoEmailModificationError: false,
    });
  }

  async _handleCancelFacebookPage() {
    await this.setStateAsync({ facebookPages: null, facebookPageIdSelected: '' });
  }

  async _handleConfirmFacebookPage() {
    const facebookPageId = this.state.facebookPageIdSelected;
    const facebookPage = this.state.facebookPages.find(page => page.id === this.state.facebookPageIdSelected);
    const facebookPageAccessToken = facebookPage ? facebookPage.access_token : '';
    const success = await CitiesService.modifyCityFacebookPage(facebookPageId, facebookPageAccessToken, this.state.cityId);

    if (success) {
      await this.setStateAsync({ facebookPages: null, facebookPageIdSelected: '', facebookPageId });
      this._notify(i18n.t('successfullyAssociated'));
    }
    else {
      this._notifyError(i18n.t('errorDuringAssociation'));
    }
  }

  async _handleInputChange(event) {
    const { name, value } = event.target;
    await this.setStateAsync({
      [name]: value
    });
  }

  async _handleModifyInfoEmailError() {
    await this.setStateAsync({ openInfoEmailModificationError: true });
  }

  async _modifyInfoEmail() {
    if (this.state.infoEmail === '' || validator.isEmail(this.state.infoEmail.trim())) {
      const success = await CitiesService.modifyInfoEmail(this.state.infoEmail.trim(), this.state.cityId);
      success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
    }
    else {
      await this._handleModifyInfoEmailError();
    }
  }

  async _modifyCommunicationsEmail() {
    if (this.state.communicationsEmail === '' || validator.isEmail(this.state.communicationsEmail.trim())) {
      const success = await CitiesService.modifyCommunicationsEmail(this.state.communicationsEmail.trim(), this.state.cityId);
      success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
    }
    else {
      await this._handleModifyInfoEmailError();
    }
  }

  async _modifyProblemStatusEmail() {
    if (this.state.problemStatusEmail === '' || validator.isEmail(this.state.problemStatusEmail.trim())) {
      const success = await CitiesService.modifyProblemStatusEmail(this.state.problemStatusEmail.trim(), this.state.cityId);
      success ? this._notify(i18n.t('successfullyModified')) : this._notifyError(i18n.t('errorDuringModification'));
    }
    else {
      await this._handleModifyInfoEmailError();
    }
  }

  async _getFacebookPages(facebookUser) {
    const facebookPages = await FacebookService.fetchFacebookPages(facebookUser.id, facebookUser.accessToken);
    if (facebookPages) {
      await this.setStateAsync({ facebookPages });
    }
  }

  _notify(message) {
    toast.success(message);
  }

  _notifyError(errorMessage) {
    toast.error(errorMessage);
  }

  _renderCitiesSelect() {
    if (this.state.userCities.length > 1) {
      return (
        <Row className='citiesSelectContainer'>
          <Col sm='6'>
            <FormControl fullWidth={true}>
              <InputLabel>{i18n.t('selectACityToConsult')}</InputLabel>
              <Select
                value={this.state.cityId}
                onChange={async (_event) => await this._fetchCity(_event.target.value)}
              >
                {
                  this.state.userCities.map(function(city) {
                    return <MenuItem key={city.cityId} value={city.cityId}>{city.name}</MenuItem>;
                  })
                }
              </Select>
            </FormControl>
          </Col>
        </Row>
      );
    }
  }

  _renderEmailsRedirectionSection() {
    return (
      <React.Fragment>
        <Row>
          <Col sm='10'>
            <TextField
              name='communicationsEmail'
              placeholder={i18n.t('enterTheCitizenCommunicationsEmail')}
              fullWidth={true}
              label={i18n.t('citizenCommunicationsEmail')}
              maxLength='255'
              onChange={this._handleInputChange}
              value={this.state.communicationsEmail}
            />
            <br />
          </Col>
          <Col sm='2'>
            <Button
              fullWidth={true}
              variant='contained'
              onClick={this._modifyCommunicationsEmail}
              className='modifyEmailButton'
            >
              {i18n.t('edit')}
            </Button>
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm='10'>
            <TextField
              name='infoEmail'
              placeholder={i18n.t('enterTheEmailAddressToSendNewReportsTo')}
              fullWidth={true}
              label={i18n.t('redirectionEmailForNewReports')}
              maxLength='255'
              onChange={this._handleInputChange}
              value={this.state.infoEmail}
            />
            <br />
          </Col>
          <Col sm='2'>
            <Button
              fullWidth={true}
              variant='contained'
              onClick={this._modifyInfoEmail}
              className='modifyEmailButton'
            >
              {i18n.t('edit')}
            </Button>
            <br />
          </Col>
        </Row>
        <Row>
          <Col sm='10'>
            <TextField
              name='problemStatusEmail'
              placeholder={i18n.t('enterTheEmailAddressToSendNotificationsOfReportStatusChange')}
              fullWidth={true}
              label={i18n.t('redirectionEmailForChangesInStatusOfReports')}
              maxLength='255'
              onChange={this._handleInputChange}
              value={this.state.problemStatusEmail}
            />
            <br />
          </Col>
          <Col sm='2'>
            <Button
              fullWidth={true}
              variant='contained'
              onClick={this._modifyProblemStatusEmail}
              className='modifyEmailButton'
            >
              {i18n.t('edit')}
            </Button>
            <br />
          </Col>
        </Row>
        <Dialog
          open={this.state.openInfoEmailModificationError}
          onClose={this._handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{i18n.t('errorDuringModification')}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {i18n.t('youMustEnterAValidEmailAddressOrLeaveTheFieldBlank')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleClose} color='primary'>
              {'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  _renderFacebookRedirectionSection() {
    return (
      <Row>
        <Col className={'facebookredirectionLabelContainer'} sm='10'>
          {this.state.facebookPageId !== '' ?
            <h5 className={'facebookredirectionLabel'}>{i18n.t('yourCarecityCityIsAssociatedWithTheFacebookPage')}: <Link href={'https://www.facebook.com/' + this.state.facebookPageId}>{this.state.facebookPageId}</Link></h5> :
            <h5 className={'facebookredirectionLabel'}>{i18n.t('yourCarecityCityIsNotAssociatedWithAFacebookPage')}</h5>
          }
        </Col>
        <Col sm='2'>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
            callback={this._getFacebookPages}
            cssClass='facebookButton MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-fullWidth'
            scope='pages_show_list,pages_manage_posts,pages_read_engagement'
            textButton={this.state.facebookPageId !== '' ? i18n.t('edit') : i18n.t('associate')} />
          <br />
        </Col>
        {this._renderFacebookConfirmationDialog()}
      </Row>
    );
  }

  _renderFacebookConfirmationDialog() {
    return (
      <Dialog
        open={this.state.facebookPages !== null}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{i18n.t('confirmFacebookPageAssociaton')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {i18n.t('youMustChooseASingleFacebookPageToAssociateWithYourCarecityCity')}
          </DialogContentText>
          <FormControl component='fieldset'>
            <RadioGroup aria-label='facebookPage' name='facebookPage' value={this.state.facebookPageIdSelected} onChange={async (event) => await this._handleChangeFacebookPage(event)}>
              {this.state.facebookPages && this.state.facebookPages.map(page => {
                return (
                  <FormControlLabel key={page.id} value={page.id} control={<Radio color='primary' />} label={page.name} />
                );
              })
              }
              <FormControlLabel value={''} control={<Radio color='primary' />} label={i18n.t('noFacebookPage')} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._handleCancelFacebookPage} color='primary'>
            {i18n.t('cancel')}
          </Button>
          <Button onClick={this._handleConfirmFacebookPage} color='primary'>
            {i18n.t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    if (User.getInstance().getUser().access_level === 'super_admin' || User.getInstance().getUser().access_level === 'admin') {
      return (
        <Container className='Redirections'>
          {this._renderCitiesSelect()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('redirectionEmailsForTheCity')}</h2>
          </Row>
          {this._renderEmailsRedirectionSection()}
          <Row>
            <h2 className={'pageTitle'}>{i18n.t('facebookAlertRedirectionPage')}</h2>
          </Row>
          {this._renderFacebookRedirectionSection()}
          <ToastContainer />
        </Container>
      );
    }
    else {
      return (
        <AccessForbidden />
      );
    }
  }
}

export default Redirections;

/* eslint-disable no-template-curly-in-string */
const fr = {
  'translation': {
    '403Message': 'Nous sommes désolés, mais la page que vous recherchez n\'est pas autorisée pour votre utilisateur.',
    '403Title': '403 - Votre utilisateur n\'est pas autorisé',
    '404Message': 'Nous sommes désolés, mais la page que vous recherchez n\'existe pas.',
    '404Title': '404 - Page non trouvée',
    'accessLevel': 'Niveau d\'accès',
    'account': 'Compte',
    'active': 'Actif',
    'activeCities': 'Villes actives',
    'activeUsers': 'Utilisateurs actifs',
    'add': 'Ajouter',
    'addACarecityCity': 'Ajouter une ville',
    'addAChoice': 'Ajouter un choix',
    'addAGroup': 'Ajouter un groupe',
    'addANightParkingZone': 'Ajouter une zone de stationnement de nuit',
    'addAnImage': 'Ajouter une image',
    'addComment': 'Ajoutez un commentaire',
    'addContent': 'Ajoutez un contenu',
    'addCountry': 'Ajoutez un pays',
    'addDaysSinceLastUpdate': 'Ajoutez le nombre de jour depuis la dernière modification',
    'addDescription': 'Ajoutez une description',
    'addEmail': 'Ajoutez un courriel',
    'addHeaderMessage': 'Ajoutez un message d\'en-tête',
    'addInactivityMessage': 'Ajoutez un message d\'inactivité',
    'additionalInformationAboutTheParkingArchive': 'Informations supplémentaires sur l\'archive de stationnement',
    'additionalInformationEdit': 'Modification des informations supplémentaires',
    'addMessage': 'Ajoutez un message',
    'addName': 'Ajoutez un nom',
    'addPriority': 'Ajoutez une priorité',
    'addQuestion': 'Ajoutez une question',
    'addStateOrProvince': 'Ajoutez un état ou une province',
    'addTitle': 'Ajoutez un titre',
    'addUrlLink': 'Ajoutez un lien URL',
    'administrator': 'Administrateur',
    'alert': 'Alerte',
    'alertDetailsHeader': 'Détails de l\'alerte',
    'alertDuplicated': 'Alerte dupliquée',
    'alertEdit': 'Modification de l\'alerte',
    'alertHeader': 'Alertes',
    'alertProgramming': 'Programmation de l\'alerte',
    'alertStopTime': 'Heure d\'arrêt de l\'alerte',
    'alertTypes': 'Types d\'alerte',
    'allMyCities': 'Toutes mes villes',
    'and': 'et',
    'anSmsOrAVoiceCallCannotBeChangedOnceSent': 'Un SMS ou un appel vocal ne peut être modifié une fois envoyé.',
    'approvalsByReport': 'Approbations par signalement',
    'areYouSureYouWantToDeleteThis': 'Êtes-vous sûr de vouloir supprimer ceci?',
    'associate': 'Associer',
    'at': 'à',
    'authorized': 'Autorisé',
    'authorizedReports': 'Signalements autorisés',
    'automatedCalls': 'Appels automatisés',
    'averageNumberOfAppovalsBeforeAReportIsAccepted': 'Nombre moyen d\'approbations avant l\'acceptation d\'un signalement',
    'averageNumberOfRepairsPerWeek': 'Nombre moyen de réparations par semaine',
    'averageTimeBetweenCreationAndClosingOfAReport': 'Temps moyen entre la création et la fermeture d\'un signalement',
    'billing': 'Facturation',
    'block': 'Bloquer',
    'blockedUser': 'Suite à plusieurs plaintes, Carecity vous a retiré le droit de rapporter des signalements. Vous pouvez toujours consulter les signalements autour de vous et recevoir les alertes des communautés que vous suivez.',
    'blockedUserTitle': 'Droits révoqués',
    'breakdownOfReportingStatus': 'Répartition des statuts des signalements',
    'breakdownOftypesOfReports': 'Répartition des types de signalements',
    'byWhatMeansOfCommunicationDoYouWishToBeReached': 'Par quel moyen de communication désirez-vous être rejoint?',
    'callMinutes': 'Minutes d\'appel',
    'callsAndSMS': 'Appels et SMS',
    'cancel': 'Annuler',
    'cannotEditThisAlert': 'Impossible de modifier cette alerte.',
    'cannotEditThisUser': 'Impossible de modifier cet utilisateur.',
    'canReport': 'Peut rapporter',
    'carecityAlertRegistrationForm': 'Formulaire d\'inscription aux alertes Carecity',
    'carecityCities': 'Villes Carecity',
    'carouselAlertFeed': 'Consultez les alertes des communautés auxquelles vous êtes abonnées',
    'carouselChooseProblem': 'Choisissez un type de signalement à rapporter à votre communauté',
    'carouselConfirmProblem': 'Confirmez l\'emplacement du signalement',
    'carouselProfil': 'Gérer les communautés que vous suivez et vos préférences dans votre profil',
    'categories': 'Catégories',
    'category': 'Catégorie',
    'change': 'Changement',
    'changeAuthor': 'Auteur du changement',
    'changeMyPassword': 'Modifier mon mot de passe',
    'choice': 'Choix',
    'chooseACarecityCity': 'Choisissez une ville Carecity',
    'chooseAnswer': 'Vous devez choisir une réponse',
    'chooseCategory': 'Choisissez une catégorie',
    'chooseFromLibrary': 'Choisir à partir de la librairie',
    'chooseTheCitiesConcernedByThisGroup': 'Choisissez les villes concernées par ce groupe',
    'cities': 'Villes',
    'citiesSuggestedByCitizens': 'Villes suggérées par les citoyens',
    'citizenCallNumber': 'Numéro d\'appel vocal du citoyen',
    'citizenCommunicationsEmail': 'Courriel de communications avec les citoyens',
    'citizenEditor': 'Éditeur citoyens',
    'citizenEmail': 'Email du citoyen',
    'citizens': 'Citoyens',
    'citizenSmsNumber': 'Numéro SMS du citoyen',
    'city': 'Ville',
    'cityComment': 'Commentaire de la ville',
    'cityInformations': 'Informations de la ville',
    'cleanliness': 'Propreté',
    'closeTheSurvey': 'Fermer le sondage',
    'communicationChannels': 'Canaux de communication',
    'communicationsEditor': 'Éditeur communications',
    'confirm': 'Confirmer',
    'confirmationOfAlertDeliveryViaSms': 'Confirmation de l\'envoie de l\'alerte par SMS',
    'confirmationOfBlock': 'Confirmation du blocage',
    'confirmationOfDeletion': 'Confirmation de la suppression',
    'confirmFacebookPageAssociaton': 'Confirmez l\'association de votre page Facebook',
    'connect': 'Se connecter',
    'connectToAccessService': 'Connecte-toi pour accéder au service',
    'connectWithApple': 'Se connecter avec Apple',
    'connectWithEmail': 'Se connecter avec courriel',
    'connectWithFacebook': 'Se connecter avec Facebook',
    'connectWithGoogle': 'Se connecter avec Google',
    'consult': 'Consulter',
    'contact': 'Contact',
    'contactCall': 'Contact téléphonique',
    'contactCityText': 'Pour signaler toute requête, nous vous invitons à communiquer avec nous.',
    'contactCityTitle': 'Contacter',
    'contactInformationsTitle': 'Si vous désirez être contacté concernant vos signalements, renseignez au moins l\'un des champs ci-dessous',
    'contactPlaceholder': 'Comment peut-on vous aider?',
    'contactSms': 'Contact SMS',
    'content': 'Contenu',
    'creationDate': 'Date de création',
    'createZone': 'Créer la zone',
    'day': 'Jour',
    'days': 'Jours',
    'deactivate': 'Désactiver mon compte',
    'deactivateMessage': 'Êtes-vous certain de vouloir désactiver votre compte Carecity?',
    'defaultReportResponse': 'Merci pour votre signalement!',
    'deleteZone': 'Supprimer la zone',
    'deleteImage': 'Supprimer l\'image',
    'deliveryStatus': 'Statut de livraison',
    'delivered': 'Livré',
    'descriptionOfTheCitizen': 'Description du citoyen',
    'dispatchDate': 'Date d\'envoi',
    'dispatchTime': 'Heure d\'envoi',
    'done': 'Terminé',
    'duplicate': 'Dupliquer',
    'edit': 'Modifier',
    'editTheImage': 'Modifier l\'image',
    'email': 'Courriel',
    'endDateOfRecurrence': 'Date de fin de la récurrence',
    'english': 'Anglais',
    'enterAChoice': 'Entrez un choix',
    'enterTheAlertTitle': 'Entrez le titre de l\'alerte',
    'enterTheCitizenCommunicationsEmail': 'Entrez le courriel de communications avec les citoyens',
    'enterTheCityName': 'Entrez le nom de la ville',
    'enterTheCountry': 'Entrez le pays',
    'enterTheDate': 'Entrez la date',
    'enterTheEmailAddressToSendNewReportsTo': 'Entrez le courriel auquel envoyer les nouveaux signalements',
    'enterTheEmailAddressToSendNotificationsOfReportStatusChange': 'Entrez le courriel auquel envoyer les notifications de changement de statut des signalements',
    'enterTheFullName': 'Entrez le nom complet',
    'enterTheStateOrProvince': 'Entrez l\'état ou la province',
    'enterTheSurveyTitle': 'Entrez le titre du sondage',
    'enterTheUserEmail': 'Entrez le courriel de l\'utilisateur',
    'enterTheUserName': 'Entrez le nom de l\'utilisateur',
    'enterTheUserPhoneNumber': 'Entrez le numéro de téléphone de l\'utilisateur',
    'enterYourAddress': 'Entrez votre adresse',
    'enterYourCurrentPassword': 'Entrez votre mot de passe actuel',
    'enterYourEmail': 'Entrez votre courriel',
    'enterYourNewPassword': 'Entrez votre nouveau mot de passe',
    'enterYourPassword': 'Entrez votre mot de passe',
    'errorDuringActivation': 'Erreur lors de l\'activation',
    'errorDuringAssociation': 'Erreur lors de l\'association',
    'errorDuringBlocking': 'Erreur lors du blocage',
    'errorDuringCreation': 'Erreur lors de la création',
    'errorDuringCreatorReport': 'Erreur lors du rapport du créateur',
    'errorDuringDeactivation': 'Erreur lors de la désactivation',
    'errorDuringDeletion': 'Erreur lors de la suppression',
    'errorDuringModification': 'Erreur lors de la modification',
    'errorDuringRegistration': 'Erreur lors de l\'inscription',
    'errorDuringRegistrationConfirmation': 'Erreur lors de la confirmation d\'inscription sur le serveur Carecity.',
    'errorEmail': 'Veuillez entrer une adresse courriel valide.',
    'errorEmptyEmailPassword': 'Veuillez compléter tous les champs.',
    'errorNewPassword': 'Les mots de passe ne correspondent pas.',
    'errorPasswordStrength': 'Votre mot de passe doit contenir au moins 8 caractères, dont au moins une lettre majuscule, une lettre minuscule, un chiffre et un symbole.',
    'errorPhoneNumber': 'Veuillez entrer un numéro de téléphone valide.',
    'errorSignIn': 'Votre courriel ou votre mot de passe est invalide.',
    'errorUserCreation': 'Le compte utilisateur n\'a pas pu être créé. Il se peut qu\'il existe déjà.',
    'expectedDispatchOn': 'Envoi prévue le',
    'facebookAlertRedirectionPage': 'Page Facebook de redirection des alertes',
    'failed': 'Échec / Désinscription',
    'feedback': 'Commentaires',
    'feedbackText': 'Décrivez birèvement ce que vous aimez ou ce qui pourrait être amélioré.',
    'feedbackTitle': 'Avis global',
    'filters': 'Filtres',
    'flood': 'Inondation',
    'followedCities': 'Communautés suivies',
    'forgotPassword': 'Mot de passe oublié?',
    'french': 'Français',
    'from': 'de',
    'fullName': 'Nom complet',
    'goToSettings': 'Aller aux paramètres',
    'graffiti': 'Graffiti',
    'groupManagement': 'Gestion des groupes',
    'groupMembers': 'Membres du groupe',
    'groups': 'Groupes',
    'gst': 'TPS',
    'heatMap': 'Carte de chaleur',
    'highRecallLevel': 'Niveau de rappel élevé',
    'historyOfChanges': 'Historique des changements',
    'id': 'Identifiant',
    'imageLoadError': 'Impossible de charger l\'image',
    'imageUrlLink': 'Lien URL de l\'image',
    'inactive': 'Inactif',
    'inactivityMessage': 'Message d\'inactivité',
    'incorrectEmailOrPasswordWarning': 'Le courriel ou mot de passe est incorrecte. Si vous ne vous souvenez pas de votre mot de passe, appuyez sur \'Mot de passe oublié\'',
    'informationCurrentlyAvailableToCitizens': 'Informations actuellement disponibles aux citoyens',
    'informationDetailsHeader': 'Détails de l\'information',
    'informationOnTheReport': 'Informations sur le signalement',
    'invite': 'Inviter',
    'isThisTheSameProblemYouAreReporting': 'Est-ce le même signalement que vous reportez',
    'language': 'Language',
    'lastCommentFromTheMunicipality': 'Dernier commentaire de la municipalité',
    'lastEditedXDaysAgo': 'Dernière modification il y a {{days}} jours',
    'loading': 'Chargement',
    'login': 'Connexion',
    'logins': 'Connexions',
    'logout': 'Déconnexion',
    'lowRecallLevel': 'Niveaux de rappel bas',
    'managementOfGeographicCities': 'Gestion des villes géographiques',
    'managementOfReportingCategories': 'Gestion des catégories de signalement',
    'manageUsersOf': 'Gérer les utilisateurs de',
    'markers': 'Marqueurs',
    'messageOfAcknowledgmentOfReceiptOrNonProcessing': 'Message d\'accusé de réception ou de non traitement.',
    'mobileUsers': 'Utilisateurs mobiles',
    'moderateRecallLevel': 'Niveau de rappel modéré',
    'month': 'Mois',
    'moreDetails': 'Plus de détails',
    'municipalUsers': 'Utilisateurs municipaux',
    'myAddresses': 'Mes adresses',
    'myCities': 'Mes communautés',
    'myCity': 'Ma communauté',
    'myFavoriteReports': 'Mes fignalements favoris',
    'myFavoriteReportsHeader': 'Signalements favoris',
    'name': 'Nom',
    'nameOfTheCity': 'Nom de la ville',
    'new': 'Nouveau',
    'newPassword': 'Nouveau mot de passe',
    'newPasswordAgain': 'Confirmer le nouveau mot de passe',
    'nightParking': 'Stationnement de nuit',
    'nightParkingHistory': 'Historique des stationnements de nuit',
    'no': 'Non',
    'noAlerts': 'Aucune alerte à afficher',
    'noCity': 'Aucune communauté à afficher',
    'noCityFollowed': 'Vous ne suivez pas de communautés',
    'noConnection': 'Aucune connexion internet',
    'noConnectionContent': 'S\'il vous plaît, vérifiez la connexion Internet sur votre téléphone intelligent pour utiliser l\'application',
    'noFacebookPage': 'Aucune page Facebook',
    'noFavoriteReport': 'Aucun signalement ajouté au favoris',
    'noGroup': 'Aucun groupe',
    'noHistory': 'Aucun historique',
    'noImage': 'Vous devez ajouter une photo à votre signalement',
    'noInformation': 'Aucune information',
    'noInformations': 'Aucune information à afficher',
    'noMatches': 'Aucune correspondance',
    'noNightParking': 'Aucun avis de stationnement',
    'noParkingZone': 'Aucune zone de stationnement',
    'noProblemCityComment': 'Aucun commentaire fourni',
    'noProblemDescription': 'Aucune description fournie',
    'noReport': 'Aucun Signalement',
    'notAllowed': 'Interdit',
    'notifications': 'Notifications',
    'noUserInThisGroup': 'Aucun utilisateur dans ce groupe',
    'numberOfCarecityCitizens': 'Nombre de citoyens Carecity',
    'numberOfDaysSinceLastUpdate': 'Nombre de jour depuis la dernière modification',
    'numberOfDaysSinceTheLastModificationOfAReport': 'Nombre de jour depuis la dernière modification d\'un signalement',
    'numberOfRequests': 'Nombre de demandes',
    'numberOfSmsCharacters': 'Nombre de caractères SMS : {{smsLength}}',
    'numberOfSmsSegments': 'Nombre de segments SMS : {{smsSegments}}',
    'numberOfSubscriptionsToAutomatedCalls': 'Nombre d\'inscription aux appels automatisés',
    'numberOfSubscriptionsToSmsAlerts': 'Nombre d\'inscription aux alertes SMS',
    'oldPassword': 'Mot de passe actuel',
    'openTheSurvey': 'Ouvrir le sondage',
    'optional': 'La description est optionnelle',
    'other': 'Autre',
    'otherCity': 'Autre ville',
    'otherCityPlaceholder': 'Entrez la ville',
    'otherCityTitle': 'Entrez la ville où vous souhaiteriez avoir Carecity implanté',
    'parking': 'Stationnement',
    'password': 'Mot de passe',
    'passwordReset': 'Réinitialisation de votre mot de passe',
    'periodicity': 'Périodicité',
    'permissionDenied': 'Permission refusée',
    'permissionDeniedExplanation': 'Pour pouvoir prendre des photos avec votre appareil photo et choisir des images dans votre bibliothèque.',
    'phoneNumber': 'Numéro de téléphone',
    'photo': 'Photo',
    'planned': 'Planifié',
    'pleaseCompleteOrCancelTheChangesBeforeCreatingANewOne': 'Veuillez compléter la modification ou l\'annuler avant d\'en créer une nouvelle.',
    'pleaseFillInTheAddress': 'Veuillez renseigner l\'adresse.',
    'pleaseFillInTheCity': 'Veuillez renseigner la ville.',
    'pleaseFillInTheFullName': 'Veuillez renseigner le nom complet.',
    'pleaseFillInTheMeansOfReceivingAlerts': 'Veuillez renseigner le moyen de réception des alertes.',
    'pleaseFillInThePhoneNumber': 'Veuillez renseigner le numéro de téléphone avec le format suivant: +1 (123) 456-7891.',
    'pleaseUpdateMessage': 'Une nouvelle version de Carecity est maintenant disponible. Veuillez mettre à jour votre application pour continuer d\'utiliser Carecity.',
    'portal': 'Portail',
    'pothole': 'Nid-de-poule',
    'previousAlerts': 'Alertes antérieures',
    'previousSurveys': 'Sondages antérieurs',
    'priority': 'Priorité',
    'privacyPolicy': 'Politique de confidentialité',
    'profilHeader': 'Profil',
    'programmed': 'Programmée',
    'publicWorks': 'Travaux publics',
    'publicWorksEditor': 'Éditeur travaux publics',
    'pushNotification': 'Notification push',
    'qst': 'TVQ',
    'quantity': 'Quantité',
    'rate': 'Tarif',
    'receiveCityNotifications': 'Recevoir les alertes de cette communauté',
    'received': 'Reçu',
    'receiveProblemsPushNotifications': 'Recevoir les notifications push des signalements',
    'recurrence': 'Récurrence',
    'redirectionEmailForChangesInStatusOfReports': 'Courriel de redirection des changements de statut des signalements',
    'redirectionEmailForNewReports': 'Courriel de redirection des nouveaux signalements',
    'redirectionEmailsForTheCity': 'Courriels de redirection à la ville',
    'register': 'S\'inscrire',
    'registration': 'Inscription',
    'registrationSuccessfullyCompleted': 'Inscription terminée avec succès!',
    'remove': 'Supprimer',
    'repairsPerWeek': 'Réparations par semaine',
    'repeatability': 'Répétitivité',
    'report': 'Signalement',
    'reportButton': 'Signaler un problème',
    'reportCategories': 'Catégories de signalement',
    'reportCoordinates': 'Coordonnées du signalement',
    'reportCreatorToCarecity': 'Rapporter le créateur du signalement à Carecity',
    'reportDate': 'Date du signalement',
    'reportedBy': 'Rapporté par',
    'reportedProblem': 'Signalements rapportés',
    'reportRecallLevels': 'Niveaux de rappel des signalements',
    'reports': 'Signalements',
    'reportUrlLink': 'Lien URL Carecity du signalement',
    'retry': 'Réessayer',
    'save': 'Enregistrer',
    'scheduleTheAlert': 'Programmer l\'alerte',
    'searchForACity': 'Rechercher une ville',
    'searchForAnAlert': 'Rechercher une alerte',
    'searchForAnArchive': 'Rechercher une archive',
    'searchForAPhoneNumber': 'Rechercher un numéro de téléphone',
    'searchForASurvey': 'Rechercher un sondage',
    'searchForAUser': 'Rechercher un utilisateur',
    'selectABillingMonth': 'Sélectionnez un mois de facturation',
    'selectACityToConsult': 'Sélectionnez une ville à consulter',
    'selectAPhoto': 'Selectionner une photo',
    'selectCategories': 'Sélectionnez des catégories',
    'selected': 'Sélectionnés',
    'selectStatuses': 'Sélectionnez des statuts',
    'selectTheMinimalApproval': 'Sélectionnez le minimum d\'approbations',
    'send': 'Envoyer',
    'sendAnAlert': 'Envoyer une alerte',
    'sendASurvey': 'Envoyer un sondage',
    'sendTheAlertOnAssociatedFacebookPage': 'Envoyer l\'alerte sur la page Facebook associée',
    'sendTheAlertViaCarecityPushNotification': 'Envoyer l\'alerte par notification push Carecity',
    'sendTheAlertViaSms': 'Envoyer l\'alerte par SMS',
    'sendTheAlertViaVoiceCall': 'Envoyer l\'alerte par appel vocal',
    'sendTimeAndStopTimeCannotBeEmpty': 'L\'heure d\'envoi et l\'heure d\'arrêt ne peuvent pas être vides.',
    'sent': 'Envoyée',
    'sentToGroups': 'Envoyée au(x) groupe(s):',
    'services': 'Services',
    'settingsAbout': 'À propos',
    'settingsContactCarecity': 'Contacter Carecity',
    'settingsContactCity': 'Contacter ma communauté',
    'settingsHeader': 'Paramètres',
    'settingsNotification': 'Notifications',
    'settingsPreferences': 'Préférences',
    'settingsPrivacy': 'Politique de confidentialité',
    'settingsSupport': 'Support',
    'settingsTerms': 'Termes et conditions',
    'settingsUserInformations': 'Mes informations',
    'settingsWebsite': 'Site web',
    'signage': 'Signalisation',
    'signin': 'Connectez-vous',
    'signinError': 'Une erreur est survenue lors de la connexion à votre compte. Veuillez essayer à nouveau ou utiliser un autre mode de connexion.',
    'signup': 'Inscrivez-vous',
    'signupWithEmail': 'Vous débutez sur Carecity? ',
    'skip': 'Passer',
    'smsAlerts': 'Alertes SMS',
    'smsSent': 'SMS envoyés',
    'smsDeliveryStatusNotAvailable': 'Le statut de livraison des SMS n\'est pas disponible pour le moment.',
    'snowRemoval': 'Déneigement',
    'startDate': 'Date de début',
    'statistics': 'Statistiques',
    'status': 'Statut',
    'statuses': 'Statuts',
    'stopDate': 'Date de fin',
    'streetFurniture': 'Mobilier urbain',
    'streetLight': 'Lampadaire',
    'submit': 'Répondre',
    'subscriberAddress': 'Coordonnées du citoyen',
    'subscriptions': 'Abonnements',
    'subTotal': 'Sous-Total',
    'successfullyAssociated': 'Associé avec succès',
    'successfullyBlocked': 'Bloqué avec succès',
    'successfullyCreated': 'Créé avec succès',
    'successfullyModified': 'Modifié avec succès',
    'successfullyRemoved': 'Supprimé avec succès',
    'successfullyReported': 'Rapporté avec succès',
    'successfullyUnblocked': 'Débloqué avec succès',
    'successSignUp': 'Bienvenue sur Carecity! Vous recevrez un mot de passe à votre adresse courriel d\'ici quelques minutes.',
    'superAdministrator': 'Super Administrateur',
    'survey': 'Sondage',
    'surveyDetailHeader': 'Détail du sondage',
    'surveyDetails': 'Détails du sondage',
    'surveyEdit': 'Modification du sondage',
    'surveyResults': 'Résultats du sondage',
    'surveys': 'Sondages',
    'takeAPhoto': 'Prendre une photo',
    'targetedCities': 'Villes ciblées',
    'targetAlertTitle': 'Cibler l\'alerte',
    'targetedGroups': 'Groupes ciblées',
    'tellUsMoreAboutYourself': 'Parlez-nous de vous',
    'theEndOfTheFirstAlertCannotBeBeforeSendingTheFristAlert': 'L\'arrêt de la première alerte ne peut pas être avant l\'envoi de la première alerte.',
    'thePriorityMustBeANumber': 'La priorité doit être un chiffre.',
    'theSelectedFileIsNotInASupportedFormat': 'Le fichier sélectionné n\'est pas une dans un format supporté.',
    'thisFieldIsOptional': 'Ce champ est optionnel.',
    'thisFieldIsRequired': 'Ce champ est obligatoire.',
    'title': 'Titre',
    'to': 'à',
    'totalNumberOfCitizensSubscribedToTheCity': 'Nombre total de citoyens abonnés à la ville',
    'totalNumberOfReports': 'Nombre total de signalements',
    'transfer': 'Transférer',
    'treatedProblem': 'Signalements traités',
    'tree': 'Arbre',
    'undelivered': 'Non livré',
    'urlLink': 'Lien URL',
    'user': 'Utilisateur',
    'userCities': 'Villes de l\'utilisateur',
    'userEdit': 'Modification de l\'utilisateur',
    'userManagement': 'Gestion des utilisateurs',
    'validateRegistration': 'Valider l\'inscription',
    'voiceAlerts': 'Alertes vocales',
    'voiceCall': 'Appel vocal',
    'waterPressure': 'Bris d\'aqueduc',
    'week': 'Semaine',
    'whatIsYourCity': 'Quelle est votre communauté?',
    'whatIsYourCityPlaceholder': 'Choisissez votre communauté',
    'yes': 'Oui',
    'youAreAboutToBlockAUser': 'Vous vous apprêtez à bloquer un utilisateur.',
    'youAreAboutToUnblockAUser': 'Vous vous apprêtez à débloquer un utilisateur.',
    'youAreAlreadyEditingAnotherGroup': 'Vous modifiez déjà un autre groupe.',
    'youAreAlreadyEditingAnotherInformation': 'Vous modifiez déjà une autre information.',
    'youCannotDeleteYourUser': 'Vous ne pouvez pas supprimer votre utilisateur.',
    'youCannotHaveAnEmptyChoice': 'Vous ne pouvez pas avoir un choix vide.',
    'youDoNotHaveAccessToAllCitiesInThisAlert': 'Vous n\'avez pas accès à toutes les villes de cette alerte.',
    'youMustAdd2Choices': 'Vous devez obligatoirement ajouter au moins deux choix.',
    'youMustAddAContent': 'Vous devez obligatoirement ajouter un contenu.',
    'youMustAddAName': 'Vous devez obligatoirement ajouter un nom.',
    'youMustAddATitle': 'Vous devez obligatoirement ajouter un titre.',
    'youMustChooseASingleFacebookPageToAssociateWithYourCarecityCity': 'Vous devez obligatoirement choisir une seule page Facebook à associer à votre ville Carecity.',
    'youMustChooseAtLeastOneCity': 'Vous devez choisir au moins une ville.',
    'youMustChooseAtLeastOneCityOrGroup': 'Vous devez choisir au moins une ville ou un groupe.',
    'youMustChooseAtLeastOneSendingMethod': 'Vous devez choisir au moins un moyen d\'envoi (Push, SMS, Appel vocal ou Facebook)',
    'youMustEnterAnEndDataThatIsAfterTheStartDate': 'Vous devez obligatoirement entrer une date de fin qui est après la date de début.',
    'youMustEnterAValidEmailAddress': 'Vous devez obligatoirement entrer une adresse courriel valide.',
    'youMustEnterAValidEmailAddressOrLeaveTheFieldBlank': 'Vous devez obligatoirement entrer une adresse courriel valide ou laisser le champ vide.',
    'youMustEnterAZoneName': 'Vous devez obligatoirement entrer un nom à la zone.',
    'youMustEnterConsistentValuesForTheThresholds': 'Vous devez obligatoirement entrer des valeurs cohérentes pour les seuils.',
    'youMustEnterNumericValuesForTheThresholds': 'Vous devez obligatoirement entrer des valeurs numérique pour les seuils.',
    'youMustEnterTheNameStateAndCountryOfTheNewCarecityCity': 'Vous devez obligatoirement entrer le nom, l\'état et le pays de la nouvelle ville Carecity.',
    'youMustEnterYourOldPasswordAndYourNewPasswordTwice': 'Vous devez obligatoirement entrer votre ancien mot de passe et deux fois votre nouveau mot de passe.',
    'youMustHaveAContentWithLessThan1600CharactersForSmsAlerts': 'Vous devez obligatoirement avoir un contenu inférieur à 1600 caractères pour les alertes SMS. La longueur actuelle du SMS est de {{nbCharacters}} caractères.',
    'youMustHaveAContentWithLessThan4096CharactersForVoiceAlerts': 'Vous devez obligatoirement avoir un contenu inférieur à 4096 caractères pour les alertes vocales. La longueur actuelle du message est de {{nbCharacters}} caractères.',
    'yourAlertWillCostXPerCitizenSubscribedToSmsAlerts': 'Votre alerte va engendrer des coûts de {{smsPrice}}$ par citoyen abonné aux alertes SMS. Souhaitez-vous envoyer cette alerte par SMS?',
    'yourCarecityCityIsAssociatedWithTheFacebookPage': 'Votre ville Carecity est associée à la page Facebook',
    'yourCarecityCityIsNotAssociatedWithAFacebookPage': 'Votre ville Carecity n\'est pas associée à une page Facebook',
    'yourUrlLinkIsInvalid': 'Votre lien URL est invalide. Vous devez avoir l\'un des formats suivant: https://www.carecity.io ou http://www.carecity.io',
    'zoneHeaderMessage': 'Message d\'en-tête des zones',
    'zonesStatus': 'Statut des zones',
    'zoomInfo': 'Veuillez effectuer un zoom vers l\'avant pour confirmer l\'emplacement de votre signalement.',
  }
};

export default fr;

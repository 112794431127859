import HttpMessage, { ROOT_URL } from './HttpMessage';

import User from '../data/User';

class InvoiceService {

  static async fetchInvoice(cityId, month, year) {
    const message = {
      route: `${ROOT_URL}/subscribers/invoice/${cityId}?token=${User.getInstance().getUser().token}&month=${month}&year=${year}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let invoice = null;
    if (data.status === 'success') {
      invoice = {
        cityId: data.invoice.city_id,
        month: data.invoice.month,
        year: data.invoice.year,
        numberSmsSent: data.invoice.number_sms_sent,
        smsPrice: data.invoice.sms_price,
        amountSms: data.invoice.amount_sms,
        numberVoiceMinutes: data.invoice.number_voice_minutes,
        voicePrice: data.invoice.voice_price,
        amountVoice: data.invoice.amount_voice,
        amountWithoutTaxes: data.invoice.amount_without_taxes,
        tps: data.invoice.tps,
        tvq: data.invoice.tvq,
        totalAmount: data.invoice.total_amount,
        invoiceId: data.invoice.id,
      };
    }
    else {
      invoice = {
        cityId: cityId,
        month: month,
        year: year,
        numberSmsSent: 0,
        smsPrice: 0.0,
        amountSms: 0.0,
        numberVoiceMinutes: 0,
        voicePrice: 0.0,
        amountVoice: 0.0,
        amountWithoutTaxes: 0.0,
        tps: 0.0,
        tvq: 0.0,
        totalAmount: 0.0,
        invoiceId: null,
      };
    }

    return invoice;
  }
}

export default InvoiceService;

import ProblemsService from '../services/ProblemsService';

class Problems {

  static getInstance() {
    if (!this.myInstance) {
      this.myInstance = new Problems();
      this.myInstance.categories = [];
      this.myInstance.clusterActive = true;
      this.myInstance.currentProblem = null;
      this.myInstance.currentProblem = null;
      this.myInstance.listeners = [];
      this.myInstance.minThumbsUp = 0;
      this.myInstance.problems = JSON.parse(localStorage.getItem('problems'));
      this.myInstance.problemsOnMap = [];
      this.myInstance.sortType = 1;
      this.myInstance.status = [];

      this.myInstance._sortProblems();
    }

    return this.myInstance;
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  _applyBounds() {
    const newList = [];
    if (this.problems) {
      this.problems.forEach(val => {
        if (this.bounds !== undefined
          && this.bounds.contains({ lat: val.latitude, lng: val.longitude })
          && val.thumbs_up_count >= this.minThumbsUp
          && (!this.categories
            || this.categories.length === 0
            || this.categories.includes(val.category))
          && (((!this.status || this.status.length === 0) && val.status !== 'done')
            || (this.status && this.status.length !== 0 && this.status.includes(val.status)))) {
          newList.push(val);
        }
      });
    }

    this.problemsOnMap = newList;
    if (this.currentProblem && !this.problemsOnMap.some(problem => problem.id === this.currentProblem.id)) {
      this.currentProblem = null;
    }
  }

  clearListeners() {
    this.listeners = [];
  }

  async fetchProblems(cityId) {
    const result = await ProblemsService.fetchProblems(cityId);
    this.problems = result.problemList;
    this.maxThumbsUp = result.maxThumbsUp;
    localStorage.setItem('problems', JSON.stringify(this.problems));

    this._filterProblems();
    this._forceUpdate();

    return this.problems;
  }

  _filterProblems() {
    this._applyBounds();
    this._sortProblems();
  }

  _forceUpdate() {
    if (this.forceUpdateTimeout) {
      clearTimeout(this.forceUpdateTimeout);
    }

    this.forceUpdateTimeout = setTimeout(async () => await Promise.all(this.listeners.map(async listener => await listener.forceUpdateAsync())), 100);
  }

  getClusterActive() {
    return this.clusterActive;
  }

  getCurrentProblem() {
    return this.currentProblem;
  }

  getProblems() {
    return this.problems;
  }

  getProblemsOnMap() {
    return this.problemsOnMap;
  }

  getProblem(id) {
    let problem = null;
    if (this.problems) {
      problem = this.problems.find(problem => problem.id === id);
    }

    return problem;
  }

  getMaxThumbsUp() {
    return this.maxThumbsUp;
  }

  setBounds(bounds) {
    this.bounds = bounds;
    this._filterProblems();
    this._forceUpdate();
  }

  setCategories(categories) {
    this.categories = categories;
    this._filterProblems();
    this._forceUpdate();
  }

  setClusterActive(clusterActive) {
    this.clusterActive = clusterActive;
    this._forceUpdate();
  }

  setCurrentProblem(currentProblem) {
    this.currentProblem = currentProblem;
    this._forceUpdate();
  }

  setMinThumbsUp(minThumbsUp) {
    this.minThumbsUp = minThumbsUp;
    this._filterProblems();
    this._forceUpdate();
  }

  setSortType(sortType) {
    this.sortType = sortType;
    this._sortProblems();
    this._forceUpdate();
  }

  setStatus(status) {
    this.status = status;
    this._filterProblems();
    this._forceUpdate();
  }

  _sortProblems() {
    if (this.sortType === 1) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => a.id - b.id);
    }
    else if (this.sortType === 2) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => b.id - a.id);
    }
    else if (this.sortType === 3) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => b.thumbs_up_count - a.thumbs_up_count);
    }
    else if (this.sortType === 4) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => a.thumbs_up_count - b.thumbs_up_count);
    }
    else if (this.sortType === 5) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => b.thumbs_down_count - a.thumbs_down_count);
    }
    else if (this.sortType === 6) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => a.thumbs_down_count - b.thumbs_down_count);
    }
    else if (this.sortType === 7) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => b.daysSinceLastUpdate - a.daysSinceLastUpdate);
    }
    else if (this.sortType === 8) {
      this.problemsOnMap = this.problemsOnMap.sort((a, b) => a.daysSinceLastUpdate - b.daysSinceLastUpdate);
    }
  }
}

export default Problems;

import moment from 'moment-timezone';

import HttpMessage, { ROOT_URL } from './HttpMessage';

import { getHttpCitiesFilter } from '../utils/Filters';
import User from '../data/User';

class ProblemsService {

  static async fetchProblems(cityIds) {
    const citiesFilters = getHttpCitiesFilter(cityIds);
    const message = {
      route: `${ROOT_URL}/problems?${citiesFilters}&token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const problemList = [];
    let maxThumbsUp = 0;
    if (data.status === 'success') {
      data.problems.forEach(val => {
        val.latitude = parseFloat(val.latitude);
        val.longitude = parseFloat(val.longitude);
        if (val.thumbs_up_count > maxThumbsUp) {
          maxThumbsUp = val.thumbs_up_count;
        }
        val.daysSinceLastUpdate = Math.floor(Math.abs(moment.duration(moment(val.updated_at, 'YYYY-MM-DD HH:mm:ss Z').diff(moment())).asDays()));
        val.daysSinceLastUpdate = isNaN(val.daysSinceLastUpdate) ? 0 : val.daysSinceLastUpdate;

        problemList.push(val);
      });
    }

    return { problemList, maxThumbsUp };
  }

  static async fetchProblem(problemId) {
    const message = {
      route: `${ROOT_URL}/problems/${problemId}?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    if (data.status === 'success') {
      data.problem.latitude = parseFloat(data.problem.latitude);
      data.problem.longitude = parseFloat(data.problem.longitude);
    }

    return data;
  }

  static async modifyProblemCategory(problemId, problemCategory) {
    const message = {
      route: `${ROOT_URL}/problems/${problemId}/category?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ category: problemCategory }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyProblemStatus(problemId, problemStatus) {
    const message = {
      route: `${ROOT_URL}/problems/${problemId}/status?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ status: problemStatus }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async deleteProblem(id) {
    const message = {
      route: `${ROOT_URL}/problems/${id}?token=${User.getInstance().getUser().token}`,
      method: 'DELETE',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async fetchProblemCategories() {
    const message = {
      route: `${ROOT_URL}/v2/problems/categories?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    let categories = [];
    if (data.status === 'success') {
      categories = data.categories;
    }

    return categories;
  }

  static async updateCategoryStatus(category) {
    const message = {
      route: `${ROOT_URL}/problems/categories?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify(category),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async modifyProblemCityComment(currentProblem) {
    const message = {
      route: `${ROOT_URL}/problems/${currentProblem.id}/comment?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      body: JSON.stringify({ comment: currentProblem.comment }),
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }

  static async signalCreator(problemId) {
    const message = {
      route: `${ROOT_URL}/problems/${problemId}/signalCreator?token=${User.getInstance().getUser().token}`,
      method: 'PUT',
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    return data.status === 'success';
  }
}

export default ProblemsService;

import React from 'react';

import ReactLoading from 'react-loading';

import './styles.scss';

const LoadingPage = () => (
  <div className="overlay">
    <ReactLoading type={'bars'} color="#223A5E" height={'15%'} width={'15%'} />
  </div>
);

export default LoadingPage;
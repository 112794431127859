import moment from 'moment-timezone';

import HttpMessage, { ROOT_URL } from './HttpMessage';

import { getHttpCitiesFilter } from '../utils/Filters';
import User from '../data/User';
import i18n from '../i18n';

class LogsService {

  static async fetchCityLogs(cityId, logType) {
    const citiesFilters = getHttpCitiesFilter([cityId]);
    const message = {
      route: `${ROOT_URL}/web_logging?log_type=${logType}&${citiesFilters}&token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);
    let logs = [];
    if (data.status === 'success') {
      logs = data.logs.map(log => {
        log.createdAt = moment(log.created_at).format('YYYY-MM-DD HH:mm:ss');
        try {
          const languageEnabled = i18n.getLanguageEnabled();
          log.content = JSON.parse(log.content)[languageEnabled];
        }
        catch (error) {
          // Keep content value
        }

        return log;
      });
    }

    return logs;
  }

  static async fetchProblemLogs(problemId) {
    const message = {
      route: `${ROOT_URL}/web_logging/problem/${problemId}?token=${User.getInstance().getUser().token}`,
      method: 'GET',
      body: null,
      header: null
    };

    const data = await HttpMessage.sendMessage(message);

    const logs = [];
    if (data.status === 'success') {
      data.logs.forEach(val => {
        if (val.log_type === 'problem') {
          const languageEnabled = i18n.getLanguageEnabled();

          const obj = {};
          obj.id = val.id;
          obj.createdAt = moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
          obj.content = JSON.parse(val.content)[languageEnabled];
          obj.employee = val.employee;

          logs.push(obj);
        }
      });
    }

    return logs;
  }
}

export default LogsService;
